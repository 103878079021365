import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { fetchNovelDetailData } from '@/api/novel';
import { fetchNovelDecisionListAll, fetchNovelEditDecisionListAll } from '@/api/decision';
import { fetchPromotionNovelDetail } from '@/api/promotion';

const novelDataState = atom({
  key: 'novelData',
  default: {},
});

const novelDecisionListState = atom({
  key: 'novelDecisionList',
  default: [],
});

const novelDecisionEditListState = atom({
  key: 'novelDecisionEditList',
  default: [],
});

const novelDetailPromotionListState = atom({
  key: 'novelDetailPromotionList',
  default: [],
});


export const useNovelData = (uid) => {
  const [novelData, setNovelData] = useRecoilState(novelDataState);

  const [novelDecisionList, setNovelDecisionList] = useRecoilState(novelDecisionListState);
  const [novelDecisionEditList, setNovelDecisionEditList] = useRecoilState(novelDecisionEditListState);
 
  const [novelDetailCoinPromotionList, setNovelDetailPromotionCoinList] = useState([])
  const [novelDetailExposureExPromotionList, setNovelDetailPromotionExposureList] = useState([])

  
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getNovelData = async () => {
      try {
        const response = await fetchNovelDetailData(uid);

        
        const list1 = await fetchNovelDecisionListAll(response._id);
        const list2 = await fetchNovelEditDecisionListAll(response._id);

        const promotionList1 = await fetchPromotionNovelDetail(response._id, 'coin');
        const promotionList2 = await fetchPromotionNovelDetail(response._id, 'exposure');

        console.log(promotionList1, promotionList2)

        setNovelData(response);


        setNovelDecisionList(list1);
        setNovelDecisionEditList(list2);

        setNovelDetailPromotionCoinList(promotionList1);
        setNovelDetailPromotionExposureList(promotionList2);
      } catch (error) {
        console.error('Failed to fetch novel Data:', error);
      } finally {
        setIsLoading(false);
      }
    }

    if(uid) {
      getNovelData();
    }
  }, [])

  return {
    novelDecisionList,
    novelDecisionEditList,

    novelDetailCoinPromotionList,
    novelDetailExposureExPromotionList,

    isLoading,
    novelData,
  }
}
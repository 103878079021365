import { axiosAuth } from "@/lib/axios";


export const fetchDashBoardSalesStatus = async () => {
  const { data } = await axiosAuth.get('/statistics/admin/dashboard/novel');
  return data; 
}

export const fetchDashBoardSalesStatusGenre = async () => {
  const { data } = await axiosAuth.get('/statistics/admin/dashboard/genre');
  return data; 
}

export const fetchDashBoardSalesStatusMonth = async () => {
  const { data } = await axiosAuth.get('/statistics/admin/dashboard/month');
  return data; 
}

export const fetchDashBoardNovelStatus = async () => {
  const { data } = await axiosAuth.get('/statistics/admin/dashboard/status');
  return data; 
}
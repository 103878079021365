import axios, { axiosAuth } from "@/lib/axios";

export const fetchNewBannerData = async (bannerData) => {
  try {
    const { data } = await axiosAuth.post('/banner', {
      ...bannerData
    })

    alert('배너를 생성했습니다.')

    return data;
  } catch (e) {
    return alert('배너 생성에 실패했습니다.')
  }
}

export const fetchUpdateBannerData = async (bannerData) => {
  try {
    const { data } = await axiosAuth.put('/banner', {
      ...bannerData
    })

    alert('배너를 수정했습니다.')

    return data;
  } catch (e) {
    return alert('배너 수정에 실패했습니다.')
  }
}

export const fetchBannerList = async (page, count, search, type) => {
  const query = [];
  if (search) query.push(`search=${search}`);
  if (type) query.push(`type=${type}`);
  const queryString = query.join('&');
  const { data } = await axiosAuth.get(`/banner/list/admin/${page}/${count}?${queryString}`);
  return data;
}

export const fetchBannerDetail = async (id) => {
  const { data } = await axios.get(`/banner/detail/${id}`);
  return data;
}
import { Button, Dropdown, Table, Pagination, SearchBar } from "@/components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { formatDateYMD, formatDateYYMD, formatImageUri } from "@/utils/format";
import { fetchStalierList } from "@/api/stalier";
import { downloadCSV } from "@/utils/csv";

const HEADERS = [
  { name: "썸네일", minWidth: 110 },
  { name: "작품명", minWidth: 220 },
  { name: "장르", minWidth: 110 },
  { name: "연재상태", minWidth: 80 },
  { name: "회차", minWidth: 80 },
  { name: "작가명", minWidth: 110 },
  { name: "게시일", minWidth: 110 },
];

const Container = styled.div`
  padding: 60px 100px;
`;

const SearchContainer = styled.div`
  margin-bottom: 50px;
`;

const OptionContainer = styled.div`
  display: flex;
  gap: 13px;
  margin-bottom: 20px;
`;

const TableTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 26px;
`;


export default function Page() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchOptions, setSearchOptions] = useState({
    search: "", // 검색어
    searchCategory: "작품명", // 검색 카테고리
    saleStatus: "판매상태", // 판매 상태
    genre: "장르", // 장르
    salePolicy: "판매정책", // 판매 정책
    serialStatus: "연재상태", // 연재 상태
  });

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [isLoading, setIsLoading] = useState();

  const [stalierList, setStalierList] = useState({
    list: [],
    totalCount: 0,
    totalPage: 0
  })


  async function getData() {
    const response = await fetchStalierList(page, count);

    setStalierList(response);
  }

  useEffect(() => {
    getData();
  }, [page, count])

  function downloadCSVButton() {
    const headers = HEADERS.map(item => item.name);
    const dataList = stalierList?.list?.map(data => {
      return [
        data.book_cover ? formatImageUri(data.book_cover) : "없음",
        data.title,
        data?.genre?.name,
        data.hasCompletion ? "완결" : "연재중", // [TODO] 추가 필요
        data?.episodeCount ?? 0,
        data?.writer_user?.name,
        formatDateYMD(data.created_date),
      ]
    })
    downloadCSV(headers, dataList, '스텔리에 작품 목록')
  }

  return (
    <Container>
      <ButtonTitle
        title='스텔리에 작품 목록'
        containerSytle={{ marginBottom: 38}}
      />

      <SearchContainer>
        <SearchBar
          options={[{ name: "작품1" }, { name: "작품2" }, { name: "작품3" }]}
          dKey="searchCategory"
          label={searchOptions.searchCategory}
          setSelect={({ name: selected }) => setSearchOptions((prev) => ({ ...prev, searchCategory: selected }))}
        />

        <Button width={590} isLoading={isLoading} onClick={() => {
          console.log(searchOptions);
        }}>검색하기</Button>
      </SearchContainer>

      <TableTitle>전체 {stalierList?.totalCount}개</TableTitle>
      

      <Table
        id="stalier-list"
        className="mb-60"
        header={HEADERS}
        datas={stalierList.list?.map((data) => {
          return {
            id: data.title + data.episode,
            renders: [
              { id: "thumbnail", render: () => data.book_cover ? <img src={formatImageUri(data.book_cover)} alt="thumbnail" style={{ width: 82, height: 118 }} draggable={false} /> : <>없음</> },
              { id: "title", render: data.title },
              { id: "genre", render: data?.genre?.name },
              { id: "serialStatus", render: data.hasCompletion ? "완결" : "연재중" }, // [TODO] 추가 필요
              { id: "episode", render: data?.episodeCount ?? 0 },
              { id: "author", render: data?.writer_user?.name },
              { id: "date", render: formatDateYMD(data.created_date) },
            ],
            onClick: () => {
              navigate(`/stalier/list/detail/${data.uuid}`);
            }
          }
        })}
      />

      <div className="flex align-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]}
          label={`${count}개`}
          dkey="pageSize"
          selected={{name: `${count}개`,value: count}}
          setSelect={item=> setCount(item.value)}
        />

        <Pagination
          currentPage={page}
          totalPage={stalierList.totalPage}
          onClickPage={(page) => {
            setPage(page);
          }}
        />

        <Button style={{ marginLeft: 'auto' }} width={245} onClick={() => {
          downloadCSVButton();
        }}>CSV 다운로드</Button>
      </div>
      
    </Container>
  );
}

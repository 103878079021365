import { Button, Table } from '@/components'
import ButtonTitle from '@/components/ButtonTitle/ButtonTitle'
import KeywordUpdateModal from '@/components/KeywordUpdateModal'
import KeywordAddModal from '@/components/KeywordAddModal'
import { useKeywordList } from '@/stores/keywords'
import { formatDateYMD } from '@/utils/format'
import { useState } from 'react'
import styled from 'styled-components'
import { loading } from '@/assets'


const Container = styled.div`
  padding: 60px 100px;
`


const HEADERS = [
  { name: "이름", minWidth: 120 },
  { name: "연관 장르", minWidth: 300 },
  { name: "생성일", minWidth: 80 },
  { name: "마지막 수정일", minWidth: 80 },
  { name: "작품 수", minWidth: 60 },
  { name: "", minWidth: 110 },
];

export default function Page () {
  const { keywordList } = useKeywordList();

  const [isKeywordAddModalOpen, setIsKeywordAddModalOpen] = useState(false);
  const [updateTarget, setUpdateTarget] = useState(null);

  return (
    <Container>
      <ButtonTitle
        title='키워드 관리'
        containerSytle={{ marginBottom: 38 }}
      />
      
      <div className='mt-100'/>


      {keywordList.length ? 
        <Table
          id="novel-list"
          className="mb-60"
          header={HEADERS}
          datas={keywordList?.map((data, index) => {
            console.log(data)
            return {
              id: 'keyword' + index,
              renders: [
                { render: () => data.name },
                { render: () => <div className='ellipsis-1 w-full'>{data.genre.map(item => item.name).join(', ')}</div>},
                { render: () => formatDateYMD(data.created_date)},
                { render: () => formatDateYMD(data.updated_date)},
                { render: () => data.novelCount },
                { render: () => <>
                  <Button width={80} height={32} fontSize={14} onClick={() => {
                    setUpdateTarget(data)
                  }}>수정하기</Button>
                </> },
              ],
            }
          })}
        />
        :
        <div className='w-full flex-center'>
          <img src={loading}/>
        </div>
      }

      <KeywordAddModal
        isOpen={isKeywordAddModalOpen}
        setIsOpen={setIsKeywordAddModalOpen}
      />

      {updateTarget && <KeywordUpdateModal
        keyword={updateTarget}
        isOpen={!!updateTarget}
        setIsOpen={setUpdateTarget}
      />}

      <div className='w-full flex justify-end'>
        <Button onClick={() => setIsKeywordAddModalOpen(true)}>키워드 추가</Button>
      </div>
    </Container>
  )
}

import styled from 'styled-components';

const Container = styled.button`
  font-family: Pretendard;
  font-size: 24px;
  font-weight: ${(props)=>props.isSelected?'900':'normal'};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: ${(props)=> props.isSelected?'#ba1c1f':'#575757'};
  margin-right: 70px;
  cursor: pointer;
  border: none;
`


function TabBar ({ tabArr, currentTab, clickTab }) {
  return (
    <div className='flex'>
      {tabArr.map((tab)=>(
        <Container key={tab} isSelected={tab===currentTab} onClick={()=>{clickTab(tab)}}>
          {tab}
        </Container>
      ))}
    </div>
  );
}

export default TabBar;

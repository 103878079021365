import styled from "styled-components";
import { Dropdown, Pagination, Table } from "..";
import { formatDateYYMD } from "@/utils/format";

const HEADER = [
  { name: "승인 요청일", minWidth: 200 },
  { name: "승인일시", minWidth: 200 },
  { name: "승인유무", minWidth: 120 },
  { name: "승인내용", minWidth: 120 },
  { name: "승인자", minWidth: 120 },
  { name: <div>플랫폼 수정사유 <span className="font-12">(배급사에서 수정 시에만 작성합니다)</span></div>, minWidth: 600 },
];


const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.6px;
  margin-bottom: 44px;
`;

function ApprovalLog({ list }) {
  console.log(list)

  return (
    <div>
      <Title>승인 이력</Title>
   
      <Table className="mb-40" header={HEADER} datas={
        list?.map((item) => {
          return {
            renders: [
              {
                render: () => <div>{formatDateYYMD(item.created_date)}</div>,
              },
              {
                render: () => <div>{item.decisionDate ? formatDateYYMD(item.decisionDate) : '대기중'}</div>,
              },
              {
                render: () => <div>{["대기중", "승인", "반려"][item.hasApproved]}</div>,
              },
              {
                render: () => <div>{item.decisionType === 0 ? '소설 정보 수정' : '소설 등록 승인'}</div>,
              },
              {
                render: () => <div>{item?.decisionAdmin?.name ?? ''}</div>,
              },
              {
                render: () => <div>{item?.decisionComment}</div>,
              },
            ],
          }
        })
      } />
    </div>
  );
}

export default ApprovalLog;

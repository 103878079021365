import { Button, Table } from '@/components'
import ButtonTitle from '@/components/ButtonTitle/ButtonTitle'
import { formatDateYMD } from '@/utils/format'
import { useState } from 'react'
import styled from 'styled-components'
import { useStalierKeywordList } from '@/stores/stalierKeywords'
import StalierKeywordAddModal from '@/components/StalierKeywordAddModal'
import StalierKeywordUpdateModal from '@/components/StalierKeywordUpdateModal'


const Container = styled.div`
  padding: 60px 100px;
`


const HEADERS = [
  { name: "이름", minWidth: 120 },
  { name: "연관 장르", minWidth: 300 },
  { name: "생성일", minWidth: 80 },
  { name: "마지막 수정일", minWidth: 80 },
  { name: "작품 수", minWidth: 60 },
  { name: "", minWidth: 110 },
];

export default function Page () {
  const { stalierKeywordList } = useStalierKeywordList();

  const [isKeywordAddModalOpen, setIsKeywordAddModalOpen] = useState(false);
  const [updateTarget, setUpdateTarget] = useState(null);

  return (
    <Container>
      <ButtonTitle
        title='스텔리에 키워드 관리'
        containerSytle={{ marginBottom: 38 }}
      />
      
      <div className='mt-100'/>


      <Table
        id="novel-list"
        className="mb-60"
        header={HEADERS}
        datas={stalierKeywordList?.map((data, index) => {
          console.log(data)
          return {
            id: 'keyword' + index,
            renders: [
              { render: () => data.name },
              { render: () => <div className='ellipsis-1 w-full'>{data.genre.map(item => item.name).join(', ')}</div>},
              { render: () => formatDateYMD(data.created_date)},
              { render: () => formatDateYMD(data.updated_date)},
              { render: () => data?.novelCount ?? 0 },  // [TODO] 추가
              { render: () => <>
                <Button width={80} height={32} fontSize={14} onClick={() => {
                  setUpdateTarget(data)
                }}>수정하기</Button>
              </> },
              
            ],
          }
        })}
      />

      <StalierKeywordAddModal
        isOpen={isKeywordAddModalOpen}
        setIsOpen={setIsKeywordAddModalOpen}
      />

      {updateTarget && <StalierKeywordUpdateModal
        keyword={updateTarget}
        isOpen={!!updateTarget}
        setIsOpen={setUpdateTarget}
      />}

      <div className='w-full flex justify-end'>
        <Button onClick={() => setIsKeywordAddModalOpen(true)}>키워드 추가</Button>
      </div>
    </Container>
  )
}
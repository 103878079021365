import styled from "styled-components";
import { Dropdown, Pagination, Table } from "..";
import { useNavigate, useParams } from "react-router-dom";
import { formatDateYMD } from "@/utils/format";
import { useUserCoinList } from "@/stores/userCoinList";

const HEADERS = [
  { name: "결제일시", minWidth: 200 },
  { name: "품목", minWidth: 180 },
  { name: "결제방법", minWidth: 120 },
  { name: "결제수단", minWidth: 120 },
  { name: "결제 전 스타", minWidth: 120 },
  { name: "결제 후 스타", minWidth: 120 },
  { name: "결제 ip", minWidth: 140 },
  { name: "주문 고유 ID", minWidth: 120 },
  { name: "영수/주문확인 email", minWidth: 200 },
  { name: "환불유무", minWidth: 120 },
];

const countOptions = [
  { name: "10개", value: 10 },
  { name: "20개", value: 20 },
  { name: "30개", value: 30 },
];

const TotalCount = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
`;

function PurchasedProduct() {
  const { uid } = useParams();
  const { userCoinList, page, count, handlePage, handleCount } = useUserCoinList(uid);

  const navigate = useNavigate();


  return (
    <div>
      <TotalCount>전체 {userCoinList.totalCount}개</TotalCount>

      <Table
        id="sale-list"
        className="mb-50"
        header={HEADERS}
        datas={
          userCoinList.list.map((data) => ({
            id: data.id,
            renders: [
              { render: () => formatDateYMD(data.created_date) },
              { render: () => data.product_id?.title },
              { render: () => data.pgType },
              { render: () => data.paymentType },
              { render: () => data.previousQuantity },
              { render: () => data.previousQuantity + data.amount },
              { render: () => data.user_ip },
              { render: () => data.paymentKey },
              { render: () => data.user?.email },
              { render: () => data.isRefund ? "O" : "X" }, // 추후 데이터 확인 후 수정 필요
            ],
            onClick: () => {
              navigate(`/payment/detail/${data._id}`);
            }
          }))
        }
      />

      <div className="flex align-center justify-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={countOptions}
          dkey="pageSize"
          select={countOptions.find((option) => option.value === count)}
          setSelect={({ value: selected }) => {
            handleCount(selected);
          }}
        />
        <Pagination
          currentPage={userCoinList.page}
          totalPage={userCoinList.totalPage}
          onClickPage={handlePage}
        />
      </div>
    </div>
  );
}

export default PurchasedProduct;

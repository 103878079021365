import { Button, Dropdown, Table, Pagination, SearchBar } from "@/components";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { formatDateYMD, formatDate } from "@/utils/format";
import { usePromotionList } from "@/stores/promotionList";
import { downloadCSV } from "@/utils/csv";

function formatDateYM(updatedAt) {
  return formatDateYMD(updatedAt).slice(0, 7);
}

const HEADERS = [
  { name: "게시일", minWidth: 130 },
  { name: "작품명", minWidth: 180 },
  { name: "작가", minWidth: 130 },
  { name: "담당 cp사", minWidth: 130 },
  { name: "현재 프로모션", minWidth: 150 },
  { name: "시작일", minWidth: 130 },
  { name: "종료일", minWidth: 130 },
  { name: "프로모션/비고", minWidth: 220 },
  { name: "상태", minWidth: 80 },
];

const Container = styled.div`
  padding: 60px 100px;
`;

const Title = styled.div`
  min-width: 600px;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 38px;
`;

const SearchContainer = styled.div`
  margin-bottom: 50px;
`;

const SearchBtnContainer = styled.div`
  display: flex;
  gap: 7px;
`;

const TableTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 26px;
`;

const SEARCH_OPTIONS = [
  { name: "작품명", value: "title" },
  { name: "작가명", value: "writer" },
];

export default function Page() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchOptions, setSearchOptions] = useState({
    search: searchParams.get("search") || "",
    type: SEARCH_OPTIONS.find((option) => option.value === searchParams.get("type")) || SEARCH_OPTIONS[0],
  });

  const [pagination, setPagination] =  useState({
    page: searchParams.get("page") || 1,
    pageSize: searchParams.get("pageSize") || 10,
    total: 100,
  });

  const { promotionList, page, handlePage, isLoading } = usePromotionList('audiobook');

  function downloadCSVButton() {
    const headers = HEADERS.map(item => item.name);
    const dataList = promotionList?.list?.map(data => {
      return [
        formatDateYMD(data.created_date),
        data.target_novel.title,
        data.target_novel.writer_list?.map(item => item.name).join(', '),
        data?.target_cp?.name,
        ["기본 오디오북"][data?.promotionNumber],
        formatDate(data.promotion_start),
        formatDate(data.promotion_end),
        data.title,
        data.isActive ? "활성화" : "비활성화",
      ]
    })
    downloadCSV(headers, dataList, '오디오북 프로모션 관리')
  }

  return (
    <Container>
      <ButtonTitle
        title='오디오북 프로모션 관리'
        containerSytle={{ marginBottom: 38 }}
      />

      <SearchContainer>
        <SearchBar
          options={SEARCH_OPTIONS}
          dKey="searchCategory"
          select={searchOptions.type}
          setSelect={(select) => setSearchOptions((prev) => ({ ...prev, type: select }))}
          search={searchOptions.search}
          onChange={(e) => setSearchOptions((prev) => ({ ...prev, search: e.target.value }))}
        />

        <SearchBtnContainer>
          <Button width={139} isLoading={isLoading} onClick={() => {
            setSearchParams((prev) => {
              prev.set("search", searchOptions.search);
              prev.set("type", searchOptions.type.value);
              prev.set("page", 1);
              return prev;
            });
          }}>조회하기</Button>

          <Button width={139} onClick={() => {
            downloadCSVButton();
          }}>csv 다운</Button>
        </SearchBtnContainer>

      </SearchContainer>

      <TableTitle>전체 {promotionList.totalCount}개</TableTitle>


      <Table
        id="promotion-list"
        className="mb-60"
        header={HEADERS}
   
        datas={promotionList?.list?.map((data) => {
          return {
            id: data._id,
            renders: [
              { id: "updatedAt", render: <div className="exbold">{formatDateYMD(data.created_date)}</div> },
              { id: "title", render: data?.target_novel?.title },
              { id: "writer", render: data?.target_novel?.writer_list?.map(item => item.name).join(', ') },
              { id: "cp", render: data?.target_cp?.name },
              { id: "promotion", render: ["기본 오디오북"][data?.promotionNumber] },
              { id: "startAt", render: <div className="exbold">{formatDate(data.promotion_start)}</div> },
              { id: "endAt", render: <div className="exbold">{formatDate(data.promotion_end)}</div> },
              { id: "note", render: data.title },
              { id: "isActive", render: data.isActive ? "활성화" : "비활성화" },
            ],
            onClick: () => {
              navigate(`/content/promotion/detail/audiobook/${data._id}`);
            }
          }
        })}
      />

      <div className="flex align-center justify-end mb-22">
        <Button style={{}} width={180} to='/content/promotion/add/audiobook'>프로모션 생성</Button>
      </div>

      <div className="flex align-center justify-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]}
          label={`${pagination.pageSize}개`}
          dkey="pageSize"
          setSelect={({ value: selected }) => {
            setPagination((prev) => ({ ...prev, pageSize: selected }));
            setSearchParams((prev) => {
              prev.set("pageSize", selected);
              return prev;
            });
          }}
        />

        <Pagination
          currentPage={page}
          totalPage={promotionList.totalPage}
          onClickPage={(page) => {
            handlePage(page);
          }}
        />
      </div>

    </Container>
  );
}

import { Button, Dropdown, Table, Pagination, SearchBar } from "@/components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { formatDateYMD, formatNumber, formatDate } from "@/utils/format";
import { fetchProductList, fetchUpdateActiveProductList } from "@/api/product";
import { downloadCSV } from "@/utils/csv";
import { fetchCouponList, fetchUpdateActiveCouponList } from "@/api/coupon";


const HEADERS = [
  { name: "게시일", minWidth: 130 },
  { name: "쿠폰명", minWidth: 180 },
  { name: "쿠폰번호", minWidth: 300 },
  { name: "게시자", minWidth: 130 },
  { name: "스타 개수", minWidth: 130 },
  { name: "시작일", minWidth: 130 },
  { name: "종료일", minWidth: 130 },
  { name: "활성화 여부", minWidth: 120 },
];

const Container = styled.div`
  padding: 60px 100px;
`;

const SearchContainer = styled.div`
  margin-bottom: 50px;
`;

const SearchBtnContainer = styled.div`
  display: flex;
  gap: 7px;
`;

const TableTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 26px;
`;


export default function Promotion() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // 여기에 현재 뭐가 들어올 수 있는 거지?
  const [searchOptions, setSearchOptions] = useState(
    "기간"
  );

  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);
  

  useEffect(() => {
    getList()
  }, [])

  async function getList() {
    const data = await fetchCouponList();
    setList(data);
  }


  async function submitUnActivate() {
    const data = await fetchUpdateActiveCouponList(selected);
    setSelected([])
  }





  function downloadCSVButton() {
    const headers = HEADERS.map(item => item.name);
    const dataList = list?.map(data => {
      return [
        formatDateYMD(data.created_date),
        data.title,
        data?.create_admin?.name,
        formatNumber(data.amount) + "개",
        formatNumber(data.price) + "원",
        formatDate(data.product_sales_start),
        formatDate(data.product_sales_end),
        data.isActive ? "활성화" : "비활성화",
      ]
    })

    downloadCSV(headers, dataList, '쿠폰 관리')
  }


  return(
    <Container>
      <ButtonTitle
        title='쿠폰 관리'
        containerSytle={{ marginBottom: 38 }}
      />

      <SearchContainer>

        <SearchBtnContainer>

        </SearchBtnContainer>

      </SearchContainer>

      <TableTitle>전체 {list.length}개</TableTitle>


      <Table
        id="coupon-list"
        className="mb-60"
        header={HEADERS}
        selected={selected}
        setSelected={setSelected}
        datas={list?.map((data) => {
          return {
            id: data._id,
            renders: [
              { id: "updatedAt", render: <div className="exbold">{formatDateYMD(data.created_date)}</div> },
              { id: "title", render: data.title },
              { id: "coupon_number", render: data?.coupon_number },
              { id: "writer", render: data?.create_admin?.name },
              { id: "oddProduct", render: formatNumber(data.amount) + "개" },
              { id: "startAt", render: <div className="exbold">{formatDate(data.coupon_start)}</div> },
              { id: "endAt", render: <div className="exbold">{formatDate(data.coupon_end)}</div> },
              { id: "note", render: data.isActive ? "활성화" : "비활성화" },
            ]
          }
        })}
      />

      <div className="w-full flex align-center justify-end mb-22">
        
        <Button width={139} onClick={() => {
            downloadCSVButton();
        }}>csv 다운</Button>

        <div className="ml-24"/>

        <Button width={139} onClick={() => {
          navigate('/coupon/add')
        }}>쿠폰 추가</Button>

        <div className="ml-24"/>

        <Button background={'#ff0000'} width={139} onClick={() => {
          submitUnActivate();
        }}>비활성화</Button>

        
      </div>

    </Container>
  )







}

import styled from 'styled-components'
import { Modal } from '..'
import { useEffect, useRef, useState } from 'react';

import ePub from 'epubjs';
import { formatEpubUri, formatImageUri } from '@/utils/format';

const Container = styled.div`

`


export default function EpubPreview ({
  isOpen,
  onClose,
  novel_uuid,
  episode_index,
  version
}) {
  console.log(novel_uuid, episode_index, version)
  const [page, setPage] = useState(1); // 페이지 번호 상태 변수 추가
  const viewerRef = useRef(null);

  useEffect(() => {
    const url = formatEpubUri(novel_uuid, episode_index, version); // EPUB 파일의 경로

    const book = ePub(url);
    const rendition = book.renderTo(viewerRef.current, {
      width: '100%',
      height: '100%'
    });

    // 페이지네이션 없이 세로로 끊김없이 나열
    rendition.flow('scrolled-doc');

    rendition.themes.default({
      body: {
        'padding-left': '16px !important',
        'padding-right': '16px !important',
        'user-select': 'none',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-drag': 'none',
        '-webkit-user-drag': 'none',
        'box-sizing': 'border-box',
        'overflow': 'auto' // 추가: 콘텐츠가 잘리지 않도록
      }
    });

    rendition.themes.select('custom');

    // 특정 페이지 렌더링
    book.ready.then(() => {
      const spineItems = book.spine.spineItems;
      const nonCoverSections = spineItems.filter(
        item => !item.href.includes('cover.xhtml') && !item.href.includes('titlepage.xhtml')
      );
      
      console.log({nonCoverSections})

      // 현재 페이지를 기반으로 렌더링할 섹션 선택
      const sectionToDisplay = nonCoverSections[page - 1]; // 페이지 번호는 1부터 시작한다고 가정

      if (sectionToDisplay) {
        rendition.display(sectionToDisplay.href);
      } else {
        // 유효하지 않은 페이지 번호인 경우 기본 섹션을 표시
        rendition.display(nonCoverSections[0]?.href || spineItems[0].href);
      }
    });

    return () => {
      if (rendition) {
        try {
          rendition.destroy();
        } catch (error) {
          console.error("Error destroying rendition:", error);
        }
      }
    };
  }, [page, episode_index]); // 페이지 번호가 바뀔 때마다 렌더링


  // 페이지 변경 핸들러 예시
  const goToNextPage = () => {
    setPage(prevPage => prevPage + 1);
  };

  const goToPreviousPage = () => {
    setPage(prevPage => Math.max(prevPage - 1, 1)); // 최소 1 페이지로 유지
  };



  return (
    <Container>
      <Modal isOpen={isOpen} onClose={onClose}  style={{
        width: '500px',
        maxWidth: '96vw',
        height: '800px',
        maxHeight: '96vh'
      }}>
        <div className="w-full pt-32 pb-48 ">
          <div className='block-draggable' ref={viewerRef} style={{ width: '100%', height: 'auto', overflow: 'auto',  }}></div>

          <div className='flex-center w-full mt-48'>
            <div className='bold underline pointer' onClick={goToPreviousPage}>이전</div>
            <div className='flex-1'/>
            <div className='bold underline pointer' onClick={goToNextPage}>다음</div>
          </div>
        </div>
      </Modal>
    </Container>
  )
}
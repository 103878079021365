import React, { useRef } from 'react';
import styled from 'styled-components';
import { useClickOutside } from '@/hooks';
import { ic_close_circle_grey } from '@/assets/components';

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({zIndex}) => zIndex};
  flex-direction: column;

  .modalInner {
    width: ${({width}) => `${width}px`};
    height: ${({height}) => `${height}px`};

    overflow-y: auto;


    position: relative;
    background-color: #fff;
    border-radius: 20px;
    padding: 32px 42px;
  }

  .closeBtn {
    position: absolute;
    top: 30px;
    right: 30px;
  }
`;

function Modal({ children, onClose, isOpen, width=900, height=700, zIndex=9999991, ...rest }) {
  const ref = useRef();

  useClickOutside(ref, () => {
    onClose();
  });

  return (
    <>
      {isOpen && (
        <Container width={width} height={height} zIndex={zIndex}>
          <div className='modalInner' ref={ref} {...rest}>
            
            <div className='closeBtn' onClick={onClose} style={{ cursor: 'pointer' }}>
              <img src={ic_close_circle_grey}/>
            </div>

            <>{children}</>
          </div>
        </Container>
      )}
    </>
  );
}

export default Modal;

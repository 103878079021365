import styled from "styled-components";
import { Button, DivideBox } from "..";



const BoldText = styled.div`
  font-family: Pretendard;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-align: left;
  color: #000;
`



function OddPolicy(
  {oddPolicyArr}
) {

  return (
  <div>

    <div className='flex mb-28' >
      <BoldText>스타정책</BoldText>
    </div>

    <DivideBox infoArr={oddPolicyArr}/>
</div>

);
}

export default OddPolicy;
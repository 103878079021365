import { Button, Dropdown, Input, Pagination, SearchBar, Table } from "@/components";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
; // 한국어 locale



import dayjs from 'dayjs';
import 'dayjs/locale/ko'; // 한국어 로케일 사용
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);


import moment from 'moment';
import 'moment-timezone'
import { fetchGetCPSalesStatus, fetchGetCPSettlement, fetchGetSalesStatus, fetchPutCPSettlement } from "@/api/statistics";
import { formatNumber } from "@/utils/format";
import _ from "lodash";
import { downloadCSV } from "@/utils/csv";
moment.tz.setDefault('Asia/Seoul');

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const HEADERS = [
  { name: "작품명", minWidth: 200 },
  { name: "작품 수", minWidth: 120 },
  { name: "토스페이먼츠 결제 (원)", minWidth: 200 },
  { name: "플레이스토어 인앱결제 (원)", minWidth: 200 },
  { name: "앱스토어 인앱결제 (원)", minWidth: 200 },
  { name: "쿠폰 결제 (원)", minWidth: 200 },
  { name: "총 판매액 (원)", minWidth: 240 },
  { name: "CP사 정산 비율", minWidth: 140 },
  { name: "플레이스토어 수수료", minWidth: 160 },
  { name: "플레이스토어 수수료 (원)", minWidth: 200 },
  { name: "앱스토어 수수료", minWidth: 160 },
  { name: "앱스토어 수수료 (원)", minWidth: 200 },
  { name: "플랫폼 수수료", minWidth: 160 },
  { name: "플랫폼 수수료 (원)", minWidth: 200 },
  { name: "총 정산액 (원)", minWidth: 240 },
  { name: "정산 완료액 (원)", minWidth: 240 },
  { name: "잔여 정산액 (원)", minWidth: 240 },
];


const Container = styled.div`
  padding: 60px 100px;

  .start_date {
    input {
      border: 1px solid rgb(185, 185, 185);
      height: 40px;
      border-radius: 12px;
      padding-left: 12px;
      background-color: #fff;
    }
  }
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
`;

const Line = styled.div`
  width: 24px;
  height: 3px;
  background-color: #000;
`;

const SearchOptionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 60px;
`;

const TotalCount = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

function Sales() {

  const [selectedDate, setSelectedDate] = useState(null);

  const [list, setList] = useState([])
  const [searchTitle, setSearchTitle] = useState('');
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  
  useEffect(() => {
    if(selectedDate) getData();
  }, [page, setPage, count])

  async function getData() {
    if(!selectedDate) return alert('날짜를 선택하세요.');
    const st = dayjs(selectedDate).startOf('month').format('YYYYMMDD');
    const ed = dayjs(selectedDate).endOf('month').format('YYYYMMDD');


    const { list, total, totalCount } = await fetchGetCPSettlement(
      page,
      count,
      st,
      ed,
      searchTitle
    );

    console.log(list, total, totalCount);

    if(!list) {
      return alert('조회 결과가 없습니다.');
    }
    
    setList(list);

    // 전체 카운트
    setTotalCount(totalCount);
  
    // 토탈
    setTotalPage(total);
  }
  
  function calculatePercentage(number, percent) {
    return Math.floor((number * (percent / 100)));
  }

  const [selected, setSelected] = useState([]);


  async function settlementSubmit() {
    const st = dayjs(selectedDate).startOf('month').format('YYYYMMDD');
    const ed = dayjs(selectedDate).endOf('month').format('YYYYMMDD');
    const cp_id_list = selected.map(item => item._id);
    const settlement_amount = selected.map(data => 
      calculatePercentage((data.wallet_total_payment_0_sum + 
      data.wallet_total_payment_1_sum + 
      data.wallet_total_payment_2_sum -
      calculatePercentage(data.wallet_total_payment_1_sum, 30) -
      calculatePercentage(data.wallet_total_payment_2_sum, 30))
    , data.settlementRatio))

    await fetchPutCPSettlement(cp_id_list, settlement_amount, st, ed);
  }

  function downloadCSVButton() {
    const headers = HEADERS.map(item => item.name);
    const dataList = list?.map(data => {
      return [
        data.name,
        data.novel_list_count,
        formatNumber(data.wallet_total_payment_0_sum),
        formatNumber(data.wallet_total_payment_1_sum),
        formatNumber(data.wallet_total_payment_2_sum),
        formatNumber(data.wallet_total_payment_3_sum),
        formatNumber(data.wallet_total_payment_0_sum + data.wallet_total_payment_1_sum + data.wallet_total_payment_2_sum + data.wallet_total_payment_3_sum),
        data.settlementRatio + "%",
        "30%",
        formatNumber(calculatePercentage(data.wallet_total_payment_1_sum, 70)),
        "30%",
        formatNumber(calculatePercentage(data.wallet_total_payment_2_sum, 70)),
        (100 - data.settlementRatio) + "%",
        
          formatNumber(
            calculatePercentage(data.wallet_total_payment_0_sum + 
              data.wallet_total_payment_1_sum + 
              data.wallet_total_payment_2_sum + 
              data.wallet_total_payment_3_sum -
              calculatePercentage(data.wallet_total_payment_1_sum, 30) -
              calculatePercentage(data.wallet_total_payment_2_sum, 30)
            , (100 - data.settlementRatio))
          ),
       
          formatNumber(
            calculatePercentage((
              data.wallet_total_payment_0_sum + 
              data.wallet_total_payment_1_sum + 
              data.wallet_total_payment_2_sum +
              data.wallet_total_payment_3_sum -
              calculatePercentage(data.wallet_total_payment_1_sum, 30) -
              calculatePercentage(data.wallet_total_payment_2_sum, 30))
            , data.settlementRatio)
          ),
       
          formatNumber(
            calculatePercentage((
              data.completed_wallet_total_payment_0_sum + 
              data.completed_wallet_total_payment_1_sum + 
              data.completed_wallet_total_payment_2_sum +
              data.completed_wallet_total_payment_3_sum -
              calculatePercentage(data.completed_wallet_total_payment_1_sum, 30) -
              calculatePercentage(data.completed_wallet_total_payment_2_sum, 30))
            , data.settlementRatio)
          ),

          formatNumber(
            Number(calculatePercentage((
              data.wallet_total_payment_0_sum + 
              data.wallet_total_payment_1_sum + 
              data.wallet_total_payment_2_sum +
              data.wallet_total_payment_3_sum -
              calculatePercentage(data.wallet_total_payment_1_sum, 30) -
              calculatePercentage(data.wallet_total_payment_2_sum, 30))
            , data.settlementRatio)
            -
            calculatePercentage((
              data.completed_wallet_total_payment_0_sum + 
              data.completed_wallet_total_payment_1_sum + 
              data.completed_wallet_total_payment_2_sum +
              data.completed_wallet_total_payment_3_sum -
              calculatePercentage(data.completed_wallet_total_payment_1_sum, 30) -
              calculatePercentage(data.completed_wallet_total_payment_2_sum, 30))
            , data.settlementRatio)
          ))
      ]
    })
    downloadCSV(headers, dataList, 'CP사 정산')
  }

  return (
    <Container>
      <ButtonTitle 
        title='CP사 정산'
        containerSytle={{ marginBottom: 60}}
      />

      <SearchContainer>
        <div className="start_date">
          <DatePicker
            selected={selectedDate}
            onChange={date => setSelectedDate(date)}
            dateFormat="yyyy-MM" // 날짜 형식 지정
            locale="ko" // 한국어 locale 사용
            showMonthYearPicker
            placeholderText="날짜를 선택하세요"
          />
        </div>

        <SearchBar
          searchBarContainerStyle={{ width: "600px", height: "46px", margin: '0' }}
          fontSize={15}
          dKey="searchCategory"
          label={"출판사"}
          search={searchTitle}
          onChange={(e) => setSearchTitle(e.target.value)}
        />
      </SearchContainer>

      <ButtonContainer>
        <Button width={140} onClick={() => {
          setPage(1);
          getData();
        }}>조회하기</Button>
      </ButtonContainer>

      <div className="mt-100"/>

      <TotalCount>전체 {totalCount}개</TotalCount>
      
      <div className="mt-24"/>
      
      <div className="mt-24"/>
      
      {list?.lenegth !== 0 && 
      <>
        <Table
          id="sale-list"
          selected={selected}
          setSelected={setSelected}
          className="mb-50"    
          header={HEADERS}
          datas={list?.map((data) => ({
            id: data,
            renders: [
              { render: () => data.name },
              { render: () => data.novel_list_count},
              { render: () => formatNumber(data.wallet_total_payment_0_sum) },
              { render: () => formatNumber(data.wallet_total_payment_1_sum) },
              { render: () => formatNumber(data.wallet_total_payment_2_sum) },
              { render: () => formatNumber(data.wallet_total_payment_3_sum) },
              { render: () => formatNumber(data.wallet_total_payment_0_sum + data.wallet_total_payment_1_sum + data.wallet_total_payment_2_sum + data.wallet_total_payment_3_sum) },
              { render: () => data.settlementRatio + "%"},
              { render: () => "30%"},
              { render: () => formatNumber(calculatePercentage(data.wallet_total_payment_1_sum, 70))},
              { render: () => "30%"},
              { render: () => formatNumber(calculatePercentage(data.wallet_total_payment_2_sum, 70))},
              { render: () => (100 - data.settlementRatio) + "%"},
              { render: () => 
                formatNumber(
                  calculatePercentage(data.wallet_total_payment_0_sum + 
                    data.wallet_total_payment_1_sum + 
                    data.wallet_total_payment_2_sum + 
                    data.wallet_total_payment_3_sum -
                    calculatePercentage(data.wallet_total_payment_1_sum, 30) -
                    calculatePercentage(data.wallet_total_payment_2_sum, 30)
                  , (100 - data.settlementRatio))
                )
              },
              { render: () => 
                formatNumber(
                  calculatePercentage((
                    data.wallet_total_payment_0_sum + 
                    data.wallet_total_payment_1_sum + 
                    data.wallet_total_payment_2_sum +
                    data.wallet_total_payment_3_sum -
                    calculatePercentage(data.wallet_total_payment_1_sum, 30) -
                    calculatePercentage(data.wallet_total_payment_2_sum, 30))
                  , data.settlementRatio)
                )
              },
              { render: () => 
                formatNumber(
                  calculatePercentage((
                    data.completed_wallet_total_payment_0_sum + 
                    data.completed_wallet_total_payment_1_sum + 
                    data.completed_wallet_total_payment_2_sum +
                    data.completed_wallet_total_payment_3_sum -
                    calculatePercentage(data.completed_wallet_total_payment_1_sum, 30) -
                    calculatePercentage(data.completed_wallet_total_payment_2_sum, 30))
                  , data.settlementRatio)
                )
              },
              { render: () => 
                formatNumber(
                  Number(calculatePercentage((
                    data.wallet_total_payment_0_sum + 
                    data.wallet_total_payment_1_sum + 
                    data.wallet_total_payment_2_sum +
                    data.wallet_total_payment_3_sum -
                    calculatePercentage(data.wallet_total_payment_1_sum, 30) -
                    calculatePercentage(data.wallet_total_payment_2_sum, 30))
                  , data.settlementRatio)
                  -
                  calculatePercentage((
                    data.completed_wallet_total_payment_0_sum + 
                    data.completed_wallet_total_payment_1_sum + 
                    data.completed_wallet_total_payment_2_sum +
                    data.completed_wallet_total_payment_3_sum -
                    calculatePercentage(data.completed_wallet_total_payment_1_sum, 30) -
                    calculatePercentage(data.completed_wallet_total_payment_2_sum, 30))
                  , data.settlementRatio)
                ))
              }
            ],
          }))}
        />

        <div className="flex align-center">
          <Dropdown
            containerStyle={{ width: "84px", minWidth: "84px" }}
            options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]}
            label={`${count}개`}
            dkey="pageSize"
            setSelect={({ value: selected }) => {
              setCount(selected);
            }}
          />
          
          <Pagination
            currentPage={page}
            totalPage={totalPage}
            onClickPage={(page) => setPage(page)}
          />
          
          <div className="mx-12"/>

          <Button style={{ marginLeft: 'auto' }} width={160} onClick={() => {
            settlementSubmit();
          }}>선택 정산 처리</Button>
          
          <Button style={{ marginLeft: '24px' }} width={160} onClick={() => {
            downloadCSVButton();
          }}>CSV 다운로드</Button>
        </div>
      </>}

    </Container>
  );
}

export default Sales;

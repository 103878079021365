import styled from "styled-components";
import { Modal } from "..";
import { useMemo, useState } from "react";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import { useParams } from "react-router-dom";

const ModalTitle = styled.div`
  font-size: 44px;
  font-weight: bold;
  margin-bottom: 80px;
`;


function useEpubUpdateModal() {
  const [isOpen, setIsOpen] = useState(false);
  const { uid } = useParams();
  
  return [
    ({ novelData, selected, episodeList, isBook }) => {
      const [step, setStep] = useState(1); // 1: 회차 수정, 2: 회차 수정 심사
      const [datas, setDatas] = useState(episodeList.map(item => {
        return {
          ...item,
          taxTag: item?.taxTag ? { name: item.taxTag, value: item.taxTag} : ""
        }
      })); // 


    
      const subTitle = useMemo(() => {
        if (step === 1) return "회차 수정";
        if (step === 2) return "화차 수정 심사";
      }, [step]);
      
      return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}  style={{
          width: '1600px',
          maxWidth: '96vw',
          height: '800px',
          maxHeight: '96vh'
        }}>
          <ModalTitle>
            {isBook ? "[단행본]" : ""} {novelData?.title} - {subTitle}
          </ModalTitle>

          {step === 1 && <Step1 
            datas={datas} 
            setDatas={setDatas}
            setStep={setStep}
            isBook={isBook}
          />}

          {step === 2 && <Step2 
            isBook={isBook}
            datas={datas} 
            setDatas={setDatas} 
            setStep={setStep} 
            onClose={() => setIsOpen(false)} 
            novel={novelData?._id}
            novel_uuid={uid}
          />}
        </Modal>
      )
    },
    () => setIsOpen(true),
  ];
};

export default useEpubUpdateModal;

import { fetchOwnLabelList } from '@/api/label';
import { book_cover_sample } from '@/assets';
import { Button, CheckBox, Dropdown, Input, LabelInput, Textarea } from '@/components'
import KeywordInput from '@/components/Keyword/KeywordInput';
import WriterInput from '@/components/Novel/WriterInput';
import { useGenreList } from '@/stores/genre'
import { useNovelCreateData } from '@/stores/novelCreate';
import { useNovelData } from '@/stores/novelData';
import { useNovelUpdateData } from '@/stores/novelUpdate';
import { formatImageUri } from '@/utils/format';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 60px 100px;


  .section_line {
    width: 100%;
    height: 2px;
    background-color: #000000;
  }

  .imageUpload {
    width: 155px;
    height: 246px;
    border-radius: 10px;
    
    .book_cover {
      width: 100%;
      height: 100%;
    }
  }
`


export default function Page () {
  const { 
    genreList,
  } = useGenreList();

  const { uid } = useParams();

  const { 
    PaidTypeList,
    SalesTypeList,
    TaxTagList,
    TaxTypeList,
    RentBenefitList,

    novelUpdateData, 
    
    pushWriterList,
    keywordSelect,
    handleChange,
    handleSerialCycle,
    submit,
  } = useNovelUpdateData(uid);

  console.log(novelUpdateData)

  return (
    <Container className='w-full'>
      <div className='bold font-30 mb-88'>작품 수정</div>
      <div className='bold font-24'>기본 정보</div>

      <div className='section_line my-24'/>

      <LabelInput background='#b9b9b9' value={novelUpdateData.title} readOnly onChange={e => handleChange('title', e.target.value)} label={"작품명"} required/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          장르 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <Dropdown options={genreList.map((item) => { return { value: item._id, name: item.name}})} select={novelUpdateData.genre} setSelect={item => handleChange('genre', item)}/>
        </div>
      </>

      <div className='mt-32'>
        <LabelInput label="연령등급" value={novelUpdateData.ageGrade.value} required readOnly background='#b9b9b9'/>
      
      </div>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          연재 요일 <span className='c-red'>*</span>
        </div>

        <div className='w-full flex align-center'>
          {novelUpdateData.serialCycle.map((item, idx) => (
            <div className='mr-16' key={`checkbox-idx-${idx}`}>
              <CheckBox selected={item} onClick={() => handleSerialCycle(idx)} label={['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'][idx]}/>
            </div>
          ))}
        </div>
      </>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          완결 여부 <span className='c-red'>*</span>
        </div>

        <div className='w-full flex align-center'>
          <CheckBox selected={!novelUpdateData.hasCompletion} onClick={e => handleChange('hasCompletion', false)} label={"연재중"}/>
          
          <div className='mr-16'/>

          <CheckBox selected={novelUpdateData.hasCompletion} onClick={e => handleChange('hasCompletion', true)} label={"완결"}/>
        </div>
      </>
      
      
      <div style={{ width: '180px'}} className='mt-32'>
        <LabelInput value={novelUpdateData.non_rental_episode_count} onChange={e => handleChange('non_rental_episode_count', e.target.value)} required label={"대여 불가 회차 수"}/>
      </div>
      
      <>
        <div className='bold font-18 mt-32 mb-10'>
          작품 소개 <span className='c-red'>*</span>
        </div>

        <div className='w-full'>
          <Textarea value={novelUpdateData.summary} onChange={e => handleChange('summary', e.target.value)}/>
        </div>
      </>


      {/* <>
        <div className='bold font-18 mt-32 mb-10'>
          출판사 레이블
        </div>

        <div className='' style={{ width: '180px'}}>
          <Dropdown options={labelList} select={novelUpdateData.cpLabel} setSelect={item => handleChange('cpLabel', item)}/>
        </div>  
      </> */}


      <div className='bold font-24 mt-68'>작가 정보</div>
      <div className='section_line my-24'/>

      <WriterInput pushWriterList={pushWriterList}/>
      
      <div className=''>
        {novelUpdateData.writer_list?.map((item, idx) => (
          <div className='bold' key={`selected-writer-${item.name}-${idx}`}>
            {item.name}
          </div>
        ))}
      </div>


      <div className='bold font-24 mt-68'>기타 정보</div>
      <div className='section_line my-24'/>

      <KeywordInput 
        selectedGenre={novelUpdateData.genre} 
        selectKeywordList={novelUpdateData.keywords} 
        keywordSelect={keywordSelect}
      />
      
      <>
        <div className='bold font-18 mt-32 mb-10'>
          유형 구분 <span className='c-red'>*</span>
        </div>
        <div className='' style={{ width: '180px'}}>
          <Dropdown options={SalesTypeList} select={novelUpdateData.salesType} setSelect={item => handleChange('salesType', item)}/>
        </div>
      </>

      
      <div className='bold font-24 mt-68'>면세 정보</div>
      <div className='section_line my-24'/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          면세/과세 <span className='c-red'>*</span>

        </div>
        <div className='' style={{ width: '180px'}}>
          <Dropdown options={TaxTypeList} select={novelUpdateData.taxType} setSelect={item => handleChange('taxType', item)}/>
        </div>
      </>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          면세(ISBN) 코드 <span className='c-red'>*</span>
        </div>

        <div className='flex align-center w-full'>
          <div className='' style={{ width: '140px'}}>
            <Dropdown options={TaxTagList} select={novelUpdateData.taxTag} setSelect={item => handleChange('taxTag', item)}/>
          </div>

          <div className='font-30 bold mx-18'>-</div>

          <div className='flex-1'>
            <Input value={novelUpdateData.taxCode} onChange={e => handleChange('taxCode', e.target.value)}/>
          </div>
        </div>
      </>



      <>
        <div className='bold font-18 mt-32 mb-10'>
          유/무료 정책 <span className='c-red'>*</span>

        </div>
        <div className='' style={{ width: '180px'}}>
          <Dropdown options={PaidTypeList} select={novelUpdateData.isPaid} setSelect={item => handleChange('isPaid', item)}/>
        </div>
      </>



      <>
        <div className='bold font-18 mt-32 mb-10'>
          전자책 정가 <span className='c-red'>*</span>

        </div>
        <div className='flex align-center w-full'>
          <div className='' style={{ width: '180px'}}>
            <Input value={novelUpdateData.regularPrice} onChange={e => handleChange('regularPrice', e.target.value)}/>
          </div>

          <div className='font-18 bold ml-12'>원 / 1화</div>

        </div>
      </>

      
      <div className='bold font-24 mt-68'>썸네일 설정</div>
      <div className='section_line my-24'/>

      <>
        <div className='bold font-18 mt-32 mb-24'>
          썸네일 <span className='c-red'>*</span>
        </div>

        <div className=''>
          <input type="file" accept="image/*" id="imageUpload" style={{ display: "none" }} onChange={(e) => handleChange('book_cover_file', e.target.files[0])}/>
          
          <label htmlFor='imageUpload' className='imageUpload flex-center flex-col w-full'>
            <img 
              className='book_cover mb-24 pointer' 
              src={(novelUpdateData.book_cover_file ? URL.createObjectURL(novelUpdateData.book_cover_file) : null) || formatImageUri(novelUpdateData.book_cover)}/>
          </label>
        </div>
      </>


      
      <div className='bold font-24 mt-68'>독자 혜택</div>
      <div className='section_line my-24'/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          신규 독자 전용 대여권 혜택

        </div>
        <div className='' style={{ width: '180px'}}>
          <Dropdown options={RentBenefitList} select={novelUpdateData.rentBenefit} setSelect={item => handleChange('rentBenefit', item)}/>
        </div>
      </>


      <div className='mt-80'/>
      <Button style={{width: '100%'}} onClick={submit}>작품 수정</Button>
    </Container>
  )
}
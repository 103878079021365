import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { fetchNovelList, fetchOwnNovelList } from '@/api/novel';

const novelListState = atom({
  key: 'NovelList',
  default: {
    list: [], // 리스트 데이터
    totalPage: 0, // 총 페이지 수
    totalCount: 0, // 총 갯수
  },
});

export const pageState = atom({
  key: 'pageState',
  default: 1,
});

export const countState = atom({
  key: 'countState',
  default: 10,
});

export const useNovelList = () => {
  const [novelList, setNovelList] = useRecoilState(novelListState);
  const [page, setPage] = useRecoilState(pageState);
  const [count, setCount] = useRecoilState(countState);

  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    if(isLoading) return;
    setIsLoading(true);

    const countParam = searchParams.get('count');
    const pageParam = searchParams.get('page');
    const searchParam = searchParams.get('search');
    const genreParam = searchParams.get('genre');
    const hasCompletionParam = searchParams.get("hasCompletion");
1
    if (countParam) {
      setCount(Number(countParam));
    }
    if (pageParam) {
      setPage(Number(pageParam));
    }

    const getNovelList = async (page, count) => {
      try {
        const data = await fetchNovelList(page, count, searchParam, genreParam, hasCompletionParam);
        setNovelList(data);
      } catch (error) {
        console.error('Failed to fetch novel genre list:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getNovelList(page, count || 10);
  }, [searchParams, setCount, setPage, setNovelList]);

  const handlePage = (pageValue) => {
    setPage(pageValue);
    setSearchParams((prev) => {
      prev.set('page', pageValue);
      return prev;
    })
  }
  
  const handleCount = (countValue) => {
    setCount(countValue);
    setSearchParams((prev) => {
      prev.set('count', countValue);
      return prev;
    })
    handlePage(1);
  };

  return {
    novelList,
    page,
    count,
    isLoading,
    handlePage,
    handleCount,
  };
};

import { pick, isNil } from 'lodash';

export const validateEmail = (value) => {
  const emailRule = /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
  if (!value) return false;
  if (value.length === 0 || emailRule.test(value)) return true;
  return false;
};

export const validatePhone = (value) => {
  const phoneRule = /^\d{2,3}-?\d{3,4}-?\d{4}$/;
  if (!value) return false;
  if (value.length === 0 || phoneRule.test(value)) return true;
  return false;
};


export const validateNumber = (value) => {
  if(!value) return false;
  console.log(value)

  const numberRule = /^[0-9]+$/;

  if (!value) return false;
  if (value.length === 0 || numberRule.test(value)) return true;
  return false;
};

export const validateLength = (value, length) => {
  if (value == null) return false;
  if (value.length <= length) return true;
  return false;
};

export const validateMinLength = (value, length) => {
  if (value == null) return false;
  if (value.length >= length) return true;
  return false;
};

export const validateFile = (value, size) => {
  if (!value) return false;
  if (value.size <= size) return true;
  return false;
};

export const validateNotEmpty = (value) => {
  if (value === null || value === undefined || value === '') return false;
  return true;
}

export const validateSpecialChar = (value) => {
  if (!value || value.length === 0) return false;
  const regex = /^[a-zA-Z0-9가-힣\s]+$/;
  if (regex.test(value)) return true;
  return false;
}
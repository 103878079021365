import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ic_page_prev } from '@/assets';


const SimpleButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0 10px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
`;
//  className="flex align-center gap-20 mb-100"
const Container =styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 100px;
`

function ButtonTitle(
  {
    title,
    backButton=false,
    containerSytle,
    to
  }
){

  const navigate = useNavigate();

  return<Container style={{...containerSytle}}>
    { backButton && 
      <SimpleButton onClick={() => { navigate(to ?? -1) }} >
        <img src={ ic_page_prev } alt='back' style={{ height: '30px' }} />
      </SimpleButton>
    }
  <Title>{title}</Title>
</Container>
}

export default ButtonTitle;
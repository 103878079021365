import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import {useSearchParams } from 'react-router-dom';
import { fetchNoticeList } from '@/api/notice';

const noticeListDataState = atom({
  key: 'noticeListData',
  default: {
    list: [],
    totalPage: 0,
    totalCount: 0,
  },
});


export const pageState = atom({
  key: 'noticeListPageState',
  default: 1,
});

export const countState = atom({
  key: 'noticeListCountState',
  default: 10,
});


export const useNoticeList = () => {
  const [noticeList, setNoticeList] = useRecoilState(noticeListDataState);
  const [page, setPage] = useRecoilState(pageState);
  const [count, setCount] = useRecoilState(countState);

  const [isLoading, setIsLoading] = useState(false);
  
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePage = (pageValue) => {
    setPage(pageValue);
    setSearchParams((prev) => {
      prev.set('page', pageValue);
      return prev;
    })
  }
  
  const handleCount = (countValue) => {
    setCount(countValue);
    setSearchParams((prev) => {
      prev.set('count', countValue);
      return prev;
    })
    handlePage(1);
  };

  useEffect(() => {
    const countParam = searchParams.get('count');
    const pageParam = searchParams.get('page');
    
    if (countParam) {
      setCount(Number(countParam));
    }
    if (pageParam) {
      setPage(Number(pageParam));
    }

    const getNoticeListData = async () => {
      if (isLoading) return;
      setIsLoading(true);
      
      try {
        const response = await fetchNoticeList(page, count);
        setNoticeList({
          list: response.list,
          totalPage: response.totalPage,
          totalCount: response.totalCount,
        });
      } catch (error) {
        console.log({error})
        console.error('Failed to fetch notice list:', error);
      } finally {
        setIsLoading(false);
      }
    }


    getNoticeListData();


  }, [searchParams, setCount, setPage, setNoticeList])

  return {
    isLoading,
    noticeList,
    page,
    count,
    handlePage,
    handleCount,
  }
}
import { Button, Modal, TabBar } from "@/components";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { useEffect, useState } from "react";
import styled from "styled-components";
import CpInfomation from "@/components/CpManageSections/CpInfomation";
import ContentList from "@/components/CpManageSections/ContentList";
import UpdateHistory from "@/components/CpManageSections/UpdateHistory";
import PromotionHistory from "@/components/CpManageSections/PromotionHistory";
import { fetchCpDetail, fetchResetPasswordCP, fetchUpdateStatusCPData } from "@/api/cp";
import { useNavigate, useParams } from "react-router-dom";
import{ nanoid } from 'nanoid';
import { fetchPromotionNovelCPDetail } from "@/api/promotion";

const TAB_CONFIG = ["정보", "작품목록", "승인이력", "프로모션 이력"];
const STATUS_LIST = ["정상", "출판 정지"]

const Container = styled.div`
  padding: 60px 100px;
`;

const UserInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 100px;
`;

const Name = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

const InfoBox = styled.div`
  display: flex;
  gap: 20px;
  font-size: 21px;
  font-weight: bold;

  .content {
    color: #636363;
    font-weight: normal;
  }
`;

function Page() {
  const navigate = useNavigate();
  const { uid: cpId } = useParams();
  const [tab, setTab] = useState(TAB_CONFIG[0]);
  const [cpInfo, setCpInfo] = useState();
  const [labels, setLabels] = useState([]);

  const [password, setPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  
  const [cpDetailCoinPromotionList, setCPDetailPromotionCoinList] = useState([])
  const [cpDetailExposureExPromotionList, setCPDetailPromotionExposureList] = useState([])


  
  useEffect(() => {
    if (!cpId) {
      alert("잘못된 접근입니다.");
      navigate("/cp/list");
    }

    (async () => {
      try{
        const data = await fetchCpDetail(cpId);
        setCpInfo(data.cp);
        setLabels(data.label);
        
        const promotionList1 = await fetchPromotionNovelCPDetail(data.cp._id, 'coin');
        const promotionList2 = await fetchPromotionNovelCPDetail(data.cp._id, 'exposure');

        setCPDetailPromotionCoinList(promotionList1);
        setCPDetailPromotionExposureList(promotionList2);

      } catch (error) {
        alert("CP 정보를 불러오는데 실패했습니다.");
        navigate("/cp/list");
      }
    })();
  }, [cpId]);


  if (!cpInfo) return null;

  async function resetPassword() {
    const new_pw = nanoid(12);
    setPassword(new_pw);

    const confirm = window.confirm('정말 초기화 하시겠습니까?');

    if(confirm) {
      await fetchResetPasswordCP(cpId, new_pw);
      setIsModalOpen(true);
    }
  }

  
  const copyToClipboard = () => {
    const textToCopy = `ID: ${cpInfo?.email}\nPW: ${password}`;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        alert('클립보드에 복사되었습니다!');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  async function updateStatus() {
    // {(cpInfo?.status ?? 0) === 0 ? "출판 정지" : "정지 해제" }
    
    const value = (cpInfo?.status ?? 0) === 0 ? 1 : 0;
    
    await fetchUpdateStatusCPData(cpId, value);
    window.location.reload();
  }

  return (
    <Container>
      <Modal isOpen={isModalOpen} onClose={() => {}} height={520}>
        <div className='flex-col flex-center'>
          <div className='bold font-32 mb-40'>CP사 비밀번호 초기화 성공</div>

          <div className='medium font-20'>ID: {cpInfo?.email}</div>
          <div className='medium font-20'>PW: {password}</div>

          <div className='text-center bold mt-40 mb-20'>위 계정을 복사해 CP사로 보내주세요.</div>
          <Button onClick={copyToClipboard}>계정 정보 복사</Button>

          <div className='bold font-20 text-center mt-60 mb-40'>
            계정정보는 당 cp사 외에는 그 어느곳에도 공유하지마세요.<br/>
            이 화면 외에는 계정정보를 복사할 수 없으니 주의해주세요.  
          </div>


          <Button onClick={() => {
            setIsModalOpen(false)
          }}>닫기</Button>
        </div>
      
      </Modal>
      
      <ButtonTitle backButton title={cpInfo.name} containerSytle={{ marginBottom: '85px' }} />
      
      <UserInformationContainer>
        <div className="flex align-center gap-30">
          <Name>{cpInfo.name}</Name>
          <Button width={140} to={`/cp/edit/${cpId}`}>정보 수정</Button>
          <Button width={140} onClick={updateStatus}> 
            {(cpInfo?.status ?? 0) === 0 ? "출판 정지" : "정지 해제" }
          </Button>
          <Button width={160} onClick={resetPassword}>비밀번호 초기화</Button>

        </div>
        <InfoBox>
          <div>대표이사</div>
          <div className="content">{cpInfo.ceo_name}</div>
        </InfoBox>
        <InfoBox>
          <div>사업자번호</div>
          <div className="content">{cpInfo.business_number}</div>
        </InfoBox>
        <InfoBox>
          <div>이메일</div>
          <div className="content">{cpInfo.email}</div>
        </InfoBox>
        <InfoBox>
          <div>레이블</div>
          <div className="content">{labels.map(({name}) => name).join(', ')}</div>
        </InfoBox>
        <InfoBox>
          <div>정산비율</div>
          <div className="content">{cpInfo.settlementRatio}%</div>
        </InfoBox>
        <InfoBox>
          <div>상태</div>
          <div className="content">{(cpInfo?.status ?? 0) === 0 ? "정상" : "출판 정지 중" }</div>
        </InfoBox>
      </UserInformationContainer>

      <div className="flex mb-50 justify-between">
        <TabBar tabArr={TAB_CONFIG} currentTab={tab} clickTab={setTab} />
      </div>

      {tab === "정보" && <CpInfomation cpInfo={cpInfo} />}
      {tab === "작품목록" && <ContentList cpInfo={cpInfo} />}
      {tab === "승인이력" && <UpdateHistory cpInfo={cpInfo}/>}
      {tab === "프로모션 이력" && 
        <PromotionHistory 
          cpDetailCoinPromotionList={cpDetailCoinPromotionList}
          cpDetailExposureExPromotionList={cpDetailExposureExPromotionList}
        />
        
        }
      
    </Container>
  )
}

export default Page;

import { fetchStalierDetailData, fetchStalierEpisodeList, fetchStalierUpdateActive } from '@/api/stalier';
import { ic_page_prev } from '@/assets';
import { Button } from '@/components';
import StalierInfoTab from '@/components/StalierDetailSection/InfoTab';
import { formatImageUri } from '@/utils/format';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'


const infoHEADERS=[
  {name : '순서', minWidth : 120 },
  {name : '제목', minWidth : 250 },
  {name : '발행일', minWidth : 120 },
  {name : '상태', minWidth : 60 },
  {name : '미리보기', minWidth : 120 },
];


const Container = styled.div`
  padding: 60px;
`;


const HeadContainer = styled.div`
  margin-bottom: 100px;

`

const Title = styled.div`
  margin-left: 20px;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-align: left;
  color: #000;

`

const NovelInfo = styled.div`
  display: flex;
  gap: 20px;
`

const NovelInfoTitle = styled.div`
  font-family: Pretendard;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: #000;
`



const NovelInfoSubBold = styled.div`
  font-family: Pretendard;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0.42px;
  text-align: left;
  color: #000;
`

const NovelInfoSub = styled.div`
  font-family: Pretendard;
  font-size: 21px;
  letter-spacing: 0.42px;
  text-align: left;
  color: #636363;
  margin-left: 20px;
  display: flex;
`



export default function Page () {
  const { uid } = useParams();

  const [detail, setDetail] = useState(null);


  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [episodeList, setEpisodeList] = useState([]);


  useEffect(() => {
    // 유효성 검사
    if(uid) getData();
  }, [uid]);

  async function getData() {
    const response = await fetchStalierDetailData(uid);
    setDetail(response);
  }

  async function handleActive() {
    const response = await fetchStalierUpdateActive(uid, !detail?.isActive);
    return window.location.reload();
  }


  useEffect(() => {
    getEpisodeList();
  }, [])


  async function getEpisodeList() {
    const result = await fetchStalierEpisodeList(uid);
    setEpisodeList(result);
  }
  
  const navigate = useNavigate();

  return (
    <Container>
      <HeadContainer className='flex align-center'>
        <img src={ic_page_prev} className="pointer" alt='back' style={{ width: '34px',height : '23px' }} onClick={() => {
          navigate(-1);
        }}/>
        <Title>{detail?.title}</Title>
      </HeadContainer>


      <div className='ml-36'>
           
        <NovelInfo className ='mb-90'>
          {detail?.book_cover && <img src={formatImageUri(detail?.book_cover)} alt='novel' style={{ width: '206px', height: '296px' }} />}
          <div>
            <div className='flex mb-20'>
              {detail?.isActive ? 
                <Button background={'red'} width={139} height={45} onClick={handleActive}>비활성화</Button> :
                <Button background={'red'} width={139} height={45} onClick={handleActive}>활성화</Button>            
              }
            </div>
            <NovelInfoTitle className='mb-30'>{detail?.title}</NovelInfoTitle>
            <div className='flex mb-22'>
              <NovelInfoSubBold>작가</NovelInfoSubBold>
              <NovelInfoSub>
                <div className='flex'>
                  <NovelInfoSub style={{marginLeft:'5px'}}>{detail?.writer_user?.name}</NovelInfoSub>  
                </div>
              </NovelInfoSub>
            </div>
      
  
            <div className='flex mb-22'>
              <NovelInfoSubBold>장르</NovelInfoSubBold>
              <NovelInfoSub>
                  <NovelInfoSub style ={{marginLeft:'5px'}}>{detail?.genre?.name}</NovelInfoSub>  
              </NovelInfoSub>  
            </div>

            <div className='flex'>
              <NovelInfoSubBold>키워드</NovelInfoSubBold>
              <NovelInfoSub>
                {detail?.keywords.map(item => item.name).map((keyword,index) => (
                  <div className='flex' key={index}>
                    <NovelInfoSub style ={{marginLeft:'5px'}}>#{keyword}{index !== detail?.keywords.length - 1 && ','}</NovelInfoSub>  
                  </div>
                ))}
              </NovelInfoSub>  
            </div>
          </div>
        </NovelInfo>
      </div>
      

      <div>
        <StalierInfoTab
          novelData={detail}
          summary={detail?.summary} 
          // infoArr={defaultInfo} 
          infoHEADERS={infoHEADERS} 
          datas={episodeList}
          getEpisodeList={getEpisodeList}
        />
      </div>
    </Container>
  )
}
import axios, { axiosAuth } from "@/lib/axios";


// 장르에 맞는 소설 리스트 가져오기
export const fetchNovelGenreList = async (genre, page) => {
  const { data } = await axios.get(`/novel/nlist/${page}/${genre}`);
  return data;
};

// 검색어에 제목이 해당하는 소설 리스트 가져오기
export const fetchNovelTitleSearchList = async (searchText) => {
  const { data } = await axiosAuth.get(`/novel/admin/search/title/${searchText}`);
  return data;
};

// 소설 회차 리스트 가져오기
export const fetchNovelEpisodeList = async (uuid, page, count) => {
  const { data } = await axios.get(`/episode/list/${uuid}/${page}/${count}`);
  return data;
}


export const fetchNovelEpisodeData = async (uuid) => {
  const { data } = await axios.get(`/episode/detail/${uuid}`);
  return data;
}


export const fetchNewNovelData = async (fetchData) => {
  try {
    const { data } = await axiosAuth.post('/novel/admin/new', {
      ...fetchData
    })

    alert('작품을 생성했습니다.')


    return data;
  } catch (e) {
    return alert('작품 생성에 실패했습니다.')
  }
}



export const fetchNovelUnActivate = async (uid) => {
  try {
    const { data } = await axiosAuth.post('/novel/admin/unactivate', {
      novel_uuid: uid
    })
    

    return data;
  } catch (e) {
    return alert('작품 생성에 실패했습니다.')
  }
}


export const fetchOwnNovelList = async (page, count, search, genre, hasCompletion) => {
  const query = [];
  if (search) query.push(`search=${search}`);
  if (genre) query.push(`genre=${genre}`);
  if (hasCompletion) query.push(`hasCompletion=${hasCompletion}`);

  const { data } = await axiosAuth.get(`/novel/admin/own/list/${page}/${count}?${query.join('&')}`);
  return data;
}



export const fetchCPNovelList = async (page, count, cp_id) => {
  const { data } = await axiosAuth.get(`/novel/admin/list/${cp_id}/${page}/${count}`);
  return data;
}



export const fetchNovelList = async (page, count, search, genre, hasCompletion) => {
  const query = [];
  if (search) query.push(`search=${search}`);
  if (genre) query.push(`genre=${genre}`);
  if (hasCompletion) query.push(`hasCompletion=${hasCompletion}`);
  
  const { data } = await axiosAuth.get(`/novel/admin/list/${page}/${count}?${query.join('&')}`);
  return data;
}


export const fetchUpdateNovelData = async (fetchData) => {
  try {
    const { data } = await axiosAuth.post('/novel/admin/update', {
      ...fetchData
    })

    alert('작품을 수정했습니다.')

    return data;
  } catch (e) {
    return alert('작품 수정에 실패했습니다.')
  }
}


// 소설 상세
export const fetchNovelDetailData = async (uuid) => {
  const { data } = await axiosAuth.get(`/novel/admin/detail/${uuid}`);
  return data;
}

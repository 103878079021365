import styled from 'styled-components'
import { LabelInput } from '..'
import { useEffect, useState } from 'react';
import KeywordModal from './KeywordModal';
import { fetchkeywordList } from '@/api/keyword';


const Container = styled.div`

`


export default function KeywordInput ({
  selectedGenre,
  selectKeywordList, // 선택된 키워드 리스트
  keywordSelect
}) {
  const [isKeywordOpen, setIsKeywordOpen] = useState(false);

  const [list, setList] = useState([]);

  useEffect(() => {
    getData();
  }, [])

  async function getData () {
    const result = await fetchkeywordList();
    setList(result);
  }

  return (
    <>
      <KeywordModal 
        selectedGenre={selectedGenre}
        keywordList={list} 
        keywordSelect={keywordSelect}
        selectKeywordList={selectKeywordList}
        isOpen={isKeywordOpen} 
        setIsOpen={setIsKeywordOpen}
      />

      <Container>
        <LabelInput
          label={"키워드"} 
          readOnly
          background='#fff'
          required
          value={selectKeywordList.map(item => '#' + item.name).join(', ')}
          onClick={() => setIsKeywordOpen(true)}
        />
      </Container>
    </>
  )
}
import theme from "@/styles/theme";
import { Bar, ResponsiveContainer, XAxis, YAxis, BarChart as RechartBarChart, Tooltip } from "recharts";

const COLORS = Object.values(theme);

const BarChart = (({data, select, margin, width, height, xAxis, yAxis, colors}) => {
console.log({select})
  return (
    <ResponsiveContainer width={width ?? '100%'} height={height ?? 200}>
      <RechartBarChart
        data={data}
        margin={margin}
      >
        {xAxis ? <XAxis dataKey={xAxis.dataKey} fontSize={xAxis.fontSize ?? 12} />: null}
        {yAxis ? <YAxis fontSize={yAxis.fontSize ?? 12} /> : null}

        {select.map((item, index) => (
          <Bar key={index} dataKey={item} fill={colors ? colors[index] : COLORS[index]} />
        ))}
        <Tooltip cursor={false} />
      </RechartBarChart>
    </ResponsiveContainer>
  )
})

export default BarChart;

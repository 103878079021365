import styled from "styled-components";
import { Dropdown, Pagination, Table } from "..";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const HEADERS = [
  { name: "정지 일시", minWidth: 120 },
  { name: "정지 종료 일시", minWidth: 120 },
  { name: "정지 종류", minWidth: 200 },
  { name: "정지 사유", minWidth: 400 },
  { name: "비고", minWidth: 300 },
];

const DATAS = [
  {
    id: 1,
    stopDate: "2021-09-01",
    stopEndDate: "2021-09-02",
    stopType: "댓글정지",
    stopReason: "댓글 내용: “시불 시불 시불” 작성, 욕설 정책 위반으로 댓글작성 정지",
    note: "",
  },
  {
    id: 2,
    stopDate: "2021-09-01",
    stopEndDate: "2021-09-02",
    stopType: "서비스 이용정지 (15일)",
    stopReason: "댓글 내용: “시불 시불 시불” 작성, 욕설 정책 위반으로 댓글작성 정지",
    note: "",
  },
  {
    id: 3,
    stopDate: "2021-09-01",
    stopEndDate: "2021-09-02",
    stopType: "서비스 이용정지 (30일)",
    stopReason: "댓글 내용: “시불 시불 시불” 작성, 욕설 정책 위반으로 댓글작성 정지",
    note: "",
  },
  {
    id: 4,
    stopDate: "2021-09-01",
    stopEndDate: "2021-09-02",
    stopType: "서비스 이용정지 (영구)",
    stopReason: "댓글 내용: “시불 시불 시불” 작성, 욕설 정책 위반으로 댓글작성 정지",
    note: "",
  },
  {
    id: 5,
    stopDate: "2021-09-01",
    stopEndDate: "2021-09-02",
    stopType: "서비스 이용정지 (영구)",
    stopReason: "댓글 내용: “시불 시불 시불” 작성, 욕설 정책 위반으로 댓글작성 정지",
    note: "",
  },
  {
    id: 6,
    stopDate: "2021-09-01",
    stopEndDate: "2021-09-02",
    stopType: "서비스 이용정지 (영구)",
    stopReason: "댓글 내용: “시불 시불 시불” 작성, 욕설 정책 위반으로 댓글작성 정지",
    note: "",
  },
  {
    id: 7,
    stopDate: "2021-09-01",
    stopEndDate: "2021-09-02",
    stopType: "서비스 이용정지 (영구)",
    stopReason: "댓글 내용: “시불 시불 시불” 작성, 욕설 정책 위반으로 댓글작성 정지",
    note: "",
  },
]

const TotalCount = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
`;

function BlockHistory() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [pagination, setPagination] = useState({
    page: searchParams.get("page") || 1,
    pageSize: searchParams.get("pageSize") || 10,
    total: 100,
  });

  return (
    <div>
      <TotalCount>전체 100개</TotalCount>

      <Table
        id="sale-list"
        className="mb-50"
        header={HEADERS}
        datas={
          DATAS.map((data) => ({
            id: data.id,
            renders: [
              { render: () => data.stopDate },
              { render: () => data.stopEndDate },
              { render: () => data.stopType },
              { render: () => data.stopReason },
              { render: () => data.note },
            ],
          }))
        }
      />

      <div className="flex align-center justify-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]}
          label={`${pagination.pageSize}개`}
          dkey="pageSize"
          setSelect={({ value: selected }) => {
            setPagination((prev) => ({ ...prev, pageSize: selected }));
            setSearchParams((prev) => {
              prev.set("pageSize", selected);
              return prev;
            });
          }}
        />
        <Pagination
          currentPage={pagination.page}
          totalPage={
            Math.ceil(pagination.total / pagination.pageSize)
          }
          onClickPage={(page) => {
            setPagination((prev) => ({ ...prev, page }));
            setSearchParams((prev) => {
              prev.set("page", page);
              return prev;
            });
          }}
        />
      </div>
    </div>
  );
}

export default BlockHistory;

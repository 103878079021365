import axios, { axiosAuth } from "@/lib/axios";

// 작품 판매 현황 
export const fetchGetSalesStatus = async (
  page,
  count,
  startDate,
  endDate, 
  searchTitle
) => {
  const { data } = await axiosAuth.get(`/statistics/admin/salesstatus/${page}/${count}/${startDate}/${endDate}?searchTitle=${searchTitle}`);
  
  return data;
}



// CP사 통계
export const fetchGetCPSalesStatus = async (
  page,
  count,
  startDate,
  endDate, 
  searchTitle
) => {
  const { data } = await axiosAuth.get(`/statistics/admin/cp/salesstatus/${page}/${count}/${startDate}/${endDate}?searchTitle=${searchTitle}`);
  
  return data;
}


// CP사 정산
export const fetchGetCPSettlement = async (
  page,
  count,
  startDate,
  endDate, 
  searchTitle
) => {
  const { data } = await axiosAuth.get(`/statistics/admin/cp/settlement/${page}/${count}/${startDate}/${endDate}?searchTitle=${searchTitle}`);
  
  return data;
}



export const fetchPutCPSettlement = async (
  cp_id_list,
  settlement_amount,
  startDate,
  endDate, 
) => {
  try {
    const { data } = await axiosAuth.post(`/statistics/admin/cp/settlement`, {
      cp_id_list,
      settlement_amount,
      startDate,
      endDate
    });

    alert('정산 처리가 완료되었습니다.')
    return data;
  } catch(e) {
    console.log(e);
    alert('정산 처리에 실패했습니다.')
  }
  
  return data;
}

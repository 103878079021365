import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchGenreList } from '@/api/genre';


const genreListState = atom({
  key: 'genreList',
  default: []
});


export const useGenreList = () => {
  const [genreList, setGenreList] = useRecoilState(genreListState);

  useEffect(() => {
    const getGenreList = async () => {
      try {
        const genreList = await fetchGenreList();

        
        // let sort_list = genreList.map((item) => {
        //   return {
        //     value: item._id,
        //     name: item.name
        //   }
        // })

        setGenreList(genreList || []);
      } catch (err) {
        console.error('Failed to Fetch Genre List', err);
      }
    }


    getGenreList();
  }, [])


  return { 
    genreList
  }

}
import { book_cover_sample } from '@/assets';
import { Button, Dropdown, LabelInput, Modal } from '@/components';
import { useCPUpdateData } from '@/stores/cpUpdate'
import { useState } from 'react';
import styled from 'styled-components'
import{ nanoid} from 'nanoid';
import { useParams } from 'react-router-dom';
import { formatImageUri } from '@/utils/format';
import ButtonTitle from '@/components/ButtonTitle/ButtonTitle';

const Container = styled.div`
  padding: 60px 100px;

  .section_line {
    width: 100%;
    height: 2px;
    background-color: #000000;
  }

  .imageUpload {
    width: 155px;
    height: 246px;
    background-color: #fff;
    border-radius: 10px;
    
    .book_cover {
      width: 100%;
      height: 100%;
    }
  }
`


export default function Page () {

  const { uid: cp_uid } = useParams();

  const {
    handleChange,
    submit,
    cpUpdateData,
    isLoading,
    BankList,
    ContractTypeList
  } = useCPUpdateData(cp_uid);

  async function onSubmit() {
    await submit(); 
  }


  return (
    <Container>
      <ButtonTitle backButton title='CP사 정보 수정' />
      <div className='bold font-24'>기본 정보</div>
      <div className='section_line my-24'/>
      
      <LabelInput value={cpUpdateData.name} onChange={e => handleChange('name', e.target.value)} label={"상호명"} required/>
      
      <div className='mt-32 mb-10'/>
            
      <LabelInput value={cpUpdateData.settlementRatio} onChange={e => handleChange('settlementRatio', e.target.value)} type="number" label={"정산비율"} required/>
      
      <div className='mt-32 mb-10'/>

      <LabelInput readOnly background='lightgrey' value={cpUpdateData.business_number} label={"사업자 번호"} required/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          계약 유형 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <Dropdown options={ContractTypeList} select={cpUpdateData.contractType} setSelect={item => handleChange('contractType', item)}/>
        </div>
      </>

      <div className='mt-32 mb-10'/>
      
      <LabelInput value={cpUpdateData.company_email} onChange={e => handleChange('company_email', e.target.value)} label={"대표 이메일"} required/>

      <div className='mt-32 mb-10'/>
      
      <LabelInput value={cpUpdateData.phone} onChange={e => handleChange('phone', e.target.value)} label={"대표 연락처"} required/>

      <div className='mt-32 mb-10'/>
      
      <LabelInput value={cpUpdateData.ceo_name} onChange={e => handleChange('ceo_name', e.target.value)} label={"대표이사명"} required/>

      <div className='mt-32 mb-10'/>
      
      <LabelInput readOnly background='lightgrey' value={cpUpdateData.email} label={"CP사 로그인용 Email"} required/>

      <div className='bold font-24 mt-60'>정산 정보</div>
      <div className='section_line my-24'/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          정산 은행 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <Dropdown options={BankList} select={cpUpdateData.bank_name} setSelect={item => handleChange('bank_name', item)}/>
        </div>
      </>

      <div className='mt-32 mb-10'/>
      
      <LabelInput value={cpUpdateData.bank_number} onChange={e => handleChange('bank_number', e.target.value)} label={"정산 계좌 번호"} required/>

      <div className='mt-32 mb-10'/>
      
      <LabelInput value={cpUpdateData.bank_account_owner} onChange={e => handleChange('bank_account_owner', e.target.value)} label={"정산 계좌 예금주"} required/>

      <div className='bold font-24 mt-60'>파일</div>
      <div className='section_line my-24'/>
      <>
        <div className='bold font-18 mt-32 mb-24'>
          통장사본 파일 <span className='c-red'>*</span>
        </div>

        <div className=''>
          <input type="file" accept="image/*" id="imageUpload1" style={{ display: "none" }} onChange={(e) => handleChange('bank_account_image_file_data', e.target.files[0])}/>
          
          <label htmlFor='imageUpload1' className='imageUpload flex-center flex-col w-full'>
            <img className='book_cover  pointer' src={(cpUpdateData.bank_account_image_file_data ? URL.createObjectURL(cpUpdateData.bank_account_image_file_data) : formatImageUri(cpUpdateData.bank_account_image))}/>
          </label>
        </div>
      </>



      <>
        <div className='bold font-18 mt-32 mb-24'>
          사업자등록증 파일 <span className='c-red'>*</span>
        </div>

        <div className=''>
          <input type="file" accept="image/*" id="imageUpload2" style={{ display: "none" }} onChange={(e) => handleChange('business_certificate_file_data', e.target.files[0])}/>
          
          <label htmlFor='imageUpload2' className='imageUpload flex-center flex-col w-full'>
            <img className='book_cover  pointer' src={(cpUpdateData.business_certificate_file_data ? URL.createObjectURL(cpUpdateData.business_certificate_file_data) : formatImageUri(cpUpdateData.business_certificate_file))}/>
          </label>
        </div>
      </>

      <div className='mt-80'/>
      <Button style={{width: '100%'}} isLoading={isLoading} onClick={onSubmit}>수정하기</Button>
      <div className='mt-100'/>
    
    </Container>
  )
}
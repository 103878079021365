import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import {useSearchParams } from 'react-router-dom';
import { fetchCpList } from '@/api/cp';

const cpListDataState = atom({
  key: 'cpListData',
  default: {
    list: [],
    totalPage: 0,
    totalCount: 0,
  },
});


export const pageState = atom({
  key: 'cpListPageState',
  default: 1,
});

export const countState = atom({
  key: 'cpListCountState',
  default: 10,
});


export const useCpList = () => {
  const [cpList, setCpList] = useRecoilState(cpListDataState);
  const [page, setPage] = useRecoilState(pageState);
  const [count, setCount] = useRecoilState(countState);

  const [isLoading, setIsLoading] = useState(false);
  
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePage = (pageValue) => {
    setPage(pageValue);
    setSearchParams((prev) => {
      prev.set('page', pageValue);
      return prev;
    })
  }
  
  const handleCount = (countValue) => {
    setCount(countValue);
    setSearchParams((prev) => {
      prev.set('count', countValue);
      return prev;
    })
    handlePage(1);
  };

  useEffect(() => {
    const countParam = searchParams.get('count');
    const pageParam = searchParams.get('page');
    const searchParam = searchParams.get('search');
    const typeParam = searchParams.get('type');
    
    if (countParam) {
      setCount(Number(countParam));
    }
    if (pageParam) {
      setPage(Number(pageParam));
    }

    const getCpListData = async () => {
      if (isLoading) return;
      setIsLoading(true);
      try {
        const response = await fetchCpList(page, count, searchParam, typeParam);
        setCpList({
          list: response.list,
          totalPage: response.totalPage,
          totalCount: response.totalCount,
        });
      } catch (error) {
        console.log({error})
        console.error('Failed to fetch CP list:', error);
      } finally {
        setIsLoading(false);
      }
    }


    getCpListData();


  }, [searchParams, setCount, setPage, setCpList])

  return {
    isLoading,
    cpList,
    page,
    count,
    handlePage,
    handleCount,
  }
}
import { fetchGetInquiryList } from '@/api/inquiry';
import { fetchGetNotificationList } from '@/api/notification';
import { Button, Dropdown, Pagination, Table } from '@/components';
import ButtonTitle from '@/components/ButtonTitle/ButtonTitle'
import { formatDateYYMD } from '@/utils/format';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'

const HEADERS = [
  { name: "문의 생성일", minWidth: 170 },
  { name: "문의자", minWidth: 120 },
  { name: "제목", minWidth: 200 },
  { name: "문의 타입", minWidth: 120 },
  { name: "답변 여부", minWidth: 120 },
];

const countOptions = [
  { name: "10개", value: 10 },
  { name: "20개", value: 20 },
  { name: "30개", value: 30 },
];

const Container = styled.div`
  padding: 60px 100px;

`



const TotalCount = styled.div`
  font-size: 25px;
  font-weight: bold;
`;
export default function Page () {

  const [list, setList] = useState([])
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);

  
  async function getData() {
    const { list, total, totalCount } = await fetchGetInquiryList(
      page,
      count,
    );

    setList(list);

    // 전체 카운트
    setTotalCount(totalCount);
  
    // 토탈
    setTotalPage(total);
  }
  
  useEffect(() => {
    getData();
  }, [page, setPage, count])

  const navigate = useNavigate()

  return (
    <Container>
      <ButtonTitle
        title='문의 관리'
        containerSytle={{ marginBottom: 60}}
      />

      
      <TotalCount>전체 {totalCount}개</TotalCount>
      
      <div className="mt-24"/>
      <Table
        id="notifi-list"
        className="mb-50"    
        header={HEADERS}
        datas={list?.map((data) => ({
          id: data.id,
          renders: [
            { render: () => formatDateYYMD(data.created_date) },
            { render: () => data?.user?.name },
            { render: () => data?.title },
            { render: () => data?.inquiry_type },
            { render: () => data?.hasResponsed ? "O" : "X" },
          ],
          onClick: () => {
            navigate(`/etc/inquiry/detail/${data._id}`);
          }
        }))}
      />

      <div className="flex align-center w-full">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={countOptions}
          label={`${count}개`}
          dkey="pageSize"
          setSelect={({ value: selected }) => {
            setCount(selected);
          }}
        />
        
        <div className='flex-1 flex-center'>
          <Pagination
            currentPage={page}
            totalPage={totalPage}
            onClickPage={(page) => setPage(page)}
          />
        </div>
      </div>
    </Container>
  )
}
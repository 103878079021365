import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchkeywordList } from '@/api/keyword';


const keywordListState = atom({
  key: 'keywordList',
  default: []
});


export const useKeywordList = () => {
  const [keywordList, setKeywordList] = useRecoilState(keywordListState);

  useEffect(() => {
    const getKeywordList = async () => {
      try {
        const keywordList = await fetchkeywordList();

        setKeywordList(keywordList);
      } catch (err) {
        console.error('Failed to Fetch Keyword List', err);
      }
    }


    getKeywordList();
  }, [])


  return { 
    keywordList
  }

}

import styled from 'styled-components';

const LineBox = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
border-bottom:2px solid #000;
padding: 30px;
gap: 40px;

&:first-child {
  border-top: 2px solid #000;
}
`

const LineInfo = styled.div`
display:flex;
gap: 12px;
flex-wrap:wrap;
`

const NovelInfoSub = styled.div`
  font-family: Pretendard;
  font-size: 21px;
  letter-spacing: 0.42px;
  text-align: left;
  color: #636363;
  display: flex;
`

const NovelInfoSubBold=styled(NovelInfoSub)`
  font-weight: bold;
  color: #000;
`

function DivideBox({ infoArr, className }) {

  return (
    <div className={className}>
      {
        infoArr.map((info,i) => {
          return <LineBox key={i}>
            {
              info.map(({name, content},index) => {
                return <LineInfo key={index}>
                  <NovelInfoSubBold>{name}</NovelInfoSubBold>
                  <NovelInfoSub>{content}</NovelInfoSub>
                </LineInfo>
              })
            }
          </LineBox>
        })
      }
    </div>
  )
}

export default DivideBox;
import styled from "styled-components";
import { DivideBox, Modal } from "..";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { formatImageUri } from "@/utils/format";

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
`

function CpInfomation({ cpInfo }) {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [isOpen, setIsOpen] = useState(false);


  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <img className="w-full" src={formatImageUri(`cp/${encodeURIComponent(cpInfo.name)}/bank_account_image.png`)}/>
      </Modal>
      <Title>기본정보</Title>
      <DivideBox
        infoArr={[
          [
            { name: "상호명", content: cpInfo.name },
            { name: "사업자번호", content: cpInfo.business_number },
            { name: "대표 이메일", content: cpInfo.email },
            { name: "대표 연락처", content: cpInfo.phone },
            { name: "대표이사", content: cpInfo.ceo_name },
          ],
          [
            { name: "담당자명", content: cpInfo.manager_name },
            { name: "담당자 이메일", content: cpInfo.manager_email },
            { name: "담당자 연락처", content: cpInfo.manager_phone },
          ],
          [
            { name: "정산 은행", content: cpInfo.bank_name },
            { name: "정산 계좌번호", content: cpInfo.bank_number },
            { name: "예금주", content: cpInfo.bank_account_owner },
            { name: "통장사본", content: <div className="underline pointer" onClick={() => setIsOpen(true)}>보기</div> },
          ],
        ]} 
      />
    </div>
  );
}

export default CpInfomation;

import axio from 'axios';

const context = import.meta.env.VITE_APP_BASE_URL;

const axiosInstance = axio.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Content-Type': 'application/json',
  },
  baseURL: context,
});

const axiosAuth = axio.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Content-Type': 'application/json',
  },
  baseURL: context,
})

axiosAuth.interceptors.request.use(config => {
  console.log(config)
  const token = localStorage.getItem('sua_access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {


  console.error(error)
  Promise.reject(error)
})

axiosAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log(error.response.status)
    if(error.response.status === 401) {
      const { email } = JSON.parse(localStorage.getItem('sua_data'))
      const refesh = localStorage.getItem('sua_refresh_token')
      const result = await axio.post(`${context}/apiv/admin/refresh`, { email }, {
        headers: {
          RefreshToken: refesh
        }
      })

      if(result.status === 201 && result.data?.status !== 404) {
        console.log('refresh result', result)
        localStorage.setItem('sua_access_token', result.data.accessToken)
        localStorage.setItem('sua_refresh_token', result.data.refreshToken)

        error.config.headers.Authorization = `Bearer ${result.data.accessToken}`
        return axio.request(error.config)
      }
      else {
        localStorage.removeItem('sua_data')
        localStorage.removeItem('sua_access_token')
        localStorage.removeItem('sua_refresh_token')
        window.location.href='/login'
      }
      
    }
    
    return Promise.reject(error);
  }
)

export default axiosInstance;

export { context, axiosAuth };

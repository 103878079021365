import { fetchEditCoinPromotionData, fetchPromotionDetail, fetchPromotionUnActivate } from '@/api/promotion';
import { Button, Dropdown, LabelInput } from '@/components';
import { formatImageUri } from '@/utils/format';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setHours, setMinutes } from 'date-fns';
import ko from 'date-fns/locale/ko'; // 한국어 locale

import moment from 'moment';
import 'moment-timezone'
import ButtonTitle from '@/components/ButtonTitle/ButtonTitle';
moment.tz.setDefault('Asia/Seoul');

const Container = styled.div`
  padding: 60px 100px;
  padding-bottom: 200px;

  .section_line {
    width: 100%;
    height: 2px;
    background-color: #000000;
  }

  .book_cover {
    width: 155px;
    height: 246px;
  }
`


export default function Page () {
  const { uid } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [detail,  setDetail] = useState(null);


  useEffect(() => {
    getData();
  }, [])

  async function getData () {
    const data = await fetchPromotionDetail(uid, 'coin');

    console.log(data)
    setDetail(data);
    

    handleStartDateChange(data.promotion_start)
    handleEndDateChange(data.promotion_end)

    setEpisodeCount(data?.timedeal_episode_count ?? data?.non_rental_episode_count)
    setTitle(data?.title)
  }

  
  
  const handleStartDateChange = date => {
    // 선택된 날짜의 시간을 00:00으로 설정
    const adjustedDate = moment(date).startOf('day').toDate();
    setStartDate(adjustedDate);
  };

  const handleEndDateChange = date => {
    // moment를 사용하여 선택된 날짜의 시간을 23:59:59으로 설정
    const adjustedDate = moment(date).endOf('day').toDate();
    setEndDate(adjustedDate);
  }

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [title, setTitle] = useState('');
  
  const [episodeCount, setEpisodeCount] = useState(0);


  // 프로모션 수정
  async function submit() {
    if (isLoading) return;
    setIsLoading(true);

    try {
      let fetchData = {
        promotion_id: uid, // 대상 promotion_id
        title, // 적용된 프로모션 제목 [매열무, 타임딜 등]
        promotion_start: startDate, // 프로모션 시작일
        promotion_end: endDate, // 프로모션 종료일
      }

      if(detail?.promotionNumber === 0) fetchData.timedeal_episode_count = episodeCount // 타임딜

      await fetchEditCoinPromotionData({
        ...fetchData
      });
    } catch (error) {
      alert('프로모션 수정에 실패했습니다.')
    } finally {
      setIsLoading(false);
    }
  }



  async function submitActive() {
    const confirm = window.confirm('정말 비활성화 하시겠습니까?');

    if(confirm) {
      await fetchPromotionUnActivate(uid, 'coin');
      alert('프로모션을 비활성화했습니다.')
    }
  }


  
  return (
    <Container>
      <ButtonTitle backButton to="/content/promotion" title='프로모션 상세' />
      <div className='bold font-24'>작품 정보</div>

      <div className='section_line my-24'/>
      
      
      {detail &&
        <div className='mt-80 w-full'>
          <div className='flex align-center'>
            <img className='book_cover' src={formatImageUri(detail?.target_novel?.book_cover)}/>

            <div className='ml-20'>
              <div className='font-24 exbold mb-16'>제목: {detail?.target_novel?.title}</div>
              <div className='font-20 medium mb-8'>장르: {detail?.target_novel?.genre.name}</div>
              <div className='font-20 medium mb-32'>키워드: {detail?.target_novel?.keywords.map(item => item.name)?.join(', ')}</div>

              <div className='font-20 medium mb-8'>작가: {detail?.target_novel?.writer_list?.map(item => item.name)?.join(', ')}</div>
              <div className='font-20 medium'>CP사: {detail?.target_cp?.name}</div>

            </div>
          </div>
        </div>
      }


      <div className='bold font-24 mt-80'>프로모션 정보</div>
      {!detail?.isActive && <div className='bold font-16 c-red'>비활성화 중</div>}

      <div className='section_line my-24'/>

      <div className='' style={{ width: '180px'}}>
        <LabelInput label="프로모션" readOnly value={["타임딜", "스확시간"][detail?.promotionNumber]} background='lightgrey'/>
      </div>

      <div className='mt-32'/>
      <LabelInput value={title} onChange={e => setTitle(e.target.value)} required label={"프로모션 이름 (설명 등)"}/>


            
      <div  style={{width: '200px'}}>
        <div className='bold font-18 mt-32 mb-10'>
          프로모션 시작일 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            dateFormat="yyyy-MM-dd" // 날짜 형식 지정
            locale={ko} // 한국어 locale 사용
          />
        </div>
      </div>



      <div style={{width: '200px'}}>
        <div className='bold font-18 mt-32 mb-10'>
          프로모션 종료일 <span className='c-red'>*</span>
        </div>

        <div className='mt-12' style={{ width: '180px'}}>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            dateFormat="yyyy-MM-dd" // 날짜 형식 지정
            locale="ko" // 한국어 locale 사용
            minDate={startDate} // 시작일을 최소 날짜로 설정
          />
        </div>
      </div>


      <div className='w-full mt-80 flex justify-end'>
        {detail?.isActive && <Button onClick={submitActive} background={'red'}>프로모션 비활성화</Button>}
        <div className='ml-24'/>
        <Button isLoading={isLoading} onClick={submit}>프로모션 수정</Button>
      </div>
    </Container>
  )
}

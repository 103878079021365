import { fetchAllUserCoinList } from '@/api/coin';
import { Button, Dropdown, Input, Pagination, SearchBar, Table } from '@/components';
import ButtonTitle from '@/components/ButtonTitle/ButtonTitle';
import { downloadCSV } from '@/utils/csv';
import { formatDateYMD } from '@/utils/format';
import { useEffect, useState } from 'react';
import styled from 'styled-components'


import moment from 'moment';
import 'moment-timezone'
import { useNavigate } from 'react-router-dom';
moment.tz.setDefault('Asia/Seoul');

const Container = styled.div`
  padding: 60px 100px;

`

const SearchContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
`;

const Line = styled.div`
  width: 24px;
  height: 3px;
  background-color: #000;
`;

const SearchOptionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;


const TotalCount = styled.div`
  font-size: 25px;
  font-weight: bold;
`;


const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 60px;
`;

const HEADERS = [
  { name: "결제일시", minWidth: 200 },
  { name: "유저", minWidth: 180 },
  { name: "품목", minWidth: 180 },
  { name: "결제방법", minWidth: 120 },
  { name: "결제수단", minWidth: 120 },
  { name: "결제 전 스타", minWidth: 120 },
  { name: "결제 후 스타", minWidth: 120 },
  { name: "결제 ip", minWidth: 140 },
  { name: "주문 고유 ID", minWidth: 120 },
  { name: "영수/주문확인 email", minWidth: 200 },
  { name: "환불유무", minWidth: 120 },
];

export default function Page () {
  const [list, setList] = useState([])
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchTitle, setSearchTitle] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [page, setPage, count])

  const handleStartDateChange = date => {
    // 선택된 날짜의 시간을 00:00으로 설정
    const adjustedDate = moment(date).startOf('day').format('YYYYMMDD-HH:mm:ss');
    return adjustedDate
  };



  const handleEndDateChange = date => {
    // moment를 사용하여 선택된 날짜의 시간을 23:59:59으로 설정
    const adjustedDate = moment(date).endOf('day').format('YYYYMMDD-HH:mm:ss');
    return adjustedDate
  }

  async function getData() {
    const st = handleStartDateChange(startDate);
    const ed = handleEndDateChange(endDate);

    const { list, total, totalCount } = await fetchAllUserCoinList(
      page, 
      count,
      st,
      ed,
      searchTitle
    );

    setList(list);

    // 전체 카운트
    setTotalCount(totalCount);
  
    // 토탈
    setTotalPage(total);
  }



  function downloadCSVButton() {
    const headers = HEADERS.map(item => item.name);
    const dataList = list?.map(data => {
      return [
        formatDateYMD(data.created_date),
        data.user?.name,
        data.product_id?.title,
        data.pgType,
        data.paymentType,
        data.previousQuantity,
        data.previousQuantity + data.amount,
        data.user_ip,
        data.paymentKey,
        data.user?.email,
        data.isRefund ? "O" : "X", // 추후 데이터 확인 후 수정 필요
      ]
    })

    downloadCSV(headers, dataList, '결제 관리')
  }

  return (
    <Container>
      <ButtonTitle 
        title='결제 관리'
        containerSytle={{ marginBottom: 60}}
      />

      <SearchContainer>
        <SearchOptionContainer>
          <Input type="date" containerStyle={{ borderRadius: "7px", overflow: "hidden", border: "1px solid #b9b9b9" }} inputStyle={{ height: '36px' }} value={startDate} onChange={e => setStartDate(e.target.value)}/>

          <Line className="ml-10" />

          <Input type="date" containerStyle={{ marginLeft: '10px', borderRadius: "7px", overflow: "hidden", border: "1px solid #b9b9b9" }} inputStyle={{ height: '36px' }} value={endDate} onChange={e => setEndDate(e.target.value)}/>
        
        </SearchOptionContainer>

        <SearchBar
          searchBarContainerStyle={{ width: "600px", height: "46px", margin: '0' }}
          fontSize={15}
          dKey="searchCategory"
          label={"유저 이름"}
          search={searchTitle}
          onChange={(e) => setSearchTitle(e.target.value)}
        />
      </SearchContainer>


      <ButtonContainer>
        <Button width={140} onClick={() => {
          setPage(1);
          getData();
        }}>조회하기</Button>
      </ButtonContainer>


      <TotalCount>전체 {totalCount}개</TotalCount>
      
      <div className="mt-24"/>
      <Table
        id="payment-list"
        className="mb-50"    
        header={HEADERS}
        datas={list?.map((data) => ({
          id: data.id,
          renders: [
            { render: () => formatDateYMD(data.created_date) },
            { render: () => data.user?.name },
            { render: () => data.product?.title },
            { render: () => data.pgType },
            { render: () => data.paymentType },
            { render: () => data.previousQuantity },
            { render: () => data.previousQuantity + data.amount },
            { render: () => data.user_ip },
            { render: () => data.paymentKey },
            { render: () => data.user?.email },
            { render: () => data.isRefund ? "O" : "X" }, // 추후 데이터 확인 후 수정 필요
          ],
          onClick: () => {
            navigate(`/payment/detail/${data._id}`);
          }
        }))}
      />


      <div className="flex align-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]}
          label={`${count}개`}
          dkey="pageSize"
          setSelect={({ value: selected }) => {
            setCount(selected);
          }}
        />

        <Pagination
          currentPage={page}
          totalPage={totalPage}
          onClickPage={(page) => setPage(page)}
        />

        <Button style={{ marginLeft: 'auto' }} width={245} onClick={() => {
          downloadCSVButton();
        }}>CSV 다운로드</Button>
      </div>
    </Container>
  )
}
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ic_header_menu, main_logo } from '@/assets'
import { Fragment, useState } from 'react'
import { getTheme } from '@/styles/theme'
import { useUser } from '@/stores'
import { getUserData } from '@/utils'
import { title } from 'process'


const navConfig = [
  {
    label: '통계',
    navList: [
      {
        title: '작품 판매 현황',
        link: '/statistics/sales-status'
      },
      {
        title: '유저 검색어 통계',
        link: '/statistics/search'
      },
      // {
      //   title: '작품 프로모션 통계',
      //   link: '/statistics/promotion'
      // }
    ],
  },
  {
    label: '결제 / 회원관리',
    navList: [
      {
        title: '결제 관리',
        link: '/payment'
      },
      {
        title: '회원 관리',
        link: '/member'
      },
      {
        title: '스타 정책 관리',
        link: '/product'
      },
      {
        title: '쿠폰 관리',
        link: '/coupon'
      }
    ]
  },
  {
    label: '콘텐츠 관리',
    navList: [
      {
        title: "장르 관리",
        link: '/genre'
      },
      {
        title: "키워드 관리",
        link: '/keywords'
      },
      {
        title: '본사 작품 관리',
        link: '/content/own'
      },
      {
        title: '작품 관리',
        link: '/content/list'
      },
      {
        title: '작품 업로드',
        link: '/content/upload'
      },
    ],
  },
  {
    label: "프로모션 관리",
    navList: [
      {
        title: '재화 프로모션 관리',
        link: '/content/promotion/coin'
      },
      {
        title: '노출 프로모션 관리',
        link: '/content/promotion/exposure'
      },
      {
        title: '오디오북 프로모션 관리',
        link: '/content/promotion/audiobook'
      }
    ]
  },
  {
    label: 'CP사 관리',
    navList: [
      {
        title: 'CP사 목록 / 관리',
        link: '/cp/list'
      },
      {
        title: 'CP사 통계',
        link: '/cp/statistics'
      },
      {
        title: 'CP사 정산',
        link: '/cp/settlement'
      },
      // {
      //   title: '세금계산서',
      //   link: '/cp/tax-invoice'
      // },
      {
        title: '작품 승인관리',
        link: '/cp/approval/novel'
      },
      {
        title: '회차 승인관리',
        link: '/cp/approval/episode'
      }
    ]
  },
  {
    label: '스텔리에 관리',
    navList: [
      {
        title: '작품 목록',
        link: '/stalier/list'
      },
      {
        title: '장르 관리',
        link: '/stalier/genre'
      },
      {
        title: '키워드 관리',
        link: '/stalier/keywords'
      },
    ]
  },
  {
    label: '기타',
    navList: [
      {
        title: '배너 관리',
        link: '/etc/banner'
      },
      // {
      //   title: '댓글 관리',
      //   link: '/etc/comment'
      // },
      {
        title: '공지사항 관리',
        link: '/etc/notice'
      },
      // {
      //   title: '로그 관리',
      //   link: '/etc/log'
      // },
      {
        title: '알람 발송 관리',
        link: '/etc/notification'
      },
      {
        title: '문의 관리',
        link: '/etc/inquiry'
      }
    ]
  }
];

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 320px;
  height: 100%;
  overflow-y: auto;
  z-index: 21;
  box-shadow: 0.6px 9px 16px 0 rgba(0, 0, 0, 0.08);
`

const InnerContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 50px;
  font-size: 18px;
  font-weight: bold;
  min-height: 100%;

  .line {
    width: 100%;
    height: 2px;
    opacity: 0.14;  
    background-color: #ba1c1f;
  }
`

const UserBox = styled(Link)`
  display: block;
  padding: 0 0 20px 0;
`

const NavigateSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px 0;
`

const NavigateBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 27px;
`

const NavLabel = styled.div`
  color: #8c8c8c;
`

const StyledLink = styled(NavLink)`
  color: ${props =>  props.selected ? '#ba1c1f' : '#5b5b5b'};
`

export default function SideBar () {
  const [user, ] = useState(getUserData());
  
  const { userLogout } = useUser();

  
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <Container id="left-sidebar">
      <InnerContainer>
        <Link to ='/'>
          <img style={{width: '100%', marginBottom: '30px'}} src={main_logo}/>
        </Link>

        <UserBox to='/mypage'>
          <div>{user?.name ?? "어드민"}</div>
          <div className='mt-16 mdeium font-16 c-grey100' onClick={userLogout}>로그아웃</div>
        </UserBox>
        
        {
          navConfig.map(({label, navList}, idx) => (
            <Fragment key={idx}>
              <div className='line' />
              <NavigateSection>
                <NavigateBox>
                <NavLabel>{label}</NavLabel>
                  {
                    navList.map((item, idx) => (
                      <StyledLink key={idx} to={item.link} selected={
                        window.location.pathname.startsWith(item.link)
                        }>
                          {item.title}
                      </StyledLink>
                    ))
                  }
                </NavigateBox>
              </NavigateSection>
            </Fragment>
          ))
        }

      </InnerContainer>
    </Container>
  )
}


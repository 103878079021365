import { axiosAuth } from "@/lib/axios";
import { nanoid } from 'nanoid';
import { fetchEpubFileToUri } from "./file";


export const fetchUploadEpisodeList = async ({
  novel,
  novel_uuid,
  files,
  last_episode_index = 0
}) => {
  console.log({files})
  try {
    const version = 1;
    for(let i = 0; i < files.length; i++) {
      const new_uuid = nanoid(24);

      let { data } = await axiosAuth.post(`/episode/admin`, {
        novel,
        novel_uuid,
        uuid: new_uuid, // Episode UUID. ( EPub File Name과 동일하게 갈 예정 )
        title: files[i].title, // 회차 제목
        fileSize: files[i].originFile.size, // Epub 파일 크기
        episodeContentType: 'EPUB', // EPUB, TEXT 등
        content: '', // 내용 ( 텍스트의 경우 )
        episode_index: i + 1 + last_episode_index, // 회차
        isPaid: files[i].isPaid.value, // 유료: true, 무료: false
        episode_price_amount: files[i]?.episode_price_amount ?? 1, // 회차 당 가격
        taxTag: files[i]?.taxTag ? files[i]?.taxTag?.value : "",
        taxCode: files[i]?.taxCode,
        isBook: files[i]?.ll ? true : false
      });
  
      // EPub 파일 S3에 올리는 통신
      await fetchEpubFileToUri(`novel/${novel_uuid}/${i + 1 + last_episode_index}/${version}`, 'episode', files[i].originFile);
    }
  } catch (e) {
    console.error(e);
    throw e;
  }

};




// 회차 수정
export const fetchEditEpisodeList = async ({
  novel,
  novel_uuid,
  datas,
  request_comment
}) => {
  let id_list = [];
  let data_list = [];


  for(let i = 0; i < datas.length; i++) {
    const isNewFile = datas[i]?.new_file ? true : false;
    
    // 신규 파일의 경우
    // 버전 정의
    const version = isNewFile ? Number(datas[i].version) + 1 : datas[i].version;

    // 단건 통신
    let { data } = await axiosAuth.put(`/episode/admin`, {
      novel,
      novel_uuid,
      episode_uuid: datas[i].uuid, // Episode UUID
      title: datas[i].title, // 회차 제목
      fileSize: isNewFile ? datas[i].new_file.size : datas[i].fileSize, // Epub 파일 크기
      episodeContentType: 'EPUB', // EPUB, TEXT 등
      version: version,
      content: datas[i].content, // 내용 ( 텍스트의 경우 )
      episode_index: datas[i].episode_index, // 회차
      isPaid: datas[i].isPaid, // 유료: true, 무료: false
      episode_price_amount: datas[i]?.episode_price_amount ?? 1, // 회차 당 가격
      taxTag: datas[i]?.taxTag ? datas[i]?.taxTag?.value : "",
      taxCode: datas[i]?.taxCode,
    });
    
    // EPub 파일 S3에 올리는 통신
    if(isNewFile) await fetchEpubFileToUri(`novel/${novel_uuid}/${datas[i].episode_index}/${version}`, 'episode', datas[i].new_file);

  }
}






// 회차 판매
export const fetchUpdateEpisodeList = async ({
  episode_list, 
  product_sales_start_list,
  product_sales_end_list
}) => {
  try {
    await axiosAuth.post('/episode/admin/update/sales/status', {
      episode_list, 
      product_sales_start_list,
      product_sales_end_list
    })
  } catch(e) {
    console.error(e);
  }
}





export const fetchAllEpisodeInNovel = async (uid) => {
  const { data } = await axiosAuth.get(`/episode/cp/own/list/${uid}`);
  return data;
}


// 회차(에피소드) 단건 승인
export const fetchApproveEpisode = async (decision_id) => {
  try {
    await axiosAuth.post(`/episodedecision/approve`, { decision_id });
  } catch(e) {
    console.error(e);
  }
}

// 회차 단건 반려
export const fetchRejectEpisode = async (uid) => {
  try {
    await axiosAuth.post(`/episodedecision/reject`, {
      decision_id: uid
    });
  } catch(e) {
    console.error(e);
  }
}

// 회차 단건 비활성화
export const fetchEpisodeUnActivate = async (uid) => {
  try {
    await axiosAuth.post(`/episode/admin/update/unactive/${uid}`);
  } catch(e) {
    console.error(e);
  }
}
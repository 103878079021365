import { useNovelData } from '@/stores/novelData';
import { useParams } from 'react-router-dom';
import styled from 'styled-components'
import { Button, LabelInput, Modal, Textarea } from '..';
import { useState } from 'react';
import { axiosAuth } from '@/lib/axios';
import { fetchAudioBookFileToUri } from '@/api/file';


const Container = styled.div`

`


const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const UploadLabel = styled.label`
  border: solid 1px #ba1c1f;
  border-radius: 7px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  cursor: pointer;
  align-items: center;
  gap: 35px;

  .text {
    font-size: 44px;
    font-weight: bold;
  }
`;

export default function AudioBookUploadModal ({
  isOpen,
  setIsOpen,

}) {

  const { uid } = useParams();
  const { novelData } = useNovelData(uid);

  const [file, setFile] = useState(null);
  const [content, setContent] = useState('');
  const [episode_index, set_episode_index] = useState();


  async function submit() {
    try {
      await fetchAudioBookFileToUri(`audiobook/${novelData.uuid}/${episode_index}`, 'audiobook', file)
  
      await axiosAuth.post('/episode/admin/update/audiobook', {
        novel_id: novelData._id,
        episode_index,// 에피소드 _id
        audioFile: `audiobook/${novelData.uuid}/${episode_index}/audiobook.mp3`, // 오디오 파일
        content_text: content // 내용
      });
      
      alert('등록 완료');
      setIsOpen(false);
    } catch (e) {
      console.log(e)
    }
  }


  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} style={{
      width: '800px',
      maxWidth: '88vw',
      height: '800px',
      maxHeight: '96vh'
    }}>
      <Container>
        <ModalTitle>
          {novelData?.title} - 오디오북 삽입
        </ModalTitle>
        
        <UploadLabel>
          <svg
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="35" cy="35" r="32" stroke="#ba1c1f" strokeWidth="6" />
            <path
              d="M35 20V50M20 35H50"
              stroke="#ba1c1f"
              strokeWidth="6"
            />
          </svg>
          <input
            type="file" 
            accept=".mp3"
            style={{display: 'none'}}
            onChange={e => {
              console.log(e.target.files[0]);
              setFile(e.target.files[0]);
            }}
          />

          <div className="text font-24">
            오디오 파일을 업로드 해주세요
          </div>
        </UploadLabel>

        <div className='mt-24'/>

        <LabelInput readOnly label={"입력된 파일"} value={file?.name} onChange={e => set_episode_index(e.target.value)}/>

        <div className='mt-24'/>

        <LabelInput label={"회차"} placeholder={1} value={episode_index} onChange={e => set_episode_index(e.target.value)}/>
        
        
        <>
          <div className='bold font-18 mt-32 mb-10'>
            내용 <span className='c-red'>*</span>
          </div>

          <div className='w-full'>
            <Textarea value={content} onChange={e => setContent(e.target.value)}/>
          </div>
        </>
        
        <div className='flex justify-end mt-24'>
          <Button onClick={submit}>등록</Button>
        </div>
      </Container>
    </Modal>
  )
}
import { fetchBannerDetail, fetchNewBannerData, fetchUpdateBannerData } from "@/api/banner";
import { fetchFileToUri } from "@/api/file";
import { book_cover_sample } from "@/assets";
import { Button, Dropdown, Input, LabelInput } from "@/components";
import { formatImageUri } from "@/utils/format";
import { setHours, setMinutes } from "date-fns";
import { ko } from "date-fns/locale/ko";
import { nanoid } from 'nanoid';
import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import moment from 'moment';
import 'moment-timezone'
moment.tz.setDefault('Asia/Seoul');

registerLocale('ko', ko);

const Container = styled.div`
  padding: 60px 100px;


  .section_line {
    width: 100%;
    height: 2px;
    background-color: #000000;
  }

  .subTitle {
    font-size: 14px;
    display: inline;
  }

  .imageUpload {
    width: 155px;
    height: 246px;
    background-color: #fff;
    border-radius: 10px;
    
    .book_cover {
      width: 100%;
      height: 100%;
    }
  }
`

const postPlaceList = [
  { value: 'main', name: '메인 화면 배너' },
  { value: 'odi_pay', name: '스타 결제 화면 배너' },
  { value: 'sub', name: '소배너' },
];

const bannerTypeList = [
  { value: 'inner', name: '내부' },
  { value: 'outer', name: '외부' },
];

const initBannerCreateData = {
  title: '', // 배너명
  post_place: postPlaceList[0], // 배너 게시 위치
  bannerType: bannerTypeList[0], // 배너 타입
  post_start_date: null, // 게시 시작일
  post_end_date: null, // 게시 종료일
  link: '', // 연결 페이지
  outLink: '', // 연결 url
  banner_image_file: null, // 배너 이미지 파일
};

  

function Register () {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const bannerId = searchParams.get('id');
  const navigate = useNavigate();
  const [bannerCreateData, setBannerCreateData] = useState(initBannerCreateData);

  const handleChange = (key, value) => {
    setBannerCreateData((prev) => ({ ...prev, [key]: value }));
  }

    
  const handleStartDateChange = date => {
    // 선택된 날짜의 시간을 00:00으로 설정
    const adjustedDate = moment(date).startOf('day').toDate();

    return adjustedDate;
  };

  const handleEndDateChange = date => {
    // moment를 사용하여 선택된 날짜의 시간을 23:59:59으로 설정
    const adjustedDate = moment(date).endOf('day').toDate();
    return adjustedDate;
  }

  const create = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      // validation check
      if(bannerCreateData.title === '') throw new Error('배너명을 입력해주세요.');
      if(bannerCreateData.banner_image_file === null) throw new Error('배너 이미지를 입력해주세요.');
      if(bannerCreateData.post_place === null) throw new Error('배너 위치를 선택해주세요.');
      if(bannerCreateData.bannerType === null) throw new Error('배너 타입을 선택해주세요.');
      if(bannerCreateData.post_start_date === '') throw new Error('배너 게시 시작일을 입력해주세요.');
      if(bannerCreateData.post_end_date === '') throw new Error('배너 게시 종료일을 입력해주세요.');
      if(bannerCreateData.link === '' && bannerCreateData.outLink === '') throw new Error('배너 하이퍼링크를 입력해주세요.');
      if(bannerCreateData.link !== '' && bannerCreateData.outLink !== '') throw new Error('배너 하이퍼링크는 하나만 입력해주세요.');

      const uuid = nanoid(40);

      try {
        await fetchFileToUri(`banner/${uuid}`, 'banner_image', bannerCreateData.banner_image_file);
      } catch {
        throw new Error('배너 이미지 업로드에 실패했습니다.');
      }

      const newBanner = {};
      newBanner.title = bannerCreateData.title;
      newBanner.link = bannerCreateData.link;
      newBanner.outLink = bannerCreateData.outLink;
      newBanner.post_start_date = bannerCreateData.post_start_date;
      newBanner.post_end_date = bannerCreateData.post_end_date;
      newBanner.post_place = bannerCreateData.post_place.value;
      newBanner.bannerType = bannerCreateData.bannerType.value;
      newBanner.post_image_uri = `banner/${uuid}/banner_image.png`;
      newBanner.content= '';
      newBanner.isLink= false;

      console.log({newBanner});

      try {
        await fetchNewBannerData(newBanner);
        navigate('/etc/banner');
      } catch {
        throw new Error('배너 등록에 실패했습니다.');
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  const update = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      if(!validate()) return;

      if (bannerCreateData.banner_image_file) {
        const uuid = nanoid(40);

        try {
          await fetchFileToUri(`banner/${uuid}`, 'banner_image', bannerCreateData.banner_image_file);
        } catch {
          throw new Error('배너 이미지 업로드에 실패했습니다.');
        }
        bannerCreateData.post_image_uri = `banner/${uuid}/banner_image.png`;
      } 
      
      const updateBanner = {
        banner_id: bannerId,
        title: bannerCreateData.title,
        post_place: bannerCreateData.post_place.value,
        bannerType: bannerCreateData.bannerType.value,
        post_start_date: bannerCreateData.post_start_date,
        post_end_date: bannerCreateData.post_end_date,
        link: bannerCreateData.link,
        outLink: bannerCreateData.outLink,
        post_image_uri: bannerCreateData.post_image_uri,
        content: '',
        isLink: false,
      };

      try {
        await fetchUpdateBannerData(updateBanner);
        navigate('/etc/banner');
      }
      catch (error) {
        throw new Error('배너 수정에 실패했습니다.');
      }
    } catch (error) {
      alert(error.message);
    }
    finally {
      setIsLoading(false);
    }
  }

  const validate = () => {
    if(bannerCreateData.title === '') {
      alert('배너명을 입력해주세요.');
      return false;
    }
    if(bannerCreateData.banner_image_file === null && bannerCreateData.post_image_uri === '') {
      alert('배너 이미지를 입력해주세요.');
      return false;
    }
    if(bannerCreateData.post_place === null) {
      alert('배너 위치를 선택해주세요.');
      return false;
    }
    if(bannerCreateData.bannerType === null) {
      alert('배너 타입을 선택해주세요.');
      return false;
    }
    if(bannerCreateData.post_start_date === '') {
      alert('배너 게시 시작일을 입력해주세요.');
      return false;
    }
    if(bannerCreateData.post_end_date === '') {
      alert('배너 게시 종료일을 입력해주세요.');
      return false;
    }
    if(bannerCreateData.link === '' && bannerCreateData.outLink === '') {
      alert('배너 하이퍼링크를 입력해주세요.');
      return false;
    }
    if(!bannerCreateData.link && !bannerCreateData.outLink) {
      alert('배너 하이퍼링크는 하나만 입력해주세요.');
      return false;
    }

    return true;
  }

  useEffect(() => {
    if (!bannerId) return;

    (async () => {
      try{
        const response = await fetchBannerDetail(bannerId);
        const { title, post_place, bannerType, post_start_date, post_end_date, link, outLink, post_image_uri } = response;
        setBannerCreateData({
          title,
        post_place: postPlaceList.find((item) => item.value === post_place),
        bannerType: bannerTypeList.find((item) => item.value === bannerType),
        post_start_date: new Date(post_start_date),
        post_end_date: new Date(post_end_date),
        link,
        outLink,
        banner_image_file: null,
        post_image_uri,
        });
      } catch (error) {
        alert('배너 정보를 불러오는데 실패했습니다.');
        navigate('/etc/banner');
      }
    })();
  }, [bannerId]);


  return (
    <Container className='w-full'>
      <div className='bold font-30 mb-88'>배너 {bannerId ? '수정' : '등록'}</div>
      <div className='bold font-24'>기본 정보</div>

      <div className='section_line my-24'/>

      <LabelInput value={bannerCreateData.title} onChange={e => handleChange('title', e.target.value)} label={"배너명"} required/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          배너 위치 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <Dropdown options={postPlaceList} select={bannerCreateData.post_place} setSelect={item => handleChange('post_place', item)}/>
        </div>
      </>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          배너 타입 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <Dropdown options={bannerTypeList} select={bannerCreateData.bannerType} setSelect={item => handleChange('bannerType', item)}/>
        </div>
      </>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          배너 게시일 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <DatePicker
            selected={bannerCreateData.post_start_date}
            onChange={date => handleChange('post_start_date', handleStartDateChange(date))}
            dateFormat="yyyy-MM-dd" // 날짜 형식 지정
            locale="ko" // 한국어 locale 사용
          />
        </div>
      </>

      <div style={{width: '200px'}}>
        <div className='bold font-18 mt-32 mb-10'>
          배너 게시 종료일<span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <DatePicker
            selected={bannerCreateData.post_end_date}
            onChange={date => handleChange('post_end_date', handleEndDateChange(date))}
            dateFormat="yyyy-MM-dd" // 날짜 형식 지정
            locale="ko" // 한국어 locale 사용
            minDate={bannerCreateData.post_start_date} // 시작일을 최소 날짜로 설정
          />
        </div>
      </div>
      



      <div className='bold font-24 mt-68'>배너 하이퍼링크 <div className="subTitle">(세개중 하나만 선택해 입력해주세요.)</div></div>
      <div className='section_line my-24'/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          연결 페이지 (연결할 내부 페이지의 url을 입력해주세요 )
        </div>
        <div className='flex align-center w-full'>
          <div className='' style={{ width: '590px'}}>
            <Input
              value={bannerCreateData.link}
              onChange={e => {
                handleChange('link', e.target.value);
                handleChange('outLink', '');
              }}
            />
          </div>
        </div>
      </>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          연결 url (외부 광고의 경우 당 url을 입력해주세요.)
        </div>
        <div className='flex align-center w-full'>
          <div className='' style={{ width: '590px'}}>
            <Input
              value={bannerCreateData.outLink}
              onChange={e => {
                handleChange('outLink', e.target.value);
                handleChange('link', '');
              }}
            />
          </div>
        </div>
      </>

      <div className='bold font-24 mt-68'>배너 이미지 설정</div>
      <div className='section_line my-24'/>

      <>
        <div className='bold font-18 mt-32 mb-24'>
          배너 (메인페이지 5:3, 소배너 3:1, 스타배너 5:3 기준에 맞춰주세요) <span className='c-red'>*</span>
        </div>

        <div className=''>
          <input type="file" accept="image/*" id="imageUpload" style={{ display: "none" }} onChange={(e) => handleChange('banner_image_file', e.target.files[0])}/>
          
          <label htmlFor='imageUpload' className='imageUpload flex-center flex-col w-full'>
            <img className='book_cover  pointer' src={(bannerCreateData.banner_image_file ? URL.createObjectURL(bannerCreateData.banner_image_file) : (bannerCreateData.post_image_uri ? formatImageUri(bannerCreateData.post_image_uri): null)) || book_cover_sample}/>
          </label>
        </div>
      </>
      


      <div className='mt-80'/>
      <Button style={{width: '100%'}} isLoading={isLoading} onClick={bannerId ? update : create}>배너 {bannerId ? '수정' : '등록'}</Button>
    </Container>
  );
}

export default Register;
import { axiosAuth } from "@/lib/axios";



export const fetchGetInquiryList = async (
  page,
  count,
) => {
  const { data } = await axiosAuth.get(`/inquiry/admin/list/${page}/${count}`);
  
  return data;
}



export const fetchGetInquiryDetail = async (uid) => {
  const { data } = await axiosAuth.get(`/inquiry/admin/detail/${uid}`);
  
  return data;
}



export const fetchPutInquiryResponse = async (uid, response_content) => {
  try {
    const { data } = await axiosAuth.post(`/inquiry/admin/response`, {
      inquiry_id: uid,
      response_content
    });

    alert('문의 답변이 저장되었습니다.')
    window.location.reload();
    
    return data;
  } catch (e) {
    alert('오류가 발생했습니다.')
    return e;
  }
}

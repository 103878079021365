import { loading } from '@/assets';
import { useEffect } from 'react';
import styled from 'styled-components'


const Container = styled.div`
  position: fixed;

  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  
  z-index: 9999992;

  background-color: rgba(0, 0, 0, 0.8);
`


export default function Loading (isLoading) {
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isLoading]);

  return (
    <Container className='flex-center flex-col'>
      <img src={loading}/>
      <div className='font-36 c-white bold mt-36'>처리중입니다...</div>
    </Container>
  )
}
import { axiosAuth } from "@/lib/axios";

// 재화 결제 리스트
export const fetchuserCoinList = async (page, count, uid) => {
  const { data } = await axiosAuth.get(`/coin/admin/user/buy/list/${page}/${count}/${uid}`);
  return data;
}

// 재화 사용 리스트
export const fetchuserUseCoinList = async (page, count, uid) => {
  const { data } = await axiosAuth.get(`/coin/admin/user/use/list/${page}/${count}/${uid}`);
  return data;
}

// 재화 결제 리스트
export const fetchAllUserCoinList = async (page, count, startDate, endDate, searchTitle) => {
  const { data } = await axiosAuth.get(`/coin/admin/user/buy/listall/${page}/${count}/${startDate}/${endDate}?searchTitle=${searchTitle}`);
  return data;
}


// 재화 상세
export const fetchCoinDetail = async (coin_id) => {
  const { data } = await axiosAuth.get(`/coin/admin/detail/${coin_id}`);
  return data;
}

// 재화 환불처리
export const fetchRefundOne = async (coin_id) => {
  const { data } = await axiosAuth.post(`/coin/admin/refund/${coin_id}`);
  return data;
}

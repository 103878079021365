import styled from "styled-components";
import { Button, Input, Modal, Table } from "..";
import {  useState } from "react";
import { formatBits } from "@/utils/format";

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setHours, setMinutes } from 'date-fns';
import ko from 'date-fns/locale/ko'; // 한국어 locale
import { fetchUpdateEpisodeList } from "@/api/episode";

import moment from 'moment';
import 'moment-timezone'
moment.tz.setDefault('Asia/Seoul');


const ModalTitle = styled.div`
  font-size: 44px;
  font-weight: bold;
  margin-bottom: 80px;
`;


const TableContainer = styled.div`
  .table_header_box {
    height: 60px;
    border-bottom: 4px solid #000;
  }

  .table_body_item {
    height: 64px;
    border-bottom: 2px solid #bbbbbb;
  }
`


function useStartSaleModal() {
  const [isOpen, setIsOpen] = useState(false);
  
  const handleStartDateChange = date => {
    // 선택된 날짜의 시간을 00:00으로 설정
    const adjustedDate = moment(date).startOf('day').toDate();

    return adjustedDate;
  };
 
  const handleEndDateChange = date => {
    // moment를 사용하여 선택된 날짜의 시간을 23:59:59으로 설정
    const adjustedDate = moment(date).endOf('day').toDate();
    return adjustedDate;
  }


  return [
    ({ initDatas, selected, episodeList }) => {
      const [datas, setDatas] = useState(episodeList);
      const [isLoading, setIsLoading] = useState(false);

      return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} style={{
          width: '1600px',
          maxWidth: '96vw',
          height: '800px',
          maxHeight: '96vh'
        }}>
          <ModalTitle>
            {/* [TODO] 변경 및 적용 */}
            미래 기술로 무림정벌 - 판매 설정
          </ModalTitle>

          <TableContainer className="w-full">
            <div className="w-full flex table_header_box align-center">
              <div className="font-20 bold" style={{ minWidth: '140px'}}>용량</div>
              <div className="font-20 bold flex-1" style={{ minWidth: '200px'}}>제목</div>
              <div className="font-20 bold" style={{ minWidth: '250px'}}>판매 시작 일시</div>
              <div className="font-20 bold" style={{ minWidth: '400px'}}>판매 중단 일시<span className="font-14" >(판매중단 계획이 없으면 빈칸으로 둬주세요)</span></div>
              <div className="font-20 bold" style={{ minWidth: '30px'}}></div>
            </div>

            <div className="table_body_box w-full">
              {datas.map((item, index) => (
                <div className="table_body_item w-full flex align-center" key={`dfiasdasdle-i-${index}`}>
                  <div className="font-20 bold" style={{ minWidth: '140px'}}>{formatBits(item.fileSize)}</div>
                  <div className="font-20 bold flex-1" style={{ minWidth: '200px'}}>{item.title}</div>
                  <div className="font-20 bold" style={{ minWidth: '250px'}}>
                    <DatePicker
                      selected={item?.product_sales_start ?? new Date()}
                      onChange={date => {
                        const list = datas.map((dataItem, idx) => {
                          if (idx === index) {
                            return { ...dataItem, product_sales_start: handleStartDateChange(date) };
                          }
                          return dataItem;
                        });
                        setDatas(list);
                      }}
                      dateFormat="yyyy-MM-dd-HH:mm" // 날짜 형식 지정
                      locale={ko} // 한국어 locale 사용
                      showTimeSelect // 시간 선택 기능 활성화
                      timeFormat="HH:mm" // 시간 형식 지정
                      timeIntervals={60} // 시간 간격 (15분)
                      timeCaption="시간" // 시간 선택기의 제목
                    />

                  </div>
                  <div className="font-20 bold" style={{ minWidth: '400px'}}>
                    <DatePicker
                      selected={item?.product_sales_end}
                      onChange={date => {
                        const list = datas.map((dataItem, idx) => {
                          if (idx === index) {
                            return { ...dataItem, product_sales_end: handleEndDateChange(date) };
                          }
                          return dataItem;
                        });
                        setDatas(list);
                      }}
                      minDate={item?.product_sales_start ?? new Date()}
                      dateFormat="yyyy-MM-dd-HH:mm" // 날짜 형식 지정
                      locale={ko} // 한국어 locale 사용
                      showTimeSelect // 시간 선택 기능 활성화
                      timeFormat="HH:mm" // 시간 형식 지정
                      timeIntervals={60} // 시간 간격 (15분)
                      timeCaption="시간" // 시간 선택기의 제목
                    />
                  </div>
                  <div className="font-20 bold" style={{ minWidth: '30px'}}></div>
                </div>
              ))}
            </div>
          </TableContainer>



          <div className="flex justify-end my-24">
            <Button width={140} isLoading={isLoading} onClick={async () => {
              if (isLoading) return;
              setIsLoading(true);
              try {
                // 판매 시작 통신
                const episode_list = datas.map(item => item._id);
                const product_sales_start_list = datas.map(item => item.product_sales_start ?? new Date());
                const product_sales_end_list = datas.map(item => item.product_sales_end ?? null);
                
                await fetchUpdateEpisodeList({ 
                  episode_list, 
                  product_sales_start_list,
                  product_sales_end_list
                })

                alert('판매설정을 변경했습니다.');
                setIsOpen(false);
              } catch (error) {
                alert('판매설정을 변경하는데 실패했습니다.');
              } finally {
                setIsLoading(false);
              }
            }}>
              판매 시작
            </Button>
          </div>
        </Modal>
      )
    },
    () => setIsOpen(true),
  ];
};



export default useStartSaleModal;

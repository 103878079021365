import { axiosAuth } from "@/lib/axios";

// 심사 리스트 가져오기
export const fetchNovelDecisionList = async (page, count, search, type, approve) => {
  const query = [];
  if (search) { query.push(`search=${search}`) };
  if (type) { query.push(`type=${type}`) };
  if (approve && approve !== "-1") { query.push(`approve=${approve}`) };
  const queryString = query.join('&');

  const { data } = await axiosAuth.get(`/noveldecision/admin/list/${page}/${count}?${queryString}`);
  return data;
}

// 심사 상세 가져오기
export const getDecisionByNovelId = async (novel_id) => {
  const { data } = await axiosAuth.get(`/noveldecision/admin/${novel_id}`);
  return data;
}

// 심사 - 세트 - 리스트
export const fetchEpisodeDecisionList = async (page, count, search, type, approve) => {
  const query = [];
  if (search) { query.push(`search=${search}`) };
  if (type) { query.push(`type=${type}`) };
  if (approve && approve !== "-1") { query.push(`approve=${approve}`) };
  const queryString = query.join('&');
  const { data } = await axiosAuth.get(`/episodedecision/admin/decision/list/${page}/${count}?${queryString}`);
  return data;
}

// 심사 - 세트 - 상세
export const getDecisionByDecisionId = async (decision_id) => {
  const { data } = await axiosAuth.get(`/episodedecision/admin/decision/${decision_id}`);
  return data;
}

export const fetchNovelDecisionApproveOne = async (decision_id) => {
  try {
    await axiosAuth.post('/noveldecision/approve', { decision_id });
  } catch {
    return console.log('심사 등록실패')
  }
}

export const fetchNovelDecisionRejectOne = async (decision_id) => {
  try {
    await axiosAuth.post('/noveldecision/reject', { decision_id });
  } catch {
    return console.log('심사 등록실패')
  }
}



export const fetchEpisodeDecisionApproveOne = async (decision_id) => {
  try {
    await axiosAuth.post('/episodedecision/approve/list', { decision_id });
  } catch {
    return console.log('심사 등록실패')
  }
}

export const fetchEpisodeDecisionRejectOne = async (decision_id) => {
  try {
    await axiosAuth.post('/episodedecision/reject/list', { decision_id });
  } catch {
    return console.log('심사 등록실패')
  }
}

export const getDecisionByEpisodeId = async (episode_id) => {
  const { data } = await axiosAuth.get(`/episodedecision/admin/episode/${episode_id}`);
  return data;
}





export const fetchNovelDecisionListAll = async (novel_id) => {
  const { data } = await axiosAuth.get(`/noveldecision/admin/novel/${novel_id}`);
  return data;
}



export const fetchNovelEditDecisionListAll = async (novel_id) => {
  const { data } = await axiosAuth.get(`/noveldecision/admin/novel/edit/${novel_id}`);
  return data;
}


// CP - 소설  승인이력
export const fetchCPNovelDecisionListAll = async (cp_id, page, count=10) => {
  const { data } = await axiosAuth.get(`/noveldecision/admin/cplist/${cp_id}/${page}/${count}`);
  return data;
}

// CP - 회차 승인이력
export const fetchCPEpisodeDecisionListAll = async (cp_id, page, count=10) => {
  const { data } = await axiosAuth.get(`/episodedecision/admin/cplist/${cp_id}/${page}/${count}`);
  return data;
}



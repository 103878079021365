import { fetchOwnLabelList } from '@/api/label'
import { Button, LabelInput } from '@/components'
import LabelAddModal from '@/components/Label/LabelAddModal'
import { getUserData } from '@/utils'
import { useEffect, useState } from 'react'
import styled from 'styled-components'


const Container = styled.div`
  padding: 60px 100px;

  .section_line {
    width: 100%;
    height: 2px;
    background-color: #000000;
  }

  .labelItem {
    display: inline-block;
  }
`


export default function MyPage () {
  const [isOpen, setIsOpen] = useState(false);
  const [user, ] = useState(getUserData());
  const [list, setList] = useState([]);

  
  useEffect(() => {
    getData();
  }, []);


  async function getData() {
    const LabelList = await fetchOwnLabelList();
    setList(LabelList.map(item => item.name));
  }


  return (
    <Container>
      <LabelAddModal isOpen={isOpen} setIsOpen={setIsOpen}/>

      <div className='font-30 bold mb-88'>어드민 프로필 편집</div>

      <div className='bold font-24'>기본 정보</div>
      <div className='section_line my-24'/>

      <LabelInput value={user.name}  label={"계정명"} required readOnly/>

      
      <div className='flex align-center w-full mt-52'>
        <div className='bold font-24 mr-32'>레이블 정보</div>
        <Button width={120} height={32} onClick={() => setIsOpen(true)}>추가</Button>
      </div>
      <div className='section_line my-24'/>

      <div className='bold font-18 mb-16'>레이블</div>
      <div className='labelList'>
        {list.map((item, idx) => (
          <div className='labelItem medium mr-8' key={`label-index-${item}-${idx}`}>{item}</div>
        ))}
      </div>
    </Container>
  )
}
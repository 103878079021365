import { Button, Dropdown, Input, LabelInput } from "@/components";
import { useEffect, useMemo, useState } from "react";
import { useQuill } from "react-quilljs";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import 'quill/dist/quill.snow.css';
import { fetchNewNoticeData, fetchNoticeDetail, fetchUpdateNoticeData } from "@/api/notice";


const Container = styled.div`
  padding: 60px 100px;


  .section_line {
    width: 100%;
    height: 2px;
    background-color: #000000;
  }

  .subTitle {
    font-size: 14px;
    display: inline;
  }

  .imageUpload {
    width: 155px;
    height: 246px;
    background-color: #fff;
    border-radius: 10px;
    
    .book_cover {
      width: 100%;
      height: 100%;
    }
  }
`

const EditorContainer = styled.div`
  width: 100%;
  height: 500px;

  .ql-editor {
    background-color: #fff;
  }
`;

const noticeTypeList = [
  { value: 'USER', name: '유저' },
  { value: 'CP', name: 'CP사' },
  { value: 'ALL', name: '전체' },
];

const initNoticeCreateData = {
  title: '', // 공지사항 제목
  noticeType: noticeTypeList[0], // 공지사항 대상
};



function Register() {
  const [searchParams] = useSearchParams();
  const noticeId = searchParams.get('id');

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [noticeCreateData, setNoticeCreateData] = useState(initNoticeCreateData);
  const { quill, quillRef, editor, Quill, editorRef } = useQuill({ 
    placeholder: '내용을 입력해주세요.', 
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: [] }],
    
        [{ list: 'ordered'}, { list: 'bullet' }],
        [{ indent: '-1'}, { indent: '+1' }],
    
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
    
        ['clean'],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
    formats: [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'indent',
      'link', 'image', 'color', 'background',
      'align', 'size',
    ],
  });

  const handleChange = (key, value) => {
    setNoticeCreateData((prev) => ({ ...prev, [key]: value }));
  }

  const create = async () => {
    if(isLoading) return;
    setIsLoading(true);

    try {
      // validation check
      if(noticeCreateData.title === '') throw new Error('공지사항 제목을 입력해주세요.');
      if(noticeCreateData.noticeType === '') throw new Error('대상을 선택해주세요.');
      
      const newNotice = {};
      newNotice.title = noticeCreateData.title;
      newNotice.noticeType = noticeCreateData.noticeType.value;
      newNotice.content = quill.root.innerHTML;

      try {
        await fetchNewNoticeData(newNotice);
        navigate('/etc/notice');
      } catch {
        throw new Error('공지사항 등록에 실패했습니다.');
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  const update = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      if(noticeCreateData.title === '') throw new Error('공지사항 제목을 입력해주세요.');
      if(noticeCreateData.noticeType === '') throw new Error('대상을 선택해주세요.');
      
      const newNotice = {};
      newNotice.notice_id = noticeId;
      newNotice.title = noticeCreateData.title;
      newNotice.noticeType = noticeCreateData.noticeType.value;
      newNotice.content = quill.root.innerHTML;

      try {
        await fetchUpdateNoticeData(newNotice);
        navigate('/etc/notice');
      } catch {
        throw new Error('공지사항 수정에 실패했습니다.');
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if(!noticeId || !quill) return;

    (async () => {
      try{
        const response = await fetchNoticeDetail(noticeId);
        setNoticeCreateData({
          title: response.title,
          noticeType: noticeTypeList.find(item => item.value === response.noticeType),
        });

        quill.root.innerHTML = response.content;

      } catch (error) {
        alert('공지사항 정보를 불러오는데 실패했습니다.');
        navigate('/etc/notice');
      }
    })();
  }, [noticeId, quill]);

  return (
    <Container className='w-full'>
      <div className='bold font-30 mb-88'>공지사항 {noticeId ? '수정' : '등록'}</div>
      <div className='bold font-24'>기본 정보</div>

      <div className='section_line my-24'/>

      <LabelInput value={noticeCreateData.title} onChange={e => handleChange('title', e.target.value)} label={"제목"} required/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          대상 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <Dropdown options={noticeTypeList} select={noticeCreateData.noticeType} setSelect={item => handleChange('noticeType', item)}/>
        </div>
      </>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          내용 <span className='c-red'>*</span>
        </div>

        <EditorContainer>
          <div ref={quillRef} />
        </EditorContainer>
      </>

      <div className='mt-80'/>
      <Button style={{width: '100%'}} isLoading={isLoading} onClick={noticeId ? update : create}>공지사항 {noticeId ? '수정' : '등록'}</Button>
    </Container>
  );
}

export default Register;
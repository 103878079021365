import styled from 'styled-components';
import Dropdown from '../Dropdown/Dropdown';
import Input from '../Inputs/Input';

const SearchBarContainer = styled.div`
  height: 60px;
  display: flex;
  margin-bottom: 20px;
  background-color: #fff;
  max-width: 880px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #b9b9b9;

  .searchLine {
    width: 1px;
    height: 100%;
    background-color: #b9b9b9;
    z-index: 1;
  }
`;

const DEFAULT_DROPDOWN_STYLES = { height: '100%', backgroundColor: 'transparent', border: 'none', padding: '20px 12px 20px 20px' };
const DEFAULT_INPUT_CONTAINER_STYLES = { border: 'none', width: 'inherit', flex: 1, borderRadius: '10px' };
const DEFAULT_INPUT_STYLES = { outline: 'none', height: '100%', borderRadius: '10px' };

function SearchBar({ searchBarContainerStyle, label, dropdownContainerStyle, options, dKey, select, setSelect, fontSize = 20, inputContainerStyle, inputStyle, search, onChange }) {
  return (
    <SearchBarContainer style={searchBarContainerStyle} >
      <Dropdown
        containerStyle={{...DEFAULT_DROPDOWN_STYLES, ...dropdownContainerStyle}}
        font={fontSize}
        options={options}
        label={label}
        dkey={dKey}
        setSelect={setSelect}
        select={select}
      />
      <div className="searchLine" />
      <Input
        font={fontSize}
        containerStyle={{...DEFAULT_INPUT_CONTAINER_STYLES, ...inputContainerStyle}}
        style={{...DEFAULT_INPUT_STYLES, ...inputStyle}}
        value={search}
        onChange={onChange}
      />
    </SearchBarContainer>
  );
}

export default SearchBar;

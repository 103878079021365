import { fetchAddKeywordData, fetchUpdateKeywordData, fetchUpdateStalierKeywordData } from '@/api/keyword';
import { Button, CheckBox, LabelInput, Modal } from '@/components'
import { axiosAuth } from '@/lib/axios';
import { useGenreList } from '@/stores/genre';
import { useStalierGenreList } from '@/stores/stalierGenre';
import { useState } from 'react'
import styled from 'styled-components'


const Container = styled.div`
  .c-828282 {
    color: #828282;
    line-height: 20px;
  }
`


export default function StalierKeywordUpdateModal ({
  keyword,
  isOpen,
  setIsOpen
}) {
  const { stalierGenreList } = useStalierGenreList();
  const [isLoading, setIsLoading] = useState(false);
  const [keywordName, setKeywordName] = useState(keyword?.name);
  
  async function submit() {
    if (isLoading) return;
    setIsLoading(true);
    try {
      // Fetch Create Keyword
      const result = await fetchUpdateStalierKeywordData(keyword._id, keywordName, genre, keywordType);
      
      // 장르 생성 성공
      if(result) {
        alert('키워드가 수정되었습니다.');
        setKeywordName('');
        setIsOpen(false);
      } else {}
    } catch(e) {
      console.error(e.message);
      alert('키워드 수정에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  }
  
  const [keywordType, setKeywordType] = useState(keyword?.keywordType ?? []);
  const [genre, setGenre] = useState(keyword?.genre?.map(item => item._id) ?? []);

  return (
    <Container>   
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className='bold font-30 mb-40'>스텔리에 키워드 수정</div>
        
        <div className='font-18 mb-10 bold'>연관 장르</div>
        <div className='mb-48 w-full'>
          {stalierGenreList.map((item) => (
            <div className='inline-block mb-10 mr-10' key={item._id}>
              <CheckBox label={item.name} selected={genre.includes(item._id)} onClick={() => {
                if(genre.includes(item._id)) setGenre(list => list.filter(it => it !== item._id ));
                else setGenre(list => [...list, item._id])
              }}/>
            </div>
          ))}
        </div>


        <div className='font-18 mb-10 bold'>키워드 타입</div>
        <div className='w-full mb-48'>
          {
            KeywordTypeList.map((item) => (
              <div className='inline-block mb-10 mr-10' key={item.name}>
                <CheckBox label={item.name} selected={keywordType.includes(item.value)} onClick={() => {
                  if(keywordType.includes(item.value)) setKeywordType(list => list.filter(it => it !== item.value ));
                  else setKeywordType(list => [...list, item.value])
                }}/>
              </div>
            ))
          }
        </div>

        <LabelInput label={"키워드명"} required value={keywordName} onChange={e => setKeywordName(e.target.value)}/>
      
        <div className='w-full flex justify-end mt-30'>
          <Button onClick={submit}>키워드 수정</Button>
        </div>
      </Modal>
    </Container>
  ) 
}


const KeywordTypeList = [
  {
    name: '장르',
    value: 0
  },
  {
    name: '소재',
    value: 1
  },
  {
    name: '주인공',
    value: 2
  }
]
import { Button, Dropdown, Table, Pagination, SearchBar } from "@/components";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { useNovelList } from "@/stores/novelList";
import { formatDateYMD, formatDateYYMD, formatImageUri } from "@/utils/format";
import { downloadCSV } from "@/utils/csv";
import { useGenreList } from "@/stores/genre";

const HEADERS = [
  { name: "썸네일", minWidth: 110 },
  { name: "작품명", minWidth: 220 },
  { name: "장르", minWidth: 110 },
  { name: "판매정책", minWidth: 80 },
  { name: "연재상태", minWidth: 80 },
  { name: "회차", minWidth: 80 },
  { name: "작가명", minWidth: 110 },
  { name: "CP사", minWidth: 110 },
  { name: "ISBN", minWidth: 110 },
  { name: "게시일", minWidth: 110 },
];

const Container = styled.div`
  padding: 60px 100px;
`;

const SearchContainer = styled.div`
  margin-bottom: 50px;
`;

const OptionContainer = styled.div`
  display: flex;
  gap: 13px;
  margin-bottom: 20px;
`;

const TableTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 26px;
`;


export default function Page() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchOptions, setSearchOptions] = useState({
    search: "", // 검색어
    searchCategory: "작품명", // 검색 카테고리
    genre: "장르", // 장르
    serialStatus: "연재상태", // 연재 상태
  });



  const { novelList, page, count, isLoading, handlePage, handleCount } = useNovelList();


  const { genreList } = useGenreList();
  
  function downloadCSVButton() {
    const headers = HEADERS.map(item => item.name);
    const dataList = novelList?.list?.map(data => {
      return [
        formatImageUri(data.book_cover),
        data?.isBook ? "[단행본] " + data.title : data.title,
        data?.genre?.name,
        data.isPaid ? '유료' : '무료',
        data.hasCompletion ? "완결" : "연재중", 
        data?.episodeCount ?? 0,
        data?.writer_list?.map(item => item.name).join(", "),
        data.owner?.name,
        data.taxTag + '-' + data.taxCode,
        formatDateYMD(data.created_date),
      ]
    })

    downloadCSV(headers, dataList, '작품 목록')
  }
  

  return (
    <Container>
      <ButtonTitle
        title='작품 목록'
        containerSytle={{ marginBottom: 38}}
      />

      <SearchContainer>
        <SearchBar
          options={[{ name: "작품명" }]}
          dKey="searchCategory"
          label={searchOptions.searchCategory}
          setSelect={({ name: selected }) => setSearchOptions((prev) => ({ ...prev, searchCategory: selected }))}
          search={searchOptions.search}
          onChange={(e) => setSearchOptions({ ...searchOptions, search: e.target.value })}
        />


        <OptionContainer>
          <Dropdown
            options={genreList}
            label={searchOptions.genre}
            dkey="genre"
            setSelect={({ name: selected }) => setSearchOptions((prev) => ({ ...prev, genre: selected }))}
          />
          <Dropdown
            options={[{ name: "연재중" }, { name: "완결" }]}
            label={searchOptions.serialStatus}
            dkey="serialStatus"
            setSelect={({ name: selected }) => setSearchOptions((prev) => ({ ...prev, serialStatus: selected }))}
          />
        </OptionContainer>

        <Button width={590} onClick={() => {
          setSearchParams(prev => {
            prev.set("search", searchOptions.search);
            prev.set("page", 1);
            if(searchOptions.genre !== "장르") prev.set("genre", genreList.find(it => it.name === searchOptions.genre)._id);
            if(searchOptions.serialStatus !== "연재상태") {
              if(searchOptions.serialStatus === "연재중") prev.set("hasCompletion", false);
              else prev.set("hasCompletion", true);
            }
            return prev;
          });
        }}>검색하기</Button>
      </SearchContainer>

      <TableTitle>전체 {novelList.totalCount}개</TableTitle>
      

      <Table
        id="novel-list"
        className="mb-60"
        header={HEADERS}
        datas={novelList.list?.map((data) => {
          return {
            id: data.title + data.episode,
            renders: [
              { id: "thumbnail", render: () => <img src={formatImageUri(data.book_cover)} alt="thumbnail" style={{ width: 82, height: 118 }} draggable={false} /> },
              { id: "title", render: data?.isBook ? "[단행본] " + data.title : data.title },
              { id: "genre", render: data?.genre?.name },
              { id: "isPaid", render: data.isPaid ? '유료' : '무료' },
              { id: "serialStatus", render: data.hasCompletion ? "완결" : "연재중" }, // [TODO] 추가 필요
              { id: "episode", render: data?.episodeCount ?? 0 },
              { id: "author", render: data.writer_list?.map(item => item.name).join(", ") },
              { id: "cp", render: data.owner?.name },
              { id: "isbn", render: data.taxTag + '-' + data.taxCode },
              { id: "date", render: formatDateYMD(data.created_date) },
            ],
            onClick: () => {
              navigate(`/content/list/detail/${data.uuid}`);
            }
          }
        })}
      />

      <div className="flex align-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]}
          label={`${count}개`}
          dkey="pageSize"
          selected={{name: `${count}개`,value: count}}
          setSelect={item=>handleCount(item.value)}
        />

        <Pagination
          currentPage={page}
          totalPage={novelList.totalPage}
          onClickPage={(page) => {
            handlePage(page);
          }}
        />

        <Button style={{ marginLeft: 'auto' }} width={245} onClick={() => {
          downloadCSVButton();
        }}>CSV 다운로드</Button>
      </div>
      
    </Container>
  );
}

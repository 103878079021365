import { getTheme } from '@/styles/theme'
import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  position: relative;
  background: ${({readOnly}) => readOnly ? '#eeeeee' : '#FFFFFF'};
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;

  input {
    height: 40px;
    width: 100%;
    border-radius: 8px;
    border: solid 1px #fff;  
    background: ${({readOnly}) => readOnly ? '#eeeeee' : '#FFFFFF'};
    padding: 0 16px;
  
    color: ${getTheme('text')};
  }

  // input:focus {
  //   border: 1px solid ${getTheme('primary')};
  // }


  input::placeholder {
    color: #9d9d9d;
  }

  .buttonText {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 16px;
    margin: auto;
  }

  input {
    ::placeholder {
      color: #B0ABB5;
    }
  }
`

function Input ({ name, onChange, buttonLabel = null, buttonOnClick = null, placeholder, readOnly=false, width=375, containerStyle, font = 14, inputStyle, ...rest }) {
  return (
    <Container width={width} readOnly={readOnly} style={containerStyle}>
      <input name={name} style={inputStyle} onChange={onChange} className={`font-${font}`} placeholder={placeholder} readOnly={readOnly} {...rest}/>
    </Container>
  )
}

export default Input
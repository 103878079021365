import axios, { axiosAuth } from '@/lib/axios';

export const fetchWriterSearchList = async (search_text) => {
  const { data } = await axios.get(`/writer/search/list/${search_text}`);
  return data;
}

export const fetchWriterAdd = async (writerName) => {
  try {
    await axiosAuth.post(`/writer/admin`, { name: writerName });
    return true;
  } catch (e) {
    alert('작가 추가에 실패했습니다.');
    return false;
  }
}

export const getWriterListByIds = async (ids) => {
  const { data } = await axiosAuth.get(`/writer/admin/list/${ids}`);
  return data;
}
import { Button, DivideBox, Dropdown, Table } from "@/components";
import { useState } from "react";
import styled from "styled-components";

// 세금계산서 정보
const TAX_INFO = [
  [
    { name: "국가", content: "대한민국" },
    { name: "유형", content: "일반계약"},
    { name: "대상 기간", content: "2024.06"},
  ],
  [
  ],
];

const HEADER = [
  { name: "정산월", minWidth: 100 },
  { name: "발행구분", minWidth: 120 },
  { name: "품목", minWidth: 120 },
  { name: "단가", minWidth: 120 },
  { name: "수량", minWidth: 100 },
  { name: "공급가액", minWidth: 120 },
  { name: "세액", minWidth: 120 },
  { name: "합계금액", minWidth: 120 },
  { name: "청구/영수", minWidth: 120 },
  { name: "비고", minWidth: 200 },
];

const DATA = [
  {
    id: 1,
    renders: [
      {
        id: 1,
        render: () => <div>2024.06</div>,
      },
      {
        id: 2,
        render: () => <div>전자세금계산서</div>,
      },
      {
        id: 3,
        render: () => <div>전자책 판매대금</div>,
      },
      {
        id: 4,
        render: () => <div>114,000</div>,
      },
      {
        id: 5,
        render: () => <div>1</div>,
      },
      {
        id: 6,
        render: () => <div>114,000</div>,
      },
      {
        id: 7,
        render: () => <div>10,000</div>,
      },
      {
        id: 8,
        render: () => <div>114,000</div>,
      },
      {
        id: 9,
        render: () => <div>청구</div>,
      },
      {
        id: 10,
        render: () => <div>없음</div>,
      },
    ]
  },
];

// 세금계산서 항목
const TAX_ITEM = [];

const Container = styled.div`
  padding: 60px 100px;
`;

const Title = styled.div`
  min-width: 600px;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 60px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 60px;
`;

const SimpleButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ color }) => color || "#000"};
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
`;

function Page () {
  const [selectMonth, setSelectMonth] = useState(null);

  return (
    <Container>
      <Title>세금계산서 조회</Title>
      <Dropdown
        style={{ width: "140px", marginBottom: "20px" }}
        select={selectMonth}
        setSelect={setSelectMonth}
        label="선택"
        options={[
          {name: "2024년 1월"},
          {name: "2024년 2월"},
          {name: "2024년 3월"},
          {name: "2024년 4월"},
          {name: "2024년 5월"},
          {name: "2024년 6월"},
        ]}
      />
      <ButtonContainer>
        <Button width={140}>조회하기</Button>
        <Button width={140}>csv 다운</Button>
      </ButtonContainer>

      <Title style={{ marginBottom: '30px' }} >2024.06 세금계산서 정보</Title>
      <div style={{ maxWidth: '900px' }}>
        <DivideBox className="mb-100" infoArr={TAX_INFO} />
      </div>

      <Title>세금계산서 항목</Title>
      <Table className="mb-30" header={HEADER} datas={DATA} />
      <div className="flex justify-end gap-12">
        <SimpleButton type="button" onClick={(e) => {
          e.preventDefault();
          alert('발행자 정보 조회');
        }}>
          발행자 정보 조회
        </SimpleButton>
        <SimpleButton type="button" color="#ba1c1f" onClick={(e) => {
          e.preventDefault();
          alert('공급받는 자 정보 조회');
        }}>
          공급받는 자 정보 조회
        </SimpleButton>

      </div>
    </Container>
  )
}

export default Page;

import { Button, Dropdown, Input, Pagination, SearchBar, Table } from "@/components";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { useUserList } from "@/stores/userList";
import { downloadCSV } from "@/utils/csv";
import { formatDate, formatDateYMD } from "@/utils/format";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const HEADERS = [
  { name: "가입일", minWidth: 120 },
  { name: "닉네임", minWidth: 120 },
  { name: "실명", minWidth: 100 },
  { name: "EMAIL", minWidth: 120 },
  { name: "전화번호", minWidth: 120 },
  { name: "생년월일", minWidth: 120 },
  { name: "성인인증 유무", minWidth: 140 },
  { name: "비고", minWidth: 200 },
];

const countOptions = [
  { name: "10개", value: 10 },
  { name: "20개", value: 20 },
  { name: "30개", value: 30 },
];

const Container = styled.div`
  padding: 60px 100px;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 60px;
`;

const TotalCount = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const SEARCH_OPTIONS = [
  { name: "실명", value: "name" },
  { name: "EMAIL", value: "email" },
];

function Member() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchOptions, setSearchOptions] = useState({ 
    search: searchParams.get('search') ?? '', // 검색어
    searchCategory: SEARCH_OPTIONS.find((option) => option.value === searchParams.get('searchCategory')) ?? SEARCH_OPTIONS[0], // 검색 카테고리
  });

  const { userList, page, count, isLoading, handlePage, handleCount } = useUserList();

  
  function downloadCSVButton() {
    const headers = HEADERS.map(item => item.name);
    const dataList = userList.list?.map(data => {
      return [
        formatDateYMD(data.created_date),
        data.nickname,
        data.name,
        data.email,
        `${data.phone}`,
        data.birth?.split("T")[0],
        data.isAdult ? "O" : "X",
        data.note,
      ]
    })

    downloadCSV(headers, dataList, '회원 관리')
  }

  return (
    <Container>
      <ButtonTitle 
        title='회원 관리'
        containerSytle={{ marginBottom: 60}}
      />

      <SearchContainer>
        <SearchBar
          searchBarContainerStyle={{ width: "600px", height: "46px", margin: '0' }}
          fontSize={15}
          options={SEARCH_OPTIONS}
          dKey="searchCategory"
          select={searchOptions.searchCategory}
          setSelect={(select) => setSearchOptions({ ...searchOptions, searchCategory: select })}
          search={searchOptions.search}
          onChange={(e) => setSearchOptions({ ...searchOptions, search: e.target.value })}
        />
      </SearchContainer>

      <ButtonContainer>
        <Button width={140} onClick={() => {
          setSearchParams(prev => {
            prev.set("search", searchOptions.search);
            prev.set("searchCategory", searchOptions.searchCategory.value);
            prev.set("page", 1);
            return prev;
          });
        }}>조회하기</Button>
      </ButtonContainer>

      <TotalCount>전체 {userList.totalCount}개</TotalCount>

      <Table
        id="sale-list"
        className="mb-50"
        header={HEADERS}
        datas={
          userList.list.map((data) => ({
            id: data.id,
            onClick: () => {
              navigate(`/member/${data._id}`);
            },
            renders: [
              { render: () => data?.created_date ? formatDateYMD(data.created_date) : formatDate(data.created) },
              { render: () => data.nickname },
              { render: () => data.name },
              { render: () => data.email },
              { render: () => data.phone },
              { render: () => data.birth?.split("T")[0] },
              { render: () => data.isAdult ? "O" : "X" },
              { render: () => data.note },
            ],
          }))
        }
      />

      <div className="flex align-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={countOptions}
          select={countOptions.find((option) => option.value === count)}
          dkey="pageSize"
          setSelect={({ value: selected }) => {
            handleCount(selected);
          }}
        />
          <Pagination
            currentPage={page}
            totalPage={userList.totalPage}
            onClickPage={handlePage}
          />

        <Button style={{ marginLeft: 'auto' }} width={245} isLoading={isLoading} onClick={() => {
          downloadCSVButton()
        }}>CSV 다운로드</Button>
      </div>
    </Container>
  );
}

export default Member;

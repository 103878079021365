import { fetchUploadEpisodeList } from "@/api/episode";
import { Button, Dropdown, Table, Textarea } from "@/components";
import Loading from "@/components/Loading";
import { useNovelCreateData } from "@/stores/novelCreate";
import { useNovelData } from "@/stores/novelData";
import { formatBits } from "@/utils/format";
import { validateLength } from "@/utils/validate";
import { useState } from "react";
import styled from "styled-components";

const SubTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  padding: 25px;
`;


const TableContainer = styled.div`
  .table_header_box {
    height: 60px;
    border-bottom: 4px solid #000;
  }

  .table_body_item {
    height: 64px;
    border-bottom: 2px solid #bbbbbb;
  }
`

function Step2({ files, last_episode_index, onClose, novel, novel_uuid, isBook }) {
  const { PaidTypeList } = useNovelCreateData();

  console.log({files})

  const [requestComment, setRequestComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isLoading && <Loading/>}
      <div>
        <TableContainer className="w-full">
          <div className="w-full flex table_header_box align-center">
            <div className="font-24 bold" style={{ minWidth: '200px'}}>파일명</div>
            <div className="font-24 bold" style={{ minWidth: '140px'}}>용량</div>
            <div className="font-24 bold" style={{ minWidth: '120px'}}>유/무료</div>
            <div className="font-24 bold flex-1" style={{ minWidth: '200px'}}>제목</div>
            <div className="font-24 bold flex-1" style={{ minWidth: '100px'}}>가격</div>
            {isBook && <div className="font-20 bold" style={{ width: '200px'}}>면세코드</div>}
            <div className="font-24 bold" style={{ minWidth: '30px'}}></div>
          </div>

          <div className="table_body_box w-full">
            {files.map((file, index) => (
              <div className="table_body_item w-full flex align-center" key={`dfile-i-${index}`}>
                <div className="font-24 bold" style={{ minWidth: '200px'}}>{file.originFile.name}</div>
                <div className="font-24 bold" style={{ minWidth: '140px'}}>{formatBits(file.originFile.size)}</div>
                <div className="font-24 bold" style={{ minWidth: '120px'}}>
                  <div style={{width: "100px"}}>
                    {file.isPaid.value ? "유료" : "무료"}
                  </div>
                </div>
                <div className="font-24 bold flex-1" style={{ minWidth: '200px'}}>
                  {file.title}
                </div>
                <div className="font-24 bold flex-1" style={{ minWidth: '100px'}}>
                  {file?.episode_price_amount ?? 1} 스타
                </div>
                
                
                {isBook && <div className="font-20 bold flex align-center" style={{ width: '200px'}}>
                  <div className=''>
                    {file?.taxTag?.name} - {file?.taxCode}
                  </div>
                </div>}

                <div className="font-24 bold" style={{ minWidth: '30px'}}></div>
              </div>
            ))}
          </div>
        </TableContainer>

        <div className="flex justify-end my-24">
          <Button width={140} isLoading={isLoading} onClick={async () => {
            if (isLoading) return;
            setIsLoading(true);
            try {
              await fetchUploadEpisodeList({
                novel,
                novel_uuid,
                files,
                request_comment: requestComment,
                last_episode_index
              });
              alert('등록이 완료되었습니다.');

              onClose();
              window.location.reload();
            } catch (e) {
              console.log({e})
              return alert('등록에 실패했습니다.', e)
            } finally {
              setIsLoading(false);
            }
          }}>
            회차 등록
          </Button>
        </div>
      </div>
    </>
  );
}

export default Step2;

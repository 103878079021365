import React, { Fragment } from 'react'
import { Routes, Route, BrowserRouter, Navigate, Outlet, useLocation } from 'react-router-dom'
import LayoutContainer from '../layouts/LayoutContainer'
import ErrorBoundary from './ErrorBoundary'
import LoginPage from '@/pages/login'

// [TODO] 로그인 필요 페이지 나눠야함, 배열로 둬서 예외처리 해도 좋을 듯
const DEFAULT = import.meta.globEager('/src/pages/(_app|404).jsx')
const PAGE = import.meta.globEager('/src/pages/**/[a-z[]*.jsx')

console.log({DEFAULT, PAGE});

const defaults = Object.keys(DEFAULT).reduce((basic, file) => {
  const key = file.replace(/\/src\/pages\/|\.jsx$/g, '')
  return { ...basic, [key]: DEFAULT[file].default }
}, {})

const pages = Object.keys(PAGE)
.filter(page => { // PAGE 내의 pc, mobile 거르기
  if(page.includes('mobile')) return false
  else if(page.includes('pc')) return false
  else if(page.includes('modal')) return false
  else if(page.includes('login')) return false
  
  else if(page.includes('signup')) return false

  else if(page.includes('rank')) return false
  else return true
})
.map((page) => { // page의 path 정형화, element 컴포넌트화
  const path = page
    .replace(/\/src\/pages|index|\.jsx$/g, '')
    .replace(/\[\.{3}.+\]/, '*')
    .replace(/\[(.+)\]/, ':$1')

  return { path, element: PAGE[page].default }
})


const PrivateRoute = ({ authenticationRequired }) => {
  const isAuthenticated = !!localStorage.getItem('sua_access_token');
  const location = useLocation();

  if (authenticationRequired && !isAuthenticated) {
    // 인증이 필요한데 사용자가 인증되지 않은 경우 로그인 페이지로 리다이렉션
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (!authenticationRequired && isAuthenticated) {
    // 인증이 필요 없는데 사용자가 이미 인증된 경우 홈 페이지로 리다이렉션
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};



export const AppRouter = () => {
  const App = defaults?.['_app'] || Fragment
  const NotFound = defaults?.['404'] || Fragment

  console.log(pages)

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <LayoutContainer>
          <Routes>
            <Route exact path='/login' element={<LoginPage/>}/>

      

            <Route element={<PrivateRoute authenticationRequired={false} />}>
              {/* 로그인 필요 없는 페이지 ('/'로 이동) */}
          
            </Route>
            

            <Route element={<PrivateRoute authenticationRequired={true} />}>
              <Route exact path="/" element={<App />} />
              
              {/* 로그인 필요 페이지 */}
              {pages.map(({ path, element: Element }) => (
                <Route key={path} path={path} element={<Element />} />
              ))}
            </Route>

            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </LayoutContainer>
      </BrowserRouter>
    </ErrorBoundary>
  )
}
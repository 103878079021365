import styled from "styled-components";
import { Dropdown, Pagination, Table } from "..";
import { useParams } from "react-router-dom";
import { formatDateYMD } from "@/utils/format";
import { useUserCoinList } from "@/stores/userCoinList";
import { useEffect, useState } from "react";
import { fetchGetUserLogList } from "@/api/log";

const HEADERS = [
  { name: "수정일시", minWidth: 200 },
  { name: "항목", minWidth: 180 },
  { name: "시행 ip", minWidth: 140 },
  { name: "변경 전 데이터", minWidth: 300 },
  { name: "변경 후 데이터", minWidth: 300 },
  { name: "어드민 처리 유무", minWidth: 140 },
];

const countOptions = [
  { name: "10개", value: 10 },
  { name: "20개", value: 20 },
  { name: "30개", value: 30 },
];

const TotalCount = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
`;

function FixHistory() {
  const { uid } = useParams();

  const [list, setList] = useState([])
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getData();
  }, [page, setPage, count])

  async function getData() {
    const { list, total, totalCount } = await fetchGetUserLogList(uid, 1, page, count);

    setList(list);

    // 전체 카운트
    setTotalCount(totalCount);
  
    // 토탈
    setTotalPage(total);
  }



  return (
    <div>
      <TotalCount>전체 {totalCount}개</TotalCount>

      <Table
        id="sale-list"
        className="mb-50"
        header={HEADERS}
        datas={
          list.map((data) => ({
            id: data.id,
            renders: [
              { render: () => formatDateYMD(data.created_date) },
              { render: () => data.type1_comment },
              { render: () => data.created_ip },
              { render: () => JSON.stringify(data.type1_before_content) },
              { render: () => JSON.stringify(data.type1_after_content) },
              { render: () => data.isAdminCreated ? "O" : "X" }, // 추후 데이터 확인 후 수정 필요
            ],
          }))
        }
      />

      <div className="flex align-center justify-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]}
          label={`${count}개`}
          dkey="pageSize"
          setSelect={({ value: selected }) => {
            setCount(selected)
          }}
        />

        <Pagination
          currentPage={page}
          totalPage={totalPage}
          onClickPage={setPage}
        />
      </div>
    </div>
  );
}

export default FixHistory;

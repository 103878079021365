import styled from 'styled-components';
import DivideBox from '../DivideBox';
import { Button, Dropdown, Table, Pagination } from '..';
import { Colors } from 'chart.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatBits, formatDateYMD } from '@/utils/format';
import { useEffect, useState } from 'react';
import useEpubUpoadModal from '../EpubUploadModal/useEpubUploadModal';
import useStartSaleModal from '../StartSaleModal/useStartSaleModal';
import useEpubUpdateModal from '../EpubUpdateModal/useEpubUpdateModal';
import { fetchApproveEpisode, fetchEpisodeUnActivate, fetchRejectEpisode } from '@/api/episode';
import { getDecisionByEpisodeId } from '@/api/decision';
import AudioBookUploadModal from '../AudioBookUploadModal';
import EpubPreview from '../EpubPreview';

const BoldText = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  color: #000;
  padding: 0px 0px 23px 0px;
`

const WorkInfo=styled.div`
  font-size: 21px;
  letter-spacing: 0.42px;
  text-align: left;
  color: #636363;
`

const WorkInfoTitle = styled.div`
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0.42px;
  text-align: left;
  color: #000;
`




function InfoTab({ 
  summary, 
  infoArr, 
  infoHEADERS, 
  datas, 
  novelData,
  page,
  setPage,
  count,
  setCount
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const [selectedData, setSelectedData] = useState([]);

  const navigate = useNavigate();

  const [, setSearchParams] = useSearchParams();

  const [EpubUploadModal, showModal] = useEpubUpoadModal();
  const [StartSaleModal, showStartSaleModal] = useStartSaleModal();
  const [EpubUpdateModal, showUpdateModal] = useEpubUpdateModal();


  const [previewSelect, setpreviewSelect] = useState(null)

  const [isAudioBookOpen, setIsAudioBookOpen] = useState(false);

  useEffect(() => {
    let filter_datas = datas.list.filter(item => selected.includes(item._id) && !selectedData.find(it => it._id === item._id));
    let li = [...selectedData, ...filter_datas];

    let li_datas = li.filter(item => selected.includes(item._id));

    setSelectedData(li_datas)
  }, [selected])

  return (<div>
    <div>
      <BoldText>기본정보</BoldText>
      </div>
      <DivideBox infoArr={infoArr}/>
      
      <div style={{ padding: '37px 20px' }}>
        <WorkInfoTitle className ='mb-34'>작품소개</WorkInfoTitle>
        <WorkInfo className='mb-100 pre-line'>{summary}</WorkInfo>
      </div>

      <BoldText>회차</BoldText>
      <hr className='mb-30' style={{ borderTop: '2px solid #000' ,backgroundColor:'#e7e6e7'}} />

      <div className='flex justify-end mb-38'>
        {novelData?.isAudioBook && <Button width={140} height={45} onClick={() => setIsAudioBookOpen(true)}>오디오북 삽입</Button>}
        <div className='mx-12'/>
        <Button width={140} height={45} onClick={showModal}>회차 추가</Button>
      </div> 


      <Table
        selected={selected}
        setSelected={setSelected}
        className='mb-30'
        headerAlign="start"
        bodyAlign="start"
        tableStyle={{ backgroundColor: '#fff', boxShadow:'0.5px 7px 24px 0 rgba(0, 0, 0, 0.04)', borderRadius: '20px'}}
        id="info-episode-list"
        header={infoHEADERS}  
        datas={datas?.list?.map((data) => ({
          id: data._id,
          renders: [
            { render: () => data.episode_index },
            { render: () => data.title },
            { render: () => data.isPaid ? "유료" : "무료" },
            { render: () => data.episode_price_amount + "개" },
            { render: () => data.version },
            { render: () => ["승인대기중", "승인완료", "승인반려", "판매중", "판매중지"][data.product_status] },
            { render: () => formatDateYMD(data.created_date)},
            { render: () => formatBits(data.fileSize) },
            { render: () => ["대기중", "승인", "반려"][data.hasApproved] },
            { render: () => <div className='underline bold pointer' onClick={() => {
              setpreviewSelect({
                novel_uuid: novelData.uuid,
                episode_index: data.episode_index,
                version: data.version
              })
            }}>미리보기</div> },
          ]}
        ))}
      />

      <div className='flex' style={{justifyContent:'left'}}>
        <Dropdown  
          containerStyle={{ width:84 ,height : 32 }}
          options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]}
          label={`30개`}
          select={{name: `${count}개`, value: count}}
          setSelect={item => setCount(item.value)}
        />
        
        <Pagination 
          currentPage={page}
          totalPage={datas.totalPage}
          onClickPage={(page) => {
            setPage(page);
          }}
        />
      </div>
      
      
      <AudioBookUploadModal
        isOpen={isAudioBookOpen}
        setIsOpen={setIsAudioBookOpen}
      />

      <EpubUploadModal
        isBook={novelData?.isBook ?? false}
        last_episode_index = {datas?.totalCount ?? 0}
      />

      


      <StartSaleModal 
        episodeList={selectedData} // [TODO] 변경 필요. 데이터를 못 가져옴
        selected={selected}
      />



      <EpubUpdateModal
        isBook={novelData?.isBook ?? false}
        novelData={novelData}
        episodeList={selectedData}
        selected={selected}
      />

      <EpubPreview
        isOpen={!!previewSelect}
        onClose={() => setpreviewSelect(null)}
        novel_uuid={previewSelect?.novel_uuid}
        episode_index={previewSelect?.episode_index}
        version={previewSelect?.version}
      />


      <div className='flex justify-end mb-38 mt-40'>
        <Button width={140} height={45} onClick={() => {
          const selected_episode = selectedData;


          if(selected_episode.find(item => item.product_status === 3)) return alert('이미 판매중인 회차가 있습니다.');
          // 승인 검증
          else if(selected_episode.find(item => item.product_status === 3)) return alert('승인 받지 않은 회차가 있습니다.');
          else showStartSaleModal();
        }}>판매하기</Button>

        <div className='mx-12'/>

        <Button width={140} height={45} onClick={async () => {
          if (isLoading) return;
          setIsLoading(true);
          try {
            if(selected.length === 0) throw new Error('선택된 회차가 없습니다.');
            const selected_episode = selectedData;
            if(selected_episode.find(item => item.hasApproved === 1)) throw new Error('이미 승인된 회차가 있습니다.');
          
            const fetchArr = selected_episode.map(async (item) => {
              const decision = await getDecisionByEpisodeId(item._id);

              await fetchApproveEpisode(decision._id);
            });

            const result = await Promise.allSettled(fetchArr);

            const filterReject = result.filter(item => item.status === 'rejected');
            if(filterReject.length > 0) throw new Error(`승인에 실패한 회차가 있습니다. (${filterReject.length}개)`);

            alert('승인에 성공했습니다.');

            setSearchParams((prev) => {
              prev.set('timestamp', Date.now());
              return prev;
            });
          } catch (e) {
            alert(e.message);
          } finally {
            setIsLoading(false);
          }
        }}>승인</Button>
        
        <div className='mx-12'/>
        
        <Button width={140} height={45} background={'red'} onClick={async () => {
          if (isLoading) return;
          setIsLoading(true);
          try {
            if(selected.length === 0) throw new Error('선택된 회차가 없습니다.');
            const selected_episode = selectedData;
            if(selected_episode.find(item => item.hasApproved === 3)) throw new Error('이미 반려된 회차가 있습니다.');
            
            const fetchArr = selected_episode.map(async (item) => {
              const decision = await getDecisionByEpisodeId(item._id);

              await fetchRejectEpisode(decision._id);
            });

            const result = await Promise.allSettled(fetchArr);

            const filterReject = result.filter(item => item.status === 'rejected');
            if(filterReject.length > 0) throw new Error(`반려에 실패한 회차가 있습니다. (${filterReject.length}개)`);

            alert('반려에 성공했습니다.');

            setSearchParams((prev) => {
              prev.set('timestamp', Date.now());
              return prev;
            });
          } catch (e) {
            alert(e.message);
          }
          finally {
            setIsLoading(false);
          }
        }}>반려</Button>
        
        <div className='mx-12'/>
        
        <Button width={140} height={45} onClick={showUpdateModal}>수정하기</Button>
        
        <div className='mx-12'/>
        
        <Button width={140} height={45} background={'red'} onClick={async () => {
          if (isLoading) return;
          setIsLoading(true);

          try {
            if (!confirm('정말 비활성화 하시겠습니까?')) return;
            if (selected.length === 0) throw new Error('선택된 회차가 없습니다.');
            const selected_episode = selectedData;
            const fetchArr = selected_episode.map(async (item) => {
              await fetchEpisodeUnActivate(item._id);
            });

            const result = await Promise.allSettled(fetchArr);

            const filterReject = result.filter(item => item.status === 'rejected');

            if(filterReject.length > 0) throw new Error(`비활성화에 실패한 회차가 있습니다. (${filterReject.length}개)`);

            alert('비활성화에 성공했습니다.');

            setSearchParams((prev) => {
              prev.set('timestamp', Date.now());
              return prev;
            });
          } catch (e) {
            alert(e.message);
          } finally {
            setIsLoading(false);
          }
        }}>비활성화</Button>
      </div> 
    </div>

  );
}

export default InfoTab;


import { Button, DivideBox, Dropdown, Table } from "@/components";
import { useState } from "react";
import styled from "styled-components";

const TAX_INFO = [
  [
    { name: "국가", content: "대한민국" },
    { name: "유형", content: "일반계약"},
    { name: "정산주기", content: "1개월"},
    { name: "대상 기간", content: "2024.06"},
  ],
  [
    { name: "발행자", content: "be주류"},
    { name: "공급받는 자", content: "(주)스텔미디어"},
    { name: "플랫폼", content: "스텔라"},
  ],
];

const HEADER = [
  { name: "면세유무", minWidth: 100 },
  { name: "발행자", minWidth: 120 },
  { name: "총 매출 금액", minWidth: 120 },
  { name: "정산금액", minWidth: 120 },
  { name: "공급가액", minWidth: 120 },
  { name: "부가세", minWidth: 120 },
  { name: "정산율", minWidth: 100 },
];

const DATA = [
  {
    id: 1,
    renders: [
      {
        id: 1,
        render: () => <div>면세</div>,
      },
      {
        id: 2,
        render: () => <div>be 주류</div>,
      },
      {
        id: 3,
        render: () => <div>180,992</div>,
      },
      {
        id: 4,
        render: () => <div>114,084</div>,
      },
      {
        id: 5,
        render: () => <div>114,084</div>,
      },
      {
        id: 6,
        render: () => <div>0</div>,
      },
      {
        id: 7,
        render: () => <div>70%</div>,
      },
    ]
  },
  {
    id: 2,
    renders: [
      {
        id: 1,
        render: () => <div>면세</div>,
      },
      {
        id: 2,
        render: () => <div>be 주류</div>,
      },
      {
        id: 3,
        render: () => <div>180,992</div>,
      },
      {
        id: 4,
        render: () => <div>114,084</div>,
      },
      {
        id: 5,
        render: () => <div>114,084</div>,
      },
      {
        id: 6,
        render: () => <div>0</div>,
      },
      {
        id: 7,
        render: () => <div>70%</div>,
      },
    ]
  },
];

const Container = styled.div`
  padding: 60px 100px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 60px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 60px;
`;

const SimpleButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ color }) => color || "#000"};
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
`;

function Page () {
  const [selectMonth, setSelectMonth] = useState(null);

  return (
    <Container>
      <Title>월 정산</Title>
      <Dropdown
        style={{ width: "140px", marginBottom: "20px" }}
        select={selectMonth}
        setSelect={setSelectMonth}
        label="선택"
        options={[
          {name: "2024년 1월"},
          {name: "2024년 2월"},
          {name: "2024년 3월"},
          {name: "2024년 4월"},
          {name: "2024년 5월"},
          {name: "2024년 6월"},
        ]}
      />
      <ButtonContainer>
        <Button width={140}>조회하기</Button>
        <Button width={140}>csv 다운</Button>
      </ButtonContainer>

      <Title style={{ marginBottom: '30px' }} >be주류 월 정산 <span className="font-24">(일반계약)</span></Title>
      <div style={{ maxWidth: '900px' }}>
        <DivideBox className="mb-100" infoArr={TAX_INFO} />
      </div>

      <div className="flex gap-30">
        <Title>정산 금액</Title>
        <Button width={140}>상세내역 다운</Button>
      </div>
      <Table className="mb-30" header={HEADER} datas={DATA} />
      <div className="flex justify-end gap-12">
        <SimpleButton type="button" onClick={(e) => {
          e.preventDefault();
          alert('발행자 정보 조회');
        }}>
          발행자 정보 조회
        </SimpleButton>
        <SimpleButton type="button" color="#ba1c1f" onClick={(e) => {
          e.preventDefault();
          alert('공급받는 자 정보 조회');
        }}>
          공급받는 자 정보 조회
        </SimpleButton>
      </div>
      </Container>
  )
}

export default Page;

import { fetchAdminSendNotification } from '@/api/notification';
import { Button, LabelInput } from '@/components';
import ButtonTitle from '@/components/ButtonTitle/ButtonTitle';
import { useState } from 'react';
import styled from 'styled-components'


const Container = styled.div`
  padding: 60px 100px;
`;


export default function Page () {

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [link, setLink] = useState('');


  async function submit() {
    const result = await fetchAdminSendNotification({
      title, body, link
    });
  }

  return (
    <Container>
      <ButtonTitle
        backButton
        title='어드민 알람 발송'
        containerSytle={{ marginBottom: 60 }}
      />

      <LabelInput label={"제목"} value={title} onChange={e => setTitle(e.target.value)}/>
      <div className="mt-24"/>
      
      <LabelInput label={"내용"} value={body} onChange={e => setBody(e.target.value)}/>
      <div className="mt-24"/>

      <LabelInput label={"링크"} value={link} onChange={e => setLink(e.target.value)}/>
    
      <div className='w-full flex justify-end mt-48'>
        <Button onClick={submit}>발송</Button>
      </div>
    </Container>
  )
}
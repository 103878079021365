import { axiosAuth } from "@/lib/axios"

export const fetchCreateCoupon = async ({
  coupon_number,
  title,
  coupon_start,
  coupon_end = null,
  amount,
}) => {
  try {
    await axiosAuth.post('/coupon', {
      coupon_number,
      title, // 오디 상품 제목\
      coupon_start, // 판매 시작일
      coupon_end, // 판매 종료일
      amount, // 수량
    })

    return alert('쿠폰이 추가되었습니다.')
  } catch {
    return alert('쿠폰 추가에 실패했습니다.')
  }
}





export const fetchCouponList = async () => {
  const { data } = await axiosAuth.get('/coupon/admin/list');
  return data;
}


export const fetchUpdateActiveCouponList = async (product_list) => {
  try {  
    await axiosAuth.post('/coupon/unactivate/list', {
      couponList: product_list
    })

    return alert('리스트가 비활성화 되었습니다.')
  } catch {
    return alert('비활성화에 실패했습니다.')
  }
}
import { axiosAuth } from "@/lib/axios"

export const fetchCreateProduct = async ({
  title,
  product_sales_start,
  product_sales_end = null,
  amount,
  price
}) => {
  try {
    await axiosAuth.post('/product', {
      title, // 오디 상품 제목\
      product_sales_start, // 판매 시작일
      product_sales_end, // 판매 종료일
      amount, // 수량
      price, // 판매 가격
    })

    return alert('상품이 추가되었습니다.')
  } catch {
    return alert('상품 추가에 실패했습니다.')
  }
}

export const fetchCreateIOSProduct = async ({
  title,
  product_sales_start,
  product_sales_end = null,
  amount,
  price,
  productId
}) => {
  try {
    await axiosAuth.post('/product/ios', {
      title, // 오디 상품 제목\
      product_sales_start, // 판매 시작일
      product_sales_end, // 판매 종료일
      amount, // 수량
      price, // 판매 가격
      product_id_IOS: productId
    })

    return alert('상품이 추가되었습니다.')
  } catch {
    return alert('상품 추가에 실패했습니다.')
  }
}




export const fetchProductList = async () => {
  const { data } = await axiosAuth.get('/product/admin/list');
  return data;
}


export const fetchUpdateActiveProductList = async (product_list) => {
  try {  
    await axiosAuth.post('/product/unactivate/list', {
      productList: product_list
    })

    return alert('리스트가 비활성화 되었습니다.')
  } catch {
    return alert('비활성화에 실패했습니다.')
  }
}
import styled from "styled-components";
import { Dropdown, Pagination, Table } from "..";
import { formatDateYYMD, formatDate } from "@/utils/format";

const HEADER = [
  { name: "생성일", minWidth: 200 },
  { name: "프로모션 종류", minWidth: 200 },
  { name: "프로모션 이름", minWidth: 300 },
  { name: "프로모션 시작일", minWidth: 200 },
  { name: "프로모션 종료일", minWidth: 200 },
  { name: "비활성화 여부", minWidth: 140 },
  { name: "타임딜 무료회차 수", minWidth: 200 },
  { name: "생성 어드민", minWidth: 120 },
  { name: "생성 어드민 IP", minWidth: 160 },
];


const EHEADER = [
  { name: "생성일", minWidth: 200 },
  { name: "프로모션 종류", minWidth: 200 },
  { name: "프로모션 이름", minWidth: 300 },
  { name: "프로모션 시작일", minWidth: 200 },
  { name: "프로모션 종료일", minWidth: 200 },
  { name: "비활성화 여부", minWidth: 140 },
  { name: "생성 어드민", minWidth: 120 },
  { name: "생성 어드민 IP", minWidth: 160 },
];


const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.6px;
  margin-bottom: 44px;
`;

function PromotionLog({ 
  novelDetailCoinPromotionList,
  novelDetailExposureExPromotionList
}) {
  return (
    <div>
      <Title>재화 프로모션 이력</Title>

      <Table className="mb-40" header={HEADER} datas={
        novelDetailCoinPromotionList?.map((item) => {
          return {
            id: item?._id,
            renders: [
              { render: () => <div>{formatDateYYMD(item.created_date)}</div> },
              { render: () => <div>{["타임딜", "스확시간"][item.promotionNumber]}</div>, },
              { render: () => <div>{item.title}</div> },
              { render: () => <div>{formatDate(item.promotion_start)}</div> },
              { render: () => <div>{formatDate(item.promotion_end)}</div> },
              { render: () => <div>{item.isActive ? 'X' : 'O'}</div> },
              { render: () => <div>{item.promotionNumber === 0 ? item.timedeal_episode_count + "화" : '타임딜 X'}</div> },
              { render: () => <div>{item?.create_admin?.name ?? '스텔라'}</div> },
              { render: () => <div>{item?.create_admin_ip}</div> },
            ],
          }
        })
      } />

      <div className="mt-80"/>


      <Title>노출 프로모션 이력</Title>

      <Table className="mb-40" header={EHEADER} datas={
        novelDetailExposureExPromotionList?.map((item) => {
          return {
            id: item?._id,
            renders: [
              { render: () => <div>{formatDateYYMD(item.created_date)}</div> },
              { render: () => <div>{["당신을 위한 추천"][item.promotionNumber]}</div>, },
              { render: () => <div>{item.title}</div> },
              { render: () => <div>{formatDate(item.promotion_start)}</div> },
              { render: () => <div>{formatDate(item.promotion_end)}</div> },
              { render: () => <div>{item.isActive ? 'X' : 'O'}</div> },
              { render: () => <div>{item?.create_admin?.name ?? '스텔라'}</div> },
              { render: () => <div>{item?.create_admin_ip}</div> },
            ],
          }
        })
      } />
    </div>
  );
}

export default PromotionLog;

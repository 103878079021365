import styled from "styled-components";
import { Modal } from "..";
import { useMemo, useState } from "react";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import { useParams } from "react-router-dom";
import { useNovelData } from "@/stores/novelData";

const ModalTitle = styled.div`
  font-size: 44px;
  font-weight: bold;
  margin-bottom: 80px;
`;


function useEpubUpoadModal() {
  const [isOpen, setIsOpen] = useState(false);
  
  const { uid } = useParams();
  const { novelData } = useNovelData(uid);

  

  return [
    ({ isBook, last_episode_index }) => {
      const [step, setStep] = useState(1); // 1: 파일 업로드, 2: 회차 심사
      const [files, setFiles] = useState([]); // 업로드한 파일 목록
      
      const subTitle = useMemo(() => {
        if (step === 1) return "epub 업로드";
        if (step === 2) return "화차 심사";
      }, [step]);
      
      return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} style={{
          width: '1200px',
          maxWidth: '88vw',
          height: '800px',
          maxHeight: '96vh'
        }}>
          <ModalTitle>
            {isBook ? "[단행본]" : ""} {novelData?.title} - {subTitle}
          </ModalTitle>

          {step === 1 && <Step1 
            isBook={isBook}
            files={files} 
            setFiles={setFiles} 
            setStep={setStep} 
            last_episode_index={last_episode_index}
          />}

          {step === 2 && <Step2 
            isBook={isBook}
            files={files}
            setFiles={setFiles} 
            setStep={setStep} 
            last_episode_index={last_episode_index}
            onClose={() => setIsOpen(false)}
            novel={novelData?._id}
            novel_uuid={uid}
          />}
        </Modal>
      )
    },
    () => setIsOpen(true),
  ];
};

export default useEpubUpoadModal;

import { fetchGetInquiryDetail, fetchPutInquiryResponse } from '@/api/inquiry';
import { fetchAdminSendNotification } from '@/api/notification';
import { Button, LabelInput, Textarea } from '@/components';
import ButtonTitle from '@/components/ButtonTitle/ButtonTitle';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 60px 100px;
`;


export default function Page () {
  const { uid } = useParams();

  const [detail, setDetail] = useState(null)
  const [response_content, set_response_content] = useState('');


  async function submit() {
    await fetchPutInquiryResponse(uid, response_content);
  }

  
  
  useEffect(() => {
    if(uid) getData(uid);
  }, [uid])


  
  
  async function getData(uid) {
    const result = await fetchGetInquiryDetail(uid);
    setDetail(result);
    set_response_content(result?.response_content ?? "")
  }

  return (
    <Container>
      <ButtonTitle
        backButton
        title='문의 답변'
        containerSytle={{ marginBottom: 60 }}
      />

      <LabelInput label={"제목"} value={detail?.title} readOnly/>
      <div className="mt-24"/>


      <LabelInput label={"타입"} value={detail?.inquiry_type} readOnly/>
      <div className="mt-24"/>
      
      <div className='bold font-18 mt-32 mb-10'>문의 내용</div>
      <Textarea value={detail?.content} readOnly/>
    
      <div className='bold font-18 mt-32 mb-10'>답변</div>
      <Textarea 
        readOnly={detail?.hasResponsed}
        value={response_content} 
        onChange={e => set_response_content(e.target.value)}
      />



      {!detail?.hasResponsed && <div className='w-full flex justify-end mt-48'>
        <Button onClick={submit}>발송</Button>
      </div>}
    </Container>
  )
}
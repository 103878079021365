import { atom, useRecoilState, useResetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchOwnLabelList } from '@/api/label';
import { fetchFileToUri } from '@/api/file';
import { fetchNewNovelData, fetchNovelDetailData, fetchUpdateNovelData } from '@/api/novel';
import { validateFile, validateLength, validateNumber } from '@/utils/validate';

const AgeGradeList = [
  { name: "전체이용가", value: "전체이용가" },
  { name: "15세 이용가", value: "15세 이용가" },
  { name: "19세 이용가", value: "19세 이용가" },
]

const SalesTypeList = [
  { name: "해당없음", value: "해당없음" },
  { name: "독점", value: "독점" },
  { name: "선공개", value: "선공개" },
]

const TaxTypeList = [
  { name: "면세", value: "면세" },
  { name: "과세", value: "과세" },
]

const PaidTypeList = [
  { name: "유료", value: true },
  { name: "무료", value: false },
]

const TaxTagList = [
  { name: "ISBN", value: "ISBN" },
  { name: "UCI", value: "UCI" },
  { name: "ISSN", value: "ISSN" },
  { name: "ESN", value: "ESN" },
]
  
const RentBenefitList = [
  { name: "없음", value: 0 },
  { name: "10화 무료", value: 10 },
  { name: "15화 무료", value: 15 },
]

const novelUpdateDataState = atom({
  key: 'novelUpdateData',
  default: {
    uuid: '',
    title: '', // 제목
    writer_list: [], // 작가 리스트
    book_cover: '', // 표지
    book_cover_file: null, // 표지 ( 파일 )
    keywords: [], // 키워드
    genre: {name: "장르", value: ''}, // 장르
    summary: '', // 줄거리 = 작품소개
    ageGrade: AgeGradeList[0], // 연령 등급
    taxTag: TaxTagList[0], // [ isbn, uci, issn, esn ] 사라져도 상관 없어짐
    taxCode: '', // 면세 코드
    serialCycle: [false, false, false, false, false, false, false], // 일 ~ 토요일 7개짜리 배열
    taxType: TaxTypeList[0], // 면세 / 과세 여부
    isPaid: PaidTypeList[0],
    regularPrice: 100, // 전자책 정가
    salesType: SalesTypeList[0], // 판매 유형
    rentBenefit: RentBenefitList[0],
    hasCompletion: false,
    non_rental_episode_count: 5
  },
});

export const useNovelUpdateData = (uid) => {
  const [novelUpdateData, setNovelUpdateData] = useRecoilState(novelUpdateDataState);

  const [labelList, setLabelList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {

    const getNovelData = async () => {
      try {
        const response = await fetchNovelDetailData(uid);

        console.log({response})

        setNovelUpdateData({
          uuid: response.uuid,
          title: response.title, // 제목
          writer_list: response.writer_list, // 작가 리스트
          book_cover: response.book_cover, // 표지
          book_cover_file: null, // 표지 ( 파일 )
          keywords: response.keywords, // 키워드
          non_rental_episode_count: response.non_rental_episode_count,
          genre: {name: response?.genre?.name, value: response?.genre?._id}, // 장르
          summary: response.summary, // 줄거리 = 작품소개
          ageGrade: {name: response.ageGrade, value: response.ageGrade}, // 연령 등급
          taxTag: {name: response.taxTag, value: response.taxTag}, // [ isbn, uci, issn, esn ] 사라져도 상관 없어짐
          taxCode: response.taxCode, // 면세 코드
          serialCycle: response.serialCycle, // 일 ~ 토요일 7개짜리 배열
          taxType: {name: response.taxType, value: response.taxType}, // 면세 / 과세 여부
          isPaid: {name: response.isPaid ? "유료" : "무료", value: response.isPaid},
          regularPrice: response.regularPrice, // 전자책 정가
          salesType: {name: response.salesType, value: response.salesType}, // 판매 유형
          rentBenefit: {name: response.rentBenefit, value: response.rentBenefit},
          hasCompletion: response.hasCompletion,
        });

      } catch (error) {
        console.error('Failed to fetch novel Data:', error);
      } finally {
        setIsLoading(false);
      }
    }

    if(uid) {
      getNovelData();
    }
  }, []);


  const handleChange = (key, value) => {
    // validation check
    switch(key) {
      case 'title':
        if(!validateLength(value, 50)) return alert('제목은 50자 이내로 작성해주세요.'); // 필요시 length 수정
        break;
      case 'summary':
        if(!validateLength(value, 5000)) return alert('작품소개는 5000자 이내로 작성해주세요.'); // 필요시 length 수정
        break;
      case 'taxCode':
        // Todo: 면세 코드 validation check
        break;
      case 'regularPrice':
        if(!validateNumber(value)) return alert('숫자만 입력해주세요.');
        break;
      case 'book_cover_file':
        if(!validateFile(value, 1024 * 1024 * 30)) return alert('이미지 파일은 30MB 이하로 업로드해주세요.'); // 필요시 size 수정
        break;
      default:
        break;
    }

    console.log(key, value)
    setNovelUpdateData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  

  
  const pushWriterList = (writerData) => {
    let copy_writer_list = [...novelUpdateData.writer_list];

    if(copy_writer_list.length <= 2) {
      copy_writer_list.push(writerData);
      
      setNovelUpdateData((prevData) => ({
        ...prevData,
        writer_list: copy_writer_list,
      }));
    } else {
      return alert('공동 작가는 3명을 넘을 수 없습니다.');
    }
  }


  const handleSerialCycle = (idx) => {
    let copySerialCycle = [...novelUpdateData.serialCycle];

    copySerialCycle[idx] = !copySerialCycle[idx];
    
    setNovelUpdateData((prevData) => ({
      ...prevData,
      serialCycle: copySerialCycle,
    }));
  }


  const keywordSelect = (item) => {
    let copyKeywordSelect = [...novelUpdateData.keywords];

    if(novelUpdateData.keywords.find(fit => fit.name === item.name && fit.keywordType === item.keywordType)) {
      copyKeywordSelect = copyKeywordSelect.filter(fit => fit._id !== item._id);
    }
    else copyKeywordSelect.push(item);
    

    setNovelUpdateData((prevData) => ({
      ...prevData,
      keywords: copyKeywordSelect,
    }));
  }



  async function submit() {
    const uuid = novelUpdateData.uuid;
    const fileName = nanoid(24);

    // validation check
    if (novelUpdateData.title.length === 0) {
      setIsLoading(false);
      return alert('제목을 입력해주세요.');
    }
    if (!validateLength(novelUpdateData.title, 50)) {
      setIsLoading(false);
      return alert('제목은 50자 이내로 작성해주세요.');
    }
    if (novelUpdateData.writer_list.length === 0) {
      setIsLoading(false);
      return alert('작가를 입력해주세요.');
    }
    if (novelUpdateData?.book_cover_file && !validateFile(novelUpdateData.book_cover_file, 1024 * 1024 * 30)) {
      setIsLoading(false);
      return alert('이미지 파일은 30MB 이하로 업로드해주세요.');
    }
    if (novelUpdateData.keywords.length === 0) {
      setIsLoading(false);
      return alert('키워드를 입력해주세요.');
    }
    if (novelUpdateData.genre.value === '') {
      setIsLoading(false);
      return alert('장르를 입력해주세요.');
    }
    if (novelUpdateData.summary.length === 0) {
      setIsLoading(false);
      return alert('작품소개를 입력해주세요.');
    }
    if (!validateLength(novelUpdateData.summary, 5000)) {
      setIsLoading(false);
      return alert('작품소개는 5000자 이내로 작성해주세요.');
    }
    if (novelUpdateData.taxCode.length === 0) {
      setIsLoading(false);
      return alert('면세 코드를 입력해주세요.');
    }
    if (novelUpdateData.regularPrice.length === 0) {
      setIsLoading(false);
      return alert('전자책 정가를 입력해주세요.');
    }
    if (!validateNumber(novelUpdateData.regularPrice)) {
      setIsLoading(false);
      return alert('숫자만 입력해주세요.');
    }
    
    
    let new_novel = { ...novelUpdateData };

    console.log(novelUpdateData)

    // 이미지 처리
    if(novelUpdateData?.book_cover_file) {
      await fetchFileToUri(`novel/${uuid}`, fileName, novelUpdateData.book_cover_file);
      new_novel.book_cover = `novel/${uuid}/${fileName}.png`;
    } else new_novel.book_cover = novelUpdateData.book_cover;


    new_novel.uuid = uuid;
    new_novel.ageGrade = novelUpdateData.ageGrade.value;
    new_novel.genre = novelUpdateData.genre.value;
    new_novel.isPaid = novelUpdateData.isPaid.value;
    new_novel.salesType = novelUpdateData.salesType.value;
    new_novel.taxTag = novelUpdateData.taxTag.value;
    new_novel.taxType = novelUpdateData.taxType.value;
    new_novel.rentBenefit = novelUpdateData.rentBenefit.value;

    new_novel.writer_list = novelUpdateData.writer_list?.map(item => item._id);
    new_novel.keywords = novelUpdateData.keywords.map(item => item._id);


    console.log(new_novel);



    // Fetch Data
    try {
      await fetchUpdateNovelData(new_novel);
      window.history.back();
    } catch {
    }


  }


  return {
    AgeGradeList,
    TaxTypeList,
    SalesTypeList,
    PaidTypeList,
    TaxTagList,
    novelUpdateData,
    labelList,
    RentBenefitList,
    submit,
    keywordSelect,
    handleChange,
    handleSerialCycle,
    pushWriterList
  }
}
import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { fetchStalierGenreList } from '@/api/genre';


const stalierGenreListState = atom({
  key: 'StalierGenreList',
  default: []
});


export const useStalierGenreList = () => {
  const [stalierGenreList, setStalierGenreList] = useRecoilState(stalierGenreListState);

  useEffect(() => {
    const getGenreList = async () => {
      try {
        const genreList = await fetchStalierGenreList();

        
        // let sort_list = genreList.map((item) => {
        //   return {
        //     value: item._id,
        //     name: item.name
        //   }
        // })

        setStalierGenreList(genreList || []);
      } catch (err) {
        console.error('Failed to Fetch Genre List', err);
      }
    }


    getGenreList();
  }, [])


  return { 
    stalierGenreList
  }

}
import { Dropdown, Pagination } from "@/components";
import { AccordionMenu } from "@/components/AccordionMenu";
import styled from "styled-components";


const Container = styled.div`
  padding: 60px 100px;
`;

const Title = styled.div`
  min-width: 600px;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 60px;
`;

const DocumentContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0.5px 7px 24px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
`;


function Page () {

  return (
    <Container>
      <Title>공지사항</Title>
      <div className="mb-100">
        <AccordionMenu
          content={
            `
              어쩌구 저쩌구 이래서 저래서
              어쩌구 저쩌구 어쩌구 저쩌구
              이래서 저래서 어쩌구 저쩌구
              어쩌구 저쩌구 이래서 저래서 어쩌구 저쩌구어쩌구 
              쩌구 이래서 저래서 어쩌구 저쩌구 어쩌구 저쩌구 이래서 저래서 어쩌구 저쩌구
              어쩌구 저쩌구 이래서 저래서
              어쩌구 저쩌구 어쩌구 저쩌구
              이래서 저래서 어쩌구 저쩌구
              어쩌구 저쩌구 이래서 저래서 어쩌구 저쩌구어쩌구 
              쩌구 이래서 저래서 어쩌구 저쩌구 어쩌구 저쩌구 이래서 저래서 어쩌구 저쩌구
              어쩌구 저쩌구 이래서 저래서
              어쩌구 저쩌구 어쩌구 저쩌구
              이래서 저래서 어쩌구 저쩌구
              어쩌구 저쩌구 이래서 저래서 어쩌구 저쩌구어쩌구 
              쩌구 이래서 저래서 어쩌구 저쩌구 어쩌구 저쩌구 이래서 저래서 어쩌구 저쩌구
              어쩌구 저쩌구 이래서 저래서
              어쩌구 저쩌구 어쩌구 저쩌구
              이래서 저래서 어쩌구 저쩌구
              어쩌구 저쩌구 이래서 저래서 어쩌구 저쩌구어쩌구 
              쩌구 이래서 저래서 어쩌구 저쩌구 어쩌구 저쩌구 이래서 저래서 어쩌구 저쩌구
              어쩌구 저쩌구 이래서 저래서
              어쩌구 저쩌구 어쩌구 저쩌구
              이래서 저래서 어쩌구 저쩌구
              어쩌구 저쩌구 이래서 저래서 어쩌구 저쩌구어쩌구 
              쩌구 이래서 저래서 어쩌구 저쩌구 어쩌구 저쩌구 이래서 저래서 어쩌구 저쩌구
              어쩌구 저쩌구 이래서 저래서
              어쩌구 저쩌구 어쩌구 저쩌구
              이래서 저래서 어쩌구 저쩌구
              어쩌구 저쩌구 이래서 저래서 어쩌구 저쩌구어쩌구 
              쩌구 이래서 저래서 어쩌구 저쩌구 어쩌구 저쩌구 이래서 저래서 어쩌구 저쩌구
            `
          }
        >
          <div className="flex gap-4 font-18 mb-14">
            <div className="bold">제목:</div>
            <div>공지사항</div>
          </div>
          <div className="flex gap-4 font-14">
            <div className="bold">작성일시:</div>
            <div>2024-07-01 11:30</div>
            <div className="bold">작성자:</div>
            <div>스텔미디어 운영팀</div>
          </div>
        </AccordionMenu>
      </div>

      <div className="flex">
        <Dropdown options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]} select={{ name: "10개", value: 10 }} />
        <Pagination currentPage={1} totalPage={100} />
      </div>
    </Container>
  );
}

export default Page;

import { Cell, Legend, Pie, PieChart as RechartsPieChart, ResponsiveContainer, Tooltip } from "recharts";

const data = [
  { name: '작품명 1', value: 400 },
  { name: '작품명 2', value: 300 },
  { name: '작품명 3', value: 300 },
  { name: '작품명 4', value: 200 },
  { name: '작품명 5', value: 100 },
  { name: '작품명 6', value: 200 },
  { name: '작품명 7', value: 500 },
  { name: '기타', value: 10 },
];

// 8개의 색상을 정의합니다.
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF1919', '#19FF19', '#19FFD9'];

// 라디안 값을 구하는 상수입니다.
const RADIAN = Math.PI / 180;

// 라벨을 커스텀 렌더링하는 함수입니다.
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={600}>
        <RechartsPieChart margin={{ bottom: 40 }}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={160}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend iconSize={10} verticalAlign="bottom"/>
        </RechartsPieChart>
      </ResponsiveContainer>
  );
}

export default PieChart;
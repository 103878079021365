import styled from "styled-components";
import { Dropdown, Pagination, Table } from "..";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { fetchuserUseCoinList } from "@/api/coin";
import { formatDateYMD, formatDateYYMD, formatNumber } from "@/utils/format";

const HEADERS = [
  { name: "결제일", minWidth: 120 },
  { name: "작품", minWidth: 200 },
  { name: "회차", minWidth: 200 },
  { name: "소장", minWidth: 120 },
  { name: "결제 전 스타", minWidth: 120 },
  { name: "결제 후 스타", minWidth: 120 },
  { name: "스타 사용량", minWidth: 120 },
  { name: "영수/주문확인 email", minWidth: 200 },
];


const TotalCount = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
`;

function PaymentInfo() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { uid } = useParams();

  const [pagination, setPagination] = useState({
    page: searchParams.get("page") || 1,
    pageSize: searchParams.get("pageSize") || 10,
    total: 100,
  });

  const [list, setList] = useState([])
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getData();
  }, [page, setPage, count])

  async function getData() {
    const { list, total, totalCount } = await fetchuserUseCoinList(page, count, uid);

    setList(list);

    // 전체 카운트
    setTotalCount(totalCount);
  
    // 토탈
    setTotalPage(total);
  }


  return (
    <div>
      <TotalCount>전체 {totalCount}개</TotalCount>

      <Table
        id="user-sale-use-list"
        className="mb-50"
        header={HEADERS}
        datas={
          list?.map((data) => ({
            id: data.id,
            renders: [
              { render: () => formatDateYMD(data?.created_date) },
              { render: () => data?.target_novel?.title },
              { render: () => data?.expense ? data.expense?.target_episode?.title : data?.expense_list.length + "개 항목 선택구매" },
              { render: () => "소장" },
              { render: () => formatNumber(data.previousQuantity) },
              { render: () => formatNumber(data.previousQuantity - data.amount)},
              { render: () => formatNumber(data.amount) },
              { render: () => data.user.email },
            ],
          }))
        }
      />

      <div className="flex align-center justify-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]}
          label={`${count}개`}
          dkey="pageSize"
          setSelect={({ value: selected }) => {
            setCount(selected)
          }}
        />

        <Pagination
          currentPage={page}
          totalPage={totalPage}
          onClickPage={(page) => setPage(page)}
        />
      </div>
    </div>
  );
}

export default PaymentInfo;

import styled from "styled-components";
import { Dropdown, Pagination, Table } from "..";

const HEADER = [
  { name: "수정일", minWidth: 120 },
  { name: "수정 시간", minWidth: 120 },
  { name: "수정페이지", minWidth: 200 },
  { name: "수정자", minWidth: 120 },
  { name: <div>플랫폼 수정사유 <span className="font-12">(배급사에서 수정 시에만 작성합니다)</span></div>, minWidth: 600 },
];

const DATAS = [
  {
    renders: [
      {
        render: () => <div>2021-07-02</div>,
      },
      {
        render: () => <div>12:00</div>,
      },
      {
        render: () => <div>작품 목록 {"->"} 정보</div>,
      },
      {
        render: () => <div>be 주류</div>,
      },
      {
        render: () => <div></div>,
      },
    ],
  },
  {
    renders: [
      {
        render: () => <div>2021-07-01</div>,
      },
      {
        render: () => <div>12:00</div>,
      },
      {
        render: () => <div>작품 목록 {"->"} 정보</div>,
      },
      {
        render: () => <div>be 주류</div>,
      },
      {
        render: () => <div></div>,
      },
    ],
  },
];

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.6px;
  margin-bottom: 44px;
`;

function FixLog() {
  return (
    <div>
      <Title>수정 이력</Title>
      <Table className="mb-40" header={HEADER} datas={DATAS} />

      <div className="flex">
        <Dropdown style={{ width: 90 }} options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]} select={{ name: "10개", value: 10 }} />
        <Pagination currentPage={1} totalPage={100} />
      </div>
    </div>
  );
}

export default FixLog;

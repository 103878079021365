import { Button, Dropdown, Input, Table } from "@/components";
import { useNovelCreateData } from "@/stores/novelCreate";
import { formatBits } from "@/utils/format";
import { validateLength } from "@/utils/validate";
import ProgressBar from "@ramonak/react-progress-bar";
import styled from "styled-components";




const UploadLabel = styled.label`
  border: solid 1px #ba1c1f;
  border-radius: 7px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  cursor: pointer;
  align-items: center;
  gap: 35px;

  .text {
    font-size: 44px;
    font-weight: bold;
  }
`;

const TableContainer = styled.div`
  .table_header_box {
    height: 60px;
    border-bottom: 4px solid #000;
  }

  .table_body_item {
    height: 64px;
    border-bottom: 2px solid #bbbbbb;
  }
`

function Step1({ files, setFiles, setStep, last_episode_index, isBook }) {
  const { PaidTypeList } = useNovelCreateData();

  return (
    <div>
      <UploadLabel>
        <svg
          width="70"
          height="70"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="35" cy="35" r="32" stroke="#ba1c1f" strokeWidth="6" />
          <path
            d="M35 20V50M20 35H50"
            stroke="#ba1c1f"
            strokeWidth="6"
          />
        </svg>
        <input
          type="file" 
          accept=".epub"
          style={{display: 'none'}}
          multiple
          onChange={e => {
            console.log(e.target.files);

            let new_files = [...e.target.files].map((item, idx) => {
              return {
                originFile: item,
                title: item.name.slice(0, -5),
                isPaid: PaidTypeList[0]
              }
            });

            setFiles(new_files);
          }}
        />

        <div className="text">
          Epub 파일을 업로드 해주세요
        </div>
      </UploadLabel>
      
      <div className="py-30 mb-100">
        {/* <ProgressBar completed={60} /> */}
      </div>

      <div className="flex flex-center mb-24">
        <Button width={140} onClick={() => {
          const list = files.map((dataItem, idx) => {
            if (idx < 5) {
              return { ...dataItem, isPaid: PaidTypeList[1] };
            }
            return dataItem;
          });
          setFiles(list);
        }}>5화 무료</Button>
        <div className="mr-16"/>

        <Button width={140} onClick={() => {
          const list = files.map((dataItem, idx) => {
            if (idx < 10) {
              return { ...dataItem, isPaid: PaidTypeList[1] };
            }
            return dataItem;
          });
          setFiles(list);
        }}>10화 무료</Button>
        <div className="mr-16"/>

        <Button width={140}  onClick={() => {
          const list = files.map((dataItem, idx) => {
            if (idx < 15) {
              return { ...dataItem, isPaid: PaidTypeList[1] };
            }
            return dataItem;
          });
          setFiles(list);
        }}>15화 무료</Button>
        <div className="mr-16"/>

        <Button width={140}  onClick={() => {
          const list = files.map((dataItem, idx) => {
            if (idx < 20) {
              return { ...dataItem, isPaid: PaidTypeList[1] };
            }
            return dataItem;
          });
          setFiles(list);
        }}>20화 무료</Button>
        <div className="mr-16"/>

        <Button width={140}  onClick={() => {
          const list = files.map((dataItem, idx) => {
            if (idx < 25) {
              return { ...dataItem, isPaid: PaidTypeList[1] };
            }
            return dataItem;
          });
          setFiles(list);
        }}>25화 무료</Button>

        <div className="flex-1"/>
        {/* <Button width={140}>
          순서반전
        </Button> */}
      </div>

    

      <TableContainer className="w-full">
        <div className="w-full flex table_header_box align-center">
          <div className="font-20 bold" style={{ minWidth: '200px'}}>파일명</div>
          <div className="font-20 bold" style={{ minWidth: '140px'}}>용량</div>
          <div className="font-20 bold" style={{ minWidth: '120px'}}>유/무료</div>
          <div className="font-20 bold flex-1" style={{ minWidth: '200px', maxWidth: '380px'}}>제목</div>
          <div className="font-20 bold" style={{ width: '100px'}}>가격</div>
          {isBook && <div className="font-20 bold" style={{ width: '300px'}}>면세코드</div>}
        </div>

        <div className="table_body_box w-full">
          {files.map((file, index) => (
            <div className="table_body_item w-full flex align-center" key={`dfile-i-${index}`}>
              <div className="font-20 bold" style={{ minWidth: '200px'}}>{file.originFile.name}</div>
              <div className="font-20 bold" style={{ minWidth: '140px'}}>{formatBits(file.originFile.size)}</div>
              <div className="font-20 bold" style={{ minWidth: '120px'}}>
                <div style={{width: "100px"}}>
                  <Dropdown style={{minWidth: '80px'}} options={PaidTypeList} select={file.isPaid} setSelect={item => {
                    const list = files.map((dataItem, idx) => {
                      if (idx === index) {
                        return { ...dataItem, isPaid: item };
                      }
                      return dataItem;
                    });
                    setFiles(list);
                  }}/>
                </div>
              </div>

              {/* 제목 */}
              <div className="font-20 bold flex-1 pr-20" style={{ minWidth: '200px', maxWidth: '380px'}}>
                <Input value={file.title} onChange={e => {
                    const list = files.map((dataItem, idx) => {
                      if (idx === index) {
                        return { ...dataItem, title: e.target.value };
                      }
                      return dataItem;
                    });
                    setFiles(list);
                }}/>
              </div>

              
              {/* 가격 */}
              <div className="font-20 bold pr-20" style={{ width: '100px'}}>
                <Input placeholder={"1"} value={file.episode_price_amount} onChange={e => {
                  setFiles(list => {
                    let copy_list = [...list];
                    copy_list[index].episode_price_amount = e.target.value;
                    return copy_list;
                  })
                }}/>
              </div>

              
              {isBook && <div className="font-20 bold flex align-center" style={{ width: '300px'}}>
                <div className='' style={{ width: '140px'}}>
                  <Dropdown options={TaxTagList} select={file.taxTag} setSelect={item => {
                    setFiles(list => {
                      let copy_list = [...list];
                      copy_list[index].taxTag = item;
                      return copy_list;
                    })
                  }}/>
                </div>

                <div className='ml-4  ' style={{ width: '100%'}}>
                  <Input placeholder={"면세코드"} value={file.taxCode} onChange={e => {
                    setFiles(list => {
                      let copy_list = [...list];
                      copy_list[index].taxCode = e.target.value;
                      return copy_list;
                    })
                  }}/>
                </div>
              </div>}

            </div>
          ))}
        </div>
      </TableContainer>

      <div className="flex justify-end mb-24 mt-40">
        <Button width={140} onClick={() => setStep(2)}>
          다음으로
        </Button>
      </div>
    </div>
  );
}

export default Step1;


const TaxTagList = [
  { name: "ISBN", value: "ISBN" },
  { name: "UCI", value: "UCI" },
  { name: "ISSN", value: "ISSN" },
  { name: "ESN", value: "ESN" },
]
  
import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchStalierKeywordList } from '@/api/keyword';


const stalierKeywordListState = atom({
  key: 'StalierKeywordList',
  default: []
});


export const useStalierKeywordList = () => {
  const [stalierKeywordList, setStalierKeywordList] = useRecoilState(stalierKeywordListState);

  useEffect(() => {
    const getKeywordList = async () => {
      try {
        const keywordList = await fetchStalierKeywordList();

        setStalierKeywordList(keywordList);
      } catch (err) {
        console.error('Failed to Fetch Keyword List', err);
      }
    }


    getKeywordList();
  }, [])


  return { 
    stalierKeywordList
  }

}
import styled from "styled-components";
import { CheckBox } from "..";
import { useState } from "react";
import { formatDateYMD } from "@/utils/format";
import { useNavigate } from "react-router-dom";


const LineBox = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
border-bottom:2px solid #000;
padding: 15px;
gap: 40px;

&:first-child {
  border-top: 2px solid #000;
  
}
`

const noticeTypeList = {
  USER: "유저",
  CP: "CP사",
  ALL: "전체",
}


function NoticeDivideBox({ infoArr, checked, setChecked }){
  const navigate = useNavigate();
  
  return(
    <div>
        <div className="mb-80" style={{width:"900px"}}>
          {infoArr.map((info)=>{
            const selected = checked.includes(info._id);
            return(
              <LineBox key={info._id} onClick={() => navigate(`/etc/notice/register?id=${info._id}`)}>
                <div className="flex">

                  <CheckBox selected={selected} onClick={(e) => {
                    e.stopPropagation();
                    selected ? setChecked((prev) => {
                      return prev.filter((_id) => _id !== info._id)
                    }) : setChecked((prev) => [...prev, info._id])
                  }} />
                  <div className="ml-18">
                    <div className="flex mb-14">
                      <div className="bold">제목:</div>
                      {info.title}
                    </div>
                    <div className="flex">
                      <div className="bold">작성일시 :</div>
                      <div style={{color:'gray'}}>{formatDateYMD(info.created_date)}</div>
                      <div className="bold ml-10">작성자 :</div>
                      <div style={{color:'gray'}}>{info.created_admin?.name}</div>
                      <div className="bold ml-10">대상 :</div>
                      <div style={{color:'gray'}}>{noticeTypeList[info.noticeType]}</div>
                    </div>
                  </div>
                </div>
                <div className="bold mr-20">상세</div>
              </LineBox>
            )
          })}
        </div>

  </div>
  )
}

export default NoticeDivideBox;
import { fetchAddGenreData } from '@/api/genre';
import { Button, LabelInput, Modal } from '@/components'
import { axiosAuth } from '@/lib/axios';
import { validateMinLength, validateNotEmpty, validateSpecialChar } from '@/utils/validate';
import { useState } from 'react'
import styled from 'styled-components'


const Container = styled.div`
  .c-828282 {
    color: #828282;
    line-height: 20px;
  }
`


export default function GenreAddModal ({
  isOpen,
  setIsOpen
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [genreName, setGenreName] = useState('');
  
  async function submit() {
    if (isLoading) return;
    setIsLoading(true);
    try{
      if(!validateNotEmpty(genreName)) throw new Error('장르명을 입력해주세요.');
      if(!validateMinLength(genreName, 2)) throw new Error('장르명은 2자 이상 입력해주세요.');
      if(!validateSpecialChar(genreName)) throw new Error('특수문자는 입력할 수 없습니다.');
      // Fetch Create Genre
      try{
        const result = await fetchAddGenreData(genreName);
        
        // 장르 생성 성공
        if(result) {
          alert('장르가 생성되었습니다.');
          setGenreName('');
          setIsOpen(false);
        } else {}
      } catch(e) {
        console.error(e.message);
        throw new Error('장르 생성에 실패했습니다.');
      }
    } catch(e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  } 

  return (
    <Container>   
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} height={400}>
        <div className='bold font-30 mb-20'>장르 추가</div>
        <div className='c-828282 medium font-14 mb-20'>
          주의사항:<br/>
          1. <br/>
          2. <br/>
          3. <br/>
          4. 
        </div>
        
        <LabelInput
          label={"장르명"}
          required
          value={genreName}
          onChange={(e) => {
            setGenreName(e.target.value);
          }}
        />
      
        <div className='w-full flex justify-end mt-30'>
          <Button isLoading={isLoading} onClick={submit}>장르 등록</Button>
        </div>
      </Modal>
    </Container>
  ) 
}
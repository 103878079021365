import axios, { axiosAuth } from "@/lib/axios";

export const fetchNewNoticeData = async (noticeData) => {
  try {
    const { data } = await axiosAuth.post('/notice', {
      ...noticeData
    })

    alert('공지사항을 생성했습니다.')

    return data;
  } catch (e) {
    return alert('공지사항 생성에 실패했습니다.')
  }
}

export const fetchUpdateNoticeData = async (noticeData) => {
  try {
    const { data } = await axiosAuth.put('/notice', {
      ...noticeData
    })

    alert('공지사항을 수정했습니다.')

    return data;
  } catch (e) {
    return alert('공지사항 수정에 실패했습니다.')
  }
}

export const fetchNoticeList = async (page, count) => {
  const { data } = await axiosAuth.get(`/notice/list/admin/${page}/${count}`);
  return data;
}

export const fetchNoticeDetail = async (noticeId) => {
  const { data } = await axios.get(`/notice/detail/${noticeId}`);
  return data;
}
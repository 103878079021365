import { Button, Dropdown, Table, Pagination, SearchBar } from "@/components";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { useCpList } from "@/stores/useCpList";
import { formatDateYMD } from "@/utils/format";
import { downloadCSV } from "@/utils/csv";

const HEADERS = [
  { name: "계약일", minWidth: 130 },
  { name: "CP명", minWidth: 180 },
  { name: "계약 유형", minWidth: 100 },
  { name: "사업자번호", minWidth: 150 },
  { name: "대표자", minWidth: 130 },
  { name: "정산비율", minWidth: 130 },
  { name: "상태", minWidth: 300 },
  { name: "비고", minWidth: 220 },
];

const countOptions = [
  { name: "10개", value: 10 },
  { name: "20개", value: 20 },
  { name: "30개", value: 30 },
];

const Container = styled.div`
  padding: 60px 100px;
`;

const SearchContainer = styled.div`
  margin-bottom: 50px;
`;

const SearchBtnContainer = styled.div`
  display: flex;
  gap: 7px;
`;

const TableTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

const SEARCH_OPTIONS = [
  { name: "CP사 명", value: "name" },
];


export default function Page() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchOptions, setSearchOptions] = useState({
    search: searchParams.get("search") ?? "",
    type: SEARCH_OPTIONS.find((option) => option.value === searchParams.get("type")) ?? SEARCH_OPTIONS[0],
  });

  const { cpList, page, count, handlePage, handleCount, isLoading } = useCpList();

  function downloadCSVButton() {
    const headers = HEADERS.map(item => item.name);
    const dataList = cpList?.list?.map(data => {
      return [
        formatDateYMD(data.created_date),
        data.name,
        data.contractType,
        data.business_number,
        data.ceo_name,
        data.settlementRatio + "%",
        ["정상", "출판 정지"][data.status ?? 0],
        data.note,
      ]
    })
    downloadCSV(headers, dataList, 'CP사 목록')
  }


  return (
    <Container>
      <ButtonTitle
        title='CP사 목록/관리'
        containerSytle={{ marginBottom: 38 }}
      />

      <SearchContainer>
        <SearchBar
          options={SEARCH_OPTIONS}
          dKey="searchCategory"
          select={searchOptions.type}
          setSelect={(option) => setSearchOptions({ ...searchOptions, type: option })}
          search={searchOptions.search}
          onChange={(e) => setSearchOptions({ ...searchOptions, search: e.target.value })}
        />

        <SearchBtnContainer>
          <Button width={139} isLoading={isLoading} onClick={() => {
            setSearchParams((prev) => {
              prev.set("search", searchOptions.search);
              prev.set("type", searchOptions.type.value);
              prev.set("page", 1);
              return prev;
            })
          }}>조회하기</Button>

          <Button width={139} onClick={() => {
            downloadCSVButton();
          }}>csv 다운</Button>
        </SearchBtnContainer>

      </SearchContainer>

      <div className="flex justify-between align-center mb-30">
      <TableTitle>전체 {cpList.totalCount}개</TableTitle>
        <Button
          width={139}
          onClick={() => {
            navigate("/cp/register");
          }}
        >
          CP사 추가
        </Button>
      </div>

      <Table
        id="novel-list"
        className="mb-60"
        header={HEADERS}
        datas={cpList.list.map((data) => {
          return {
            id: data._id,
            renders: [
              { render: formatDateYMD(data.created_date) },
              { render: data.name },
              { render: data.contractType },
              { render: data.business_number },
              { render: data.ceo_name },
              { render: data.settlementRatio + "%" },
              { render: ["정상", "출판 정지"][data.status ?? 0]},
              { render: data.note },
            ],
            onClick: () => {
              navigate(`/cp/list/${data._id}`);
            }
          }
        })}
      />

      <div className="flex align-center justify-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={countOptions}
          dkey="pageSize"
          select={countOptions.find((option) => option.value === count)}
          setSelect={({ value }) => handleCount(value)}
        />

        <Pagination
          currentPage={page}
          totalPage={cpList.totalPage}
          onClickPage={handlePage}
        />
      </div>

    </Container>
  );
}

import styled from 'styled-components'
import { Modal } from '..'
import { useEffect, useRef, useState } from 'react';

import ePub from 'epubjs';
import { formatEpubUri, formatImageUri } from '@/utils/format';

const Container = styled.div`
  .stalier-content {
    padding: 0 16px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    box-sizing: border-box;
    overflow: auto; // 추가: 콘텐츠가 잘리지 않도록
  }
`


export default function StalierPreview ({
  isOpen,
  onClose,
  content
}) {

  return (
    <Container>
      <Modal isOpen={isOpen} onClose={onClose}  style={{
        width: '500px',
        maxWidth: '96vw',
        height: '800px',
        maxHeight: '96vh'
      }}>
        <div className="w-full pt-32 pb-48 ">
          <div className='stalier-content pre-line'>{content}</div>
        </div>
      </Modal>
    </Container>
  )
}
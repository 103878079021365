import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { fetchEpisodeDecisionApproveOne, fetchEpisodeDecisionList, fetchEpisodeDecisionRejectOne } from '@/api/decision';

const episodeDecisionListState = atom({
  key: 'EpisodeDecisionList',
  default: {
    list: [], // 리스트 데이터
    totalPage: 0, // 총 페이지 수
    totalCount: 0, // 총 갯수
  },
});

export const pageState = atom({
  key: 'episodeDecisionPageState',
  default: 1,
});

export const countState = atom({
  key: 'episodeDecisionCountState',
  default: 10,
});

export const useEpisodeDecisionList = () => {
  const [episodeDecisionList, setEpisodeDecisionList] = useRecoilState(episodeDecisionListState);
  const [page, setPage] = useRecoilState(pageState);
  const [count, setCount] = useRecoilState(countState);

  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    const countParam = searchParams.get('count');
    const pageParam = searchParams.get('page');
    const searchParam = searchParams.get('search');
    const typeParam = searchParams.get('type');
    const approveParam = searchParams.get('approve');

    if (countParam) {
      setCount(Number(countParam));
    }
    if (pageParam) {
      setPage(Number(pageParam));
    }

    const getEpisodeOwnList = async (page, count) => {
      if (isLoading) return;
      setIsLoading(true);
      try {
        const data = await fetchEpisodeDecisionList(page, count, searchParam, typeParam, approveParam);
        setEpisodeDecisionList(data);
      } catch (error) {
        console.error('Failed to fetch episode genre list:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getEpisodeOwnList(page, count || 10);
  }, [searchParams, page, count, setCount, setPage, setEpisodeDecisionList]);


  const handlePage = (pageValue) => {
    setPage(pageValue);
    setSearchParams((prev) => {
      prev.set('page', pageValue);
      return prev;
    })
  }
  
  const handleCount = (countValue) => {
    setCount(countValue);
    setSearchParams((prev) => {
      prev.set('count', countValue);
      return prev;
    })
    handlePage(1);
  };



  const submitApprove = async ( selected ) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      console.log('Approve', selected);

      for (let i = 0; i < selected.length; i++ ) {
        await fetchEpisodeDecisionApproveOne(selected[i]);

        if(i === selected.length -1) alert('심사 승인이 완료되었습니다.') 
      }
    } catch (error) {
      alert('심사 승인에 실패했습니다.')
    } finally {
      setIsLoading(false);
    }
  }


  const submitReject = async ( selected ) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      console.log('Reject', selected);

      for (let i = 0; i < selected.length; i++ ) {
        await fetchEpisodeDecisionRejectOne(selected[i]);

        if(i === selected.length -1) alert('심사 반려가 완료되었습니다.')
      }
    } catch (error) {
      alert('심사 반려에 실패했습니다.')
    } finally {
      setIsLoading(false);
    }
  }

  return {
    episodeDecisionList,
    page,
    count,
    isLoading,
    submitApprove,
    submitReject,
    handlePage,
    handleCount,
  };
};

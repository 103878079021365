import { getDecisionByNovelId } from '@/api/decision';
import { getWriterListByIds } from '@/api/writer';
import { book_cover_sample, ic_page_prev } from '@/assets';
import { Button, CheckBox, Dropdown, Input, LabelInput, Textarea } from '@/components'
import { useGenreList } from '@/stores/genre';
import { useKeywordList } from '@/stores/keywords';
import { formatImageUri } from '@/utils/format';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'

const BENEFIT_OPTIONS = {
  0: '없음',
  10: '10화 무료',
  15: '15화 무료',
}

const Container = styled.div`
  padding: 60px 100px;


  .section_line {
    width: 100%;
    height: 2px;
    background-color: #000000;
  }

  .imageUpload {
    width: 155px;
    height: 246px;
    background-color: #fff;
    border-radius: 10px;
    
    .book_cover {
      width: 100%;
      height: 100%;
    }
  }
`

const HeadContainer = styled.div`
  margin-bottom: 100px;
`

const Title = styled.div`
  margin-left: 20px;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-align: left;
  color: #000;
`

export default function Page () {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateData, setUpdateData] = useState(null);
  const [writerList, setWriterList] = useState([]);

  const { genreList } = useGenreList();
  const { keywordList } = useKeywordList();

  useEffect(() => {
    const getNovelDetail = async () => {
      const data = await getDecisionByNovelId(id);
      const writerIds = data?.[data.decisionType === 0 ? 'request_content' : 'target_novel']?.writer_list;
      const fetchedWriterList = await getWriterListByIds(writerIds?.join(','));
      setUpdateData(data?.[data.decisionType === 0 ? 'request_content' : 'target_novel']);
      setWriterList(fetchedWriterList);
    };

    getNovelDetail();
  }, [id]);

  if (!updateData) return null;

  return (
    <Container className='w-full'>

      <HeadContainer className='flex align-center'>
        <img src={ic_page_prev} className="pointer" alt='back' style={{ width: '34px',height : '23px' }} onClick={() => {
          navigate(-1);
        }}/>
        <Title>작품 상세</Title>
      </HeadContainer>
      <div className='bold font-24'>기본 정보</div>

      <div className='section_line my-24'/>

      <LabelInput value={updateData.title} label={"작품명"} readOnly />

      <>
        <div className='bold font-18 mt-32 mb-10'>
          장르 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          {genreList.find(item => item._id === updateData.genre)?.name}
        </div>
      </>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          연령등급 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          {updateData.ageGrade}
        </div>
      </>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          연재 요일 <span className='c-red'>*</span>
        </div>

        <div className='w-full flex align-center'>
          {updateData.serialCycle.map((item, idx) => (
            <div className='mr-16' key={`checkbox-idx-${idx}`}>
              <CheckBox selected={item} label={['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'][idx]}/>
            </div>
          ))}
        </div>
      </>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          완결 여부 <span className='c-red'>*</span>
        </div>

        <div className='w-full flex align-center'>
          <CheckBox selected={!updateData.hasCompletion} label={"연재중"}/>
          
          <div className='mr-16'/>

          <CheckBox selected={updateData.hasCompletion} label={"완결"}/>
        </div>
      </>
      
      
      <div style={{ width: '180px'}} className='mt-32'>
        <LabelInput value={updateData.non_rental_episode_count} readOnly label={"대여 불가 회차 수"}/>
      </div>
      
      <>
        <div className='bold font-18 mt-32 mb-10'>
          작품 소개 <span className='c-red'>*</span>
        </div>

        <div className='w-full'>
          <Textarea value={updateData.summary} readOnly/>
        </div>
      </>



      <div className='bold font-24 mt-68'>작가 정보</div>
      <div className='section_line my-24'/>

      {writerList.map((item) => item.name).join(', ')}
      
      <div className=''>
        {updateData.writer_list?.map((item, idx) => (
          <div className='bold' key={`selected-writer-${item.name}-${idx}`}>
            {item.name}
          </div>
        ))}
      </div>


      <div className='bold font-24 mt-68'>기타 정보</div>
      <div className='section_line my-24'/>

      {keywordList.filter(item => updateData.keywords.includes(item._id)).map(item => item.name).join(', ')}
      
      <>
        <div className='bold font-18 mt-32 mb-10'>
          유형 구분 <span className='c-red'>*</span>
        </div>
        <div className='' style={{ width: '180px'}}>
          {updateData.salesType}
        </div>
      </>

      
      <div className='bold font-24 mt-68'>면세 정보</div>
      <div className='section_line my-24'/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          면세/과세 <span className='c-red'>*</span>

        </div>
        <div className='' style={{ width: '180px'}}>
          {updateData.taxType}
        </div>
      </>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          면세(ISBN) 코드 <span className='c-red'>*</span>
        </div>

        <div className='flex align-center w-full'>
          <div className='' style={{ width: '140px'}}>
            {updateData.taxTag}
          </div>

          <div className='font-30 bold mx-18'>-</div>

          <div className='flex-1'>
            <Input value={updateData.taxCode} readOnly/>
          </div>
        </div>
      </>



      <>
        <div className='bold font-18 mt-32 mb-10'>
          유/무료 정책 <span className='c-red'>*</span>

        </div>
        <div className='' style={{ width: '180px'}}>
          {updateData.isPaid ? '유료' : '무료'}
        </div>
      </>



      <>
        <div className='bold font-18 mt-32 mb-10'>
          전자책 정가 <span className='c-red'>*</span>

        </div>
        <div className='flex align-center w-full'>
          <div className='' style={{ width: '180px'}}>
            <Input value={updateData.regularPrice} readOnly/>
          </div>

          <div className='font-18 bold ml-12'>원 / 1화</div>

        </div>
      </>

      
      <div className='bold font-24 mt-68'>썸네일 설정</div>
      <div className='section_line my-24'/>

      <>
        <div className='bold font-18 mt-32 mb-24'>
          썸네일 <span className='c-red'>*</span>
        </div>

        <div className=''>
          <label htmlFor='imageUpload' className='imageUpload flex-center flex-col w-full'>
            <img className='book_cover  pointer' src={(updateData.book_cover ? formatImageUri(updateData.book_cover) : null) || book_cover_sample}/>
          </label>
        </div>
      </>


      
      <div className='bold font-24 mt-68'>독자 혜택</div>
      <div className='section_line my-24'/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          신규 독자 전용 대여권 혜택

        </div>
        <div className='' style={{ width: '180px'}}>
          {BENEFIT_OPTIONS[updateData.rentBenefit]}
        </div>
      </>
    </Container>
  )
}

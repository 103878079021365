import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchNovelEpisodeList } from '@/api/novel';

const episodeListDataState = atom({
  key: 'episodeListData',
  default: {
    list: [],
    totalPage: 0,
    totalCount: 0,
  },
});


export const pageState = atom({
  key: 'episodeListPageState',
  default: 1,
});

export const countState = atom({
  key: 'episodeListCountState',
  default: 10,
});


export const useEpisodeList = (novel_uid) => {
  const [episodeList, setEpisodeList] = useRecoilState(episodeListDataState);
  const [page, setPage] = useRecoilState(pageState);
  const [count, setCount] = useRecoilState(countState);

  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const countParam = searchParams.get('count');
    const pageParam = searchParams.get('page');
    
    if (countParam) {
      setCount(Number(countParam));
    }
    if (pageParam) {
      setPage(Number(pageParam));
    }

    const getEpisodeListData = async () => {
      try {
        const response = await fetchNovelEpisodeList(novel_uid, page, count);

        console.log(response)
        setEpisodeList(response);
      } catch (error) {
        console.error('Failed to fetch novel episode list:', error);
      } finally {
        setIsLoading(false);
      }
    }


    if(novel_uid) {
      getEpisodeListData();
    }


  }, [searchParams, page, count, setCount, setPage, setEpisodeList])

  

  const handlePage = (pageValue) => {
    setPage(pageValue);
    setSearchParams('page', pageValue)
  }
  
  const handleCount = (countValue) => {
    setCount(countValue);
    setSearchParams('count', countValue)
  };



  return {
    isLoading,
    page,
    count,
    handlePage,
    handleCount,
    episodeList
  }
}
import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import {useSearchParams } from 'react-router-dom';
import { fetchuserCoinList } from '@/api/coin';

const userCoinListDataState = atom({
  key: 'userCoinListData',
  default: {
    list: [],
    totalPage: 0,
    totalCount: 0,
  },
});


export const pageState = atom({
  key: 'userCoinListPageState',
  default: 1,
});

export const countState = atom({
  key: 'userCoinListCountState',
  default: 10,
});


export const useUserCoinList = (uid) => {
  const [userCoinList, setuserCoinList] = useRecoilState(userCoinListDataState);
  const [page, setPage] = useRecoilState(pageState);
  const [count, setCount] = useRecoilState(countState);

  const [isLoading, setIsLoading] = useState(false);
  
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePage = (pageValue) => {
    setPage(pageValue);
    setSearchParams((prev) => {
      prev.set('page', pageValue);
      return prev;
    })
  }
  
  const handleCount = (countValue) => {
    setCount(countValue);
    setSearchParams((prev) => {
      prev.set('count', countValue);
      return prev;
    })
    handlePage(1);
  };

  useEffect(() => {
    const countParam = searchParams.get('count');
    const pageParam = searchParams.get('page');
    
    if (countParam) {
      setCount(Number(countParam));
    }
    if (pageParam) {
      setPage(Number(pageParam));
    }

    const getuserCoinListData = async () => {
      try {
        const response = await fetchuserCoinList(page, count, uid);
        setuserCoinList({
          list: response.list,
          totalPage: response.totalPage,
          totalCount: response.totalCount,
        });
      } catch (error) {
        console.log({error})
        console.error('Failed to fetch userOdi list:', error);
      } finally {
        setIsLoading(false);
      }
    }


    getuserCoinListData();


  }, [searchParams, setCount, setPage, setuserCoinList])

  return {
    isLoading,
    userCoinList,
    page,
    count,
    handlePage,
    handleCount,
  }
}
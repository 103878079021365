import { Button, TabBar } from "@/components";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import PurchasedProduct from "@/components/MemberManageSections/PurchasedProduct";
import PaymentInfo from "@/components/MemberManageSections/PaymentInfo";
import { useEffect, useState } from "react";
import styled from "styled-components";
import BlockHistory from "@/components/MemberManageSections/BlockHistory";
import { useNavigate, useParams } from "react-router-dom";
import { fetchUserDetail } from "@/api/user";
import PolicyHistory from "@/components/MemberManageSections/PolicyHistory";
import FixHistory from "@/components/MemberManageSections/FixHistory";

const TAB_CONFIG = ["결제정보", "구매작품", "약관동의", "정보 수정 이력"];

const Container = styled.div`
  padding: 60px 100px;
`;

const UserInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 100px;
`;

const Name = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

const InfoBox = styled.div`
  display: flex;
  gap: 20px;
  font-size: 21px;
  font-weight: bold;

  .content {
    color: #636363;
    font-weight: normal;
  }
`;

function Page() {
  const navigate = useNavigate();
  const { uid } = useParams();
  const [userDetail, setUserDetail] = useState();
  const [tab, setTab] = useState(TAB_CONFIG[0]);

  useEffect(() => {
    if(!uid) return navigate(-1);

    (async () => {
      const data = await fetchUserDetail(uid);
      setUserDetail(data);
    })();
  }, [uid]);
  if(!userDetail) return null;

  return (
    <Container>
      <ButtonTitle backButton title={userDetail?.name} containerSytle={{ marginBottom: '85px' }} />
      
      <UserInformationContainer>
        <div className="flex align-center gap-100">
          <Name>{userDetail.name}</Name>
          {/* [TODO] */}
          <Button width={140}>정지</Button>
        </div>
        <InfoBox>
          <div>닉네임</div>
          <div className="content">{userDetail.nickname}</div>
        </InfoBox>
        <InfoBox>
          <div>이메일</div>
          <div className="content">{userDetail.email}</div>
        </InfoBox>
        <InfoBox>
          <div>전화번호</div>
          <div className="content">{userDetail.phone}</div>
        </InfoBox>
        <InfoBox>
          <div>생년월일</div>
          <div className="content">{userDetail?.birth ? userDetail?.birth?.split('T')[0] : ''}</div>
        </InfoBox>
      </UserInformationContainer>

      <div className="flex mb-50 justify-between">
        <TabBar tabArr={TAB_CONFIG} currentTab={tab} clickTab={setTab} />
      </div>

      {tab === "결제정보" && <PaymentInfo />}
      {/* {tab === "댓글목록" && <div>댓글목록</div>} */}
      {tab === "구매작품" &&  <PurchasedProduct />}
      {/* {tab === "정지 이력" && <BlockHistory />} */} 
      {tab === "약관동의" && <PolicyHistory
        agreedToServicePolicy={userDetail.agreedToServicePolicy}
        agreedToPrivacyPolicy={userDetail.agreedToPrivacyPolicy}
      />}
      {tab === "정보 수정 이력" && <FixHistory/>}      
    </Container>
  )
}

export default Page;

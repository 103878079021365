import styled from "styled-components";
import { Button, DivideBox, Dropdown, Pagination, Table } from "..";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useCpNovelList } from "@/stores/cpNovelList";
import { formatDateYMD, formatImageUri } from "@/utils/format";
import { downloadCSV } from "@/utils/csv";

const HEADERS = [
  { name: "썸네일", minWidth: 110 },
  { name: "작품명", minWidth: 220 },
  { name: "장르", minWidth: 110 },
  { name: "판매정책", minWidth: 80 },
  { name: "연재상태", minWidth: 80 },
  { name: "회차", minWidth: 80 },
  { name: "작가명", minWidth: 110 },
  { name: "CP사", minWidth: 110 },
  { name: "ISBN", minWidth: 110 },
  { name: "게시일", minWidth: 110 },
];

const countOptions = [
  { name: "10개", value: 10 },
  { name: "20개", value: 20 },
  { name: "30개", value: 30 },
];

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
`

function ContentList({ cpInfo }) {
  const { uid } = useParams();
  const navigate = useNavigate();
  const { cpNovelList, page, count, handlePage, handleCount } = useCpNovelList(uid);


  
  function downloadCSVButton() {
    const headers = HEADERS.map(item => item.name);
    const dataList = cpNovelList?.list?.map(data => {
      return [
        formatImageUri(data.book_cover),
        data?.isBook ? "[단행본] " + data.title : data.title,
        data?.genre?.name,
        data.isPaid ? '유료' : '무료',
        data.hasCompletion ? "완결" : "연재중", 
        data?.episodeCount ?? 0,
        data?.writer_list?.map(item => item.name).join(", "),
        data.owner?.name,
        data.taxTag + '-' + data.taxCode,
        formatDateYMD(data.created_date),
      ]
    })

    downloadCSV(headers, dataList, cpInfo?.name + ' 작품 목록')
  }
  


  return (
    <div>
      <Title>전체 {cpNovelList.totalCount}개</Title>
      

      <Table
        id="novel-list"
        className="mb-60"
        header={HEADERS}
        datas={cpNovelList.list.map((data) => {
          return {
            id: data._id,
            renders: [
              { id: "thumbnail", render: () => <img src={formatImageUri(data.book_cover)} alt="thumbnail" style={{ width: 82, height: 118 }} draggable={false} /> },
              { id: "title", render: data?.isBook ? "[단행본] " + data.title : data.title },
              { id: "genre", render: data?.genre?.name },
              { id: "isPaid", render: data.isPaid ? '유료' : '무료' },
              { id: "serialStatus", render: data.hasCompletion ? "완결" : "연재중" }, // [TODO] 추가 필요
              { id: "episode", render: data?.episodeCount ?? 0 },
              { id: "author", render: data.writer_list?.map(item => item.name).join(", ") },
              { id: "author", render: data.owner?.name },
              { id: "isbn", render: data.taxTag + '-' + data.taxCode },
              { id: "date", render: formatDateYMD(data.created_date) },
            ],
            onClick: () => {
              navigate(`/content/list/detail/${data.uuid}`);
            }
          }
        })}
      />

      <div className="flex align-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={countOptions}
          dkey="pageSize"
          select={countOptions.find((option) => option.value === count)}
          setSelect={({ value }) => handleCount(value)}
        />

        <Pagination
          currentPage={page}
          totalPage={cpNovelList.totalPage}
          onClickPage={handlePage}
        />

        <Button style={{ marginLeft: 'auto' }} width={245} onClick={() => {
          downloadCSVButton();
        }}>CSV 다운로드</Button>
      </div>
    </div>
  );
}

export default ContentList;

import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Navigate, useLocation } from 'react-router-dom';
import SideBar from './SideBar';


const AuthWhiteList = ['/login']

const Wrapper = ({children}) => {
  const isAuthenticated = localStorage.getItem('sua_access_token');
  const location = useLocation();
  const authentication = !AuthWhiteList.includes(location.pathname)


  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  if(authentication) {
    // 인증이 반드시 필요한 페이지
    return isAuthenticated === null ? <Navigate to='/login'/> : children;  
  } else {
    // 인증이 반드시 필요 없는 페이지
    return isAuthenticated === null ? children : <Navigate to="/"/>;
  }
} 


const Container = styled.div`
`

const InnerContainer = styled.div`
  min-width: 100%;
  display: flex;
  background: #f7f7f7;
  min-height: 100vh;
  padding-left: 320px;
  overflow-x: auto;
  .main_section {
    width: 100%;
  }
`


const NotAuthContainer = styled.div`
  width: 100%;
  display: flex;
  background: #f7f7f7;
  min-height: 100vh;

  .main_section {
    width: 100%;
  }
`


function LayoutContainer({ children }) {
  
  const isAuthenticated = localStorage.getItem('sua_data');

  return (
    <>
      {/* 로그인 시 나오는 레이아웃 */}
      {isAuthenticated && 
        <Container id="#wrap" className='flex-center'>
          <Wrapper>
            <SideBar/>

            <InnerContainer id='inner_container'>

              <div className='main_section flex-1'>
                {children}
              </div>
            </InnerContainer>

          </Wrapper>
        </Container>
      }
      
      {/* 로그인 없을 시 나오는 레이아웃 */}
      {!isAuthenticated && 
        <Container id="#wrap" className='flex-center'>
          <NotAuthContainer id='inner_container'>

            <div className='main_section flex-1'>
              {children}
            </div>
          </NotAuthContainer>

        </Container>
      }
    </>
  );
}

export default LayoutContainer;

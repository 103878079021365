import { axiosAuth } from "@/lib/axios";



// 검색어 랭킹 ( 통계 )
export const fetchGetTopSearchesByType = async (
  startDate,
  endDate, 
  topN
) => {
  const { data } = await axiosAuth.get(`/search/admin/rank/${startDate}/${endDate}/${topN}`);
  
  return data;
}

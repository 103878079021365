import { fetchPromotionList } from "@/api/promotion";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { atom, useRecoilState } from "recoil";

const promotionListState = atom({
  key: 'PromotionList',
  default: {
    list: [], // 리스트 데이터
    totalPage: 0, // 총 페이지 수
    totalCount: 0, // 총 갯수
  },
});

export const pageState = atom({
  key: 'promotionPageState',
  default: 1,
});

export const countState = atom({
  key: 'promotionCountState',
  default: 10,
});


export const usePromotionList = (promotion_type) => {
  const [promotionList, setPromotionList] = useRecoilState(promotionListState);
  const [page, setPage] = useRecoilState(pageState);
  const [count, setCount] = useRecoilState(countState);

  const [isLoading, setIsLoading] = useState(false);


  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const countParam = searchParams.get('count');
    const pageParam = searchParams.get('page');
    const search = searchParams.get('search');
    const type = searchParams.get('type');

    if (countParam) {
      setCount(Number(countParam));
    }
    if (pageParam) {
      setPage(Number(pageParam));
    }

    
    const getList = async (page, count) => {
      if (isLoading) return;
      setIsLoading(true);
      try {
        const data = await fetchPromotionList(page, count, search, type, promotion_type);
        setPromotionList(data);
      } catch (error) {
        console.error('Failed to fetch promotion list:', error);
      } finally {
        setIsLoading(false);
      }
    };


    getList(page, count || 10);
  }, [searchParams, page, count, setCount, setPage, setPromotionList]);


  const handlePage = (pageValue) => {
    setPage(pageValue);
    setSearchParams((prev) => {
      prev.set('page', pageValue);
      return prev;
    })
  }
  
  const handleCount = (countValue) => {
    setCount(countValue);
    setSearchParams((prev) => {
      prev.set('count', countValue);
      return prev;
    })
    handlePage(1);
  };


  return {
    promotionList,
    isLoading,
    page,
    count,
    handlePage,
    handleCount,
  }
}
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { BarChart } from "..";
import styled from "styled-components";

// 월 매출 샘플 데이터
const MONTHLY_SALES_DATA = [
  {
    month: '1월',
    sales: 4000,
  },
  {
    month: '2월',
    sales: 3000,
  },
  {
    month: '3월',
    sales: 2000,
  },
  {
    month: '4월',
    sales: 2780,
  },
  {
    month: '5월',
    sales: 1890,
  },
  {
    month: '6월',
    sales: 2390,
  },
  {
    month: '7월',
    sales: 3490,
  },
  {
    month: '8월',
    sales: 4000,
  },
  {
    month: '9월',
    sales: 3000,
  },
  {
    month: '10월',
    sales: 2000,
  },
  {
    month: '11월',
    sales: 2780,
  },
  {
    month: '12월',
    sales: 1890,
  },
];

const Card = styled.div`
  width:690px;
  background-color:#fff;
  box-shadow: 0.5px 7px 24px 0 rgba(0, 0, 0, 0.04);
  padding: 18px 30px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const SubTitle = styled.div`
  font-size: 15px;
`

function MonthySalesStatistics({ list }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <Card>
      <div className="flex">
        <Title>월 매출 통계 [스타 사용량]</Title>
        <SubTitle className='mt-4 ml-10'>(12개월)</SubTitle>
      </div>
      {
        isLoading
        ? 
          (
            <ContentLoader
              speed={2}
              width={"100%"}
              height={172}
              viewBox="0 0 600 176"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="5" y="35" rx="0" ry="0" width="40" height="140" />
              <rect x="55" y="55" rx="0" ry="0" width="40" height="120" />
              <rect x="105" y="75" rx="0" ry="0" width="40" height="100" />
              <rect x="155" y="15" rx="0" ry="0" width="40" height="160" />
              <rect x="205" y="75" rx="0" ry="0" width="40" height="100" />
              <rect x="255" y="155" rx="0" ry="0" width="40" height="20" />
              <rect x="305" y="55" rx="0" ry="0" width="40" height="120" />
              <rect x="355" y="15" rx="0" ry="0" width="40" height="160" />   
              <rect x="405" y="55" rx="0" ry="0" width="40" height="120" />    
              <rect x="455" y="135" rx="0" ry="0" width="40" height="40" />
              <rect x="505" y="95" rx="0" ry="0" width="40" height="80" />
              <rect x="555" y="75" rx="0" ry="0" width="40" height="100" />
            </ContentLoader>
          )
        : 
          (
            <BarChart data={list} select={["sales"]} margin={{ top: 20 }} height={176} xAxis={{ dataKey: 'month' }} yAxis />
          )
      }
    </Card>
  )
}

export default MonthySalesStatistics;

import styled from 'styled-components';
import DivideBox from '../DivideBox';
import { Button, Dropdown, Table, Pagination } from '..';
import { Colors } from 'chart.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatBits, formatDateYMD } from '@/utils/format';
import { useEffect, useState } from 'react';
import useEpubUpoadModal from '../EpubUploadModal/useEpubUploadModal';
import useStartSaleModal from '../StartSaleModal/useStartSaleModal';
import useEpubUpdateModal from '../EpubUpdateModal/useEpubUpdateModal';
import { fetchApproveEpisode, fetchEpisodeUnActivate, fetchRejectEpisode } from '@/api/episode';
import { getDecisionByEpisodeId } from '@/api/decision';
import { fetchStalierEpisodeUnActive } from '@/api/stalier';
import StalierPreview from '../StalierPreview';

const BoldText = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  color: #000;
  padding: 0px 0px 23px 0px;
`

const WorkInfo=styled.div`
  font-size: 21px;
  letter-spacing: 0.42px;
  text-align: left;
  color: #636363;
`

const WorkInfoTitle = styled.div`
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0.42px;
  text-align: left;
  color: #000;
`




function StalierInfoTab({ 
  novelData,
  summary,
  infoHEADERS,
  datas, 
  getEpisodeList
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const navigate = useNavigate();

  const [, setSearchParams] = useSearchParams();
  const [previewSelect, setpreviewSelect] = useState(null)

  return (<div>
    <div>
      <BoldText>기본정보</BoldText>
      </div>
      {/* <DivideBox infoArr={infoArr}/> */}
      
      <div style={{ padding: '37px 20px' }}>
        <WorkInfoTitle className ='mb-34'>작품소개</WorkInfoTitle>
        <WorkInfo className='mb-100 pre-line'>{summary}</WorkInfo>
      </div>

      <BoldText>회차</BoldText>
      <hr className='mb-30' style={{ borderTop: '2px solid #000' ,backgroundColor:'#e7e6e7'}} />


      <Table
        selected={selected}
        setSelected={setSelected}
        className='mb-30'
        headerAlign="start"
        bodyAlign="start"
        tableStyle={{ backgroundColor: '#fff', boxShadow:'0.5px 7px 24px 0 rgba(0, 0, 0, 0.04)', borderRadius: '20px'}}
        id="info-episode-list"
        header={infoHEADERS}  
        datas={datas?.map((data) => ({
          id: data._id,
          renders: [
            { render: () => data.episode_index },
            { render: () => data.title },
            { render: () => formatDateYMD(data.created_date)},
            { render: () => data.isActive ? "활성화" : "비활성화" },
            { render: () => <div className='underline bold pointer' onClick={() => {
              setpreviewSelect({
                content: data.content
              })
            }}>미리보기</div> },
          ]}
        ))}
      />

      <StalierPreview
        isOpen={!!previewSelect}
        onClose={() => setpreviewSelect(null)}
        content={previewSelect?.content}
      />

      <div className='flex justify-end mb-38 mt-40'>
        <Button width={140} height={45} background={'red'} onClick={async () => {
          if (isLoading) return;
          setIsLoading(true);

          try {
            if (!confirm('정말 변경 하시겠습니까?')) return;
            if (selected.length === 0) throw new Error('선택된 회차가 없습니다.');
            const fetchArr = selected.map(async (item) => {
              await fetchStalierEpisodeUnActive(item);
            });

            const result = await Promise.allSettled(fetchArr);

            const filterReject = result?.filter(item => item.status === 'rejected');

            if(filterReject.length > 0) throw new Error(`변경에 실패한 회차가 있습니다. (${filterReject.length}개)`);

            alert('변경에 성공했습니다.');
            getEpisodeList();

            setSearchParams((prev) => {
              prev.set('timestamp', Date.now());
              return prev;
            });
          } catch (e) {
            alert(e.message);
          } finally {
            setIsLoading(false);
          }
        }}>활성 상태 반전</Button>
      </div> 
    </div>

  );
}

export default StalierInfoTab;


import { Button, Dropdown, Table, Pagination, SearchBar } from "@/components";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { formatDateYMD } from "@/utils/format";
import { downloadCSV } from "@/utils/csv";

function formatDateYM(updatedAt) {
  return formatDateYMD(updatedAt).slice(0, 7);
}
const HEADERS = [
  { name: "게시일", minWidth: 130 },
  { name: "프로모션 명", minWidth: 180 },
  { name: "작가", minWidth: 100 },
  { name: "대상 스타 상품", minWidth: 130 },
  { name: "할인율", minWidth: 150 },
  { name: "시작일", minWidth: 130 },
  { name: "종료일", minWidth: 130 },
  { name: "비고", minWidth: 300 },
];

const Container = styled.div`
  padding: 60px 100px;
`;

const SearchContainer = styled.div`
  margin-bottom: 50px;
`;

const SearchBtnContainer = styled.div`
  display: flex;
  gap: 7px;
`;

const TableTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 26px;
`;


export default function Promotion() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // 여기에 현재 뭐가 들어올 수 있는 거지?
  const [searchOptions, setSearchOptions] = useState(
    "기간"
  );

  const [pagination, setPagination] = useState({
    page: searchParams.get("page") || 1,
    pageSize: searchParams.get("pageSize") || 10,
    total: 100,
  });

  const novelList = {

    list: [
      { "updatedAt" : "20240705", "title": "미래기술로 무림정벌", "writer": "신승일", "oddProduct": "300개", "promotion": "10%", "startAt": "2024.07.01", "endAt": "2024.07.02", "note": ""}
    ],
    totalPage: 12,
    totalCount: 100,
  };

  const [page, handlePage] = useState(1);

  function downloadCSVButton() {
    const headers = HEADERS.map(item => item.name);
    const dataList = novelList?.list?.map(data => {
      return [
        formatDateYMD(data.updatedAt),
        data.title,
        data.writer,
        data.oddProduct,
        data.promotion,
        data.startAt,
        data.endAt,
        data.note,
      ]
    })
    downloadCSV(headers, dataList, '재화 프로모션 관리')
  }
  
  return(
    <Container>
      <ButtonTitle
        title='스타 프로모션 관리'
        containerSytle={{ marginBottom: 38 }}
      />

      <SearchContainer>
        <SearchBar
          options={[{ name: "기간" }]}
          dKey="searchCategory"
          label={searchOptions}
          setSelect={({ name: selected }) => setSearchOptions(selected)}
          dropdownContainerStyle={{ width: "80px" }}
          searchBarContainerStyle={{ width: "300px"}}
        />

        <SearchBtnContainer>
          <Button width={139} onClick={() => {
            console.log(searchOptions);
          }}>조회하기</Button>

          <Button width={139} onClick={() => {
            downloadCSVButton();
          }}>csv 다운</Button>
        </SearchBtnContainer>

      </SearchContainer>

      <TableTitle>전체 {novelList.totalCount}개</TableTitle>


      <Table
        id="novel-list"
        className="mb-60"
        header={HEADERS}
        datas={novelList.list?.map((data) => {
          return {
            id: data?._id,
            renders: [
              { id: "updatedAt", render: <div className="exbold">{formatDateYMD(data.updatedAt)}</div> },
              { id: "title", render: data?.title },
              { id: "writer", render: data?.writer },
              { id: "oddProduct", render: data?.oddProduct },
              { id: "promotion", render: data?.promotion },
              { id: "startAt", render: data?.startAt },
              { id: "endAt", render: data?.endAt },
              { id: "note", render: data?.note },
            ],
            onClick: () => {
              navigate(`/content/novel/detail/${data.uuid}`);
            }
          }
        })}
      />

      <div className="flex align-center mb-22">
        <Button style={{ marginLeft: 'auto' }} width={139} onClick={() => {
          console.log("프로모션 설정");
        }}>프로모션 설정</Button>
      </div>

      <div className="flex align-center justify-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]}
          label={`${pagination.pageSize}개`}
          dkey="pageSize"
          setSelect={({ value: selected }) => {
            setPagination((prev) => ({ ...prev, pageSize: selected }));
            setSearchParams((prev) => {
              prev.set("pageSize", selected);
              return prev;
            });
          }}
        />

        <Pagination
          currentPage={page}
          totalPage={novelList.totalPage}
          onClickPage={(page) => {
            handlePage(page);
          }}
        />
      </div>

    </Container>
  )







}

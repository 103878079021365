import axios, { axiosAuth } from "@/lib/axios";


export const fetchStalierList = async (page, count) => {
  const { data } = await axiosAuth.get(`/stalier/admin/list/${page}/${count}`);
  return data;
}

export const fetchStalierDetailData = async (uuid) => {
  const { data } = await axiosAuth.get(`/stalier/admin/detail/${uuid}`);
  return data;
}

export const fetchStalierUpdateActive = async (uuid, value) => {
  try {
    const { data } = await axiosAuth.put(`/stalier/admin/isActive`, {
      uuid: uuid,
      isActive: value
    });

    alert('상태가 변경되었습니다.')
  
    return data;
  } catch {
    alert('상태 변경에 실패했습니다.')
  }
}


export const fetchStalierEpisodeList = async (uid) => {
  const { data } = await axiosAuth.get(`/stalierepisode/admin/list/${uid}`);
  return data;
}


export const fetchStalierEpisodeUnActive = async (uid) => {
  const { data } = await axiosAuth.put(`/stalierepisode/admin/isActive`, {
    episode_id: uid, 
  });
  return data;
}

import styled from 'styled-components'
import { SampleNovel } from '@/static/SampleNovel';
import { ic_page_prev } from '@/assets';
import { useEffect, useState } from 'react';
import { Button, TabBar } from '@/components';

import WorkPolicy from '@/components/NovelDetailSection/workPolicy';
import OddPolicy from '@/components/NovelDetailSection/oddPolicy';
import FixLog from '@/components/NovelDetailSection/fixLog';
import InfoTab from '@/components/NovelDetailSection/InfoTab';
import { useNavigate, useParams } from 'react-router-dom';
import ApprovalLog from '@/components/NovelDetailSection/approvalLog';
import { fetchNovelDetailData, fetchNovelUnActivate } from '@/api/novel';
import { formatDateYMD, formatImageUri } from '@/utils/format';
import { useNovelData } from '@/stores/novelData';
import { useEpisodeList } from '@/stores/episodeList';
import PromotionLog from '@/components/NovelDetailSection/PromotionLog';



const Container = styled.div`
  padding: 60px;
  padding-bottom: 160px;
`;


 
const HeadContainer = styled.div`
  margin-bottom: 100px;

`



const Title = styled.div`
  margin-left: 20px;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-align: left;
  color: #000;

`

const NovelInfo = styled.div`
  display: flex;
  gap: 20px;
`

const NovelInfoTitle = styled.div`
  font-family: Pretendard;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: #000;
`



const NovelInfoSubBold = styled.div`
  font-family: Pretendard;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0.42px;
  text-align: left;
  color: #000;
`

const NovelInfoSub = styled.div`
  font-family: Pretendard;
  font-size: 21px;
  letter-spacing: 0.42px;
  text-align: left;
  color: #636363;
  margin-left: 20px;
  display: flex;
`


const tabArr=[
  '정보',
  '작품정책',
  '스타정책',
  '승인이력',
  "프로모션 이력"
];



const infoHEADERS=[
  {name : '순서', minWidth : 120 },
  {name : '제목', minWidth : 250 },
  {name : '유/무료', minWidth : 120 },
  {name : '가격', minWidth : 70 },
  {name : '버전', minWidth : 120 },
  {name : '판매상태', minWidth : 120 },
  {name : '발행일', minWidth : 120 },
  {name : '용량', minWidth : 120 },
  {name : '승인', minWidth : 120 },
  {name : '미리보기', minWidth : 120 },
];


function NovelDetail(){
  const { uid } = useParams();
  
  const [currentTab, clickTab] = useState('정보');
  const [detail, setDetail] = useState(null);

  

  
  const [defaultInfo, setDefaultInfo] = useState([]);
  const [workPolicyArr, setWorkPolicyArr] = useState([]);
  const [oddPolicyArr, setOddPolicyArr] = useState([])


  const { 
    novelData, 
    novelDecisionList, 
    novelDecisionEditList,
    novelDetailCoinPromotionList,
    novelDetailExposureExPromotionList,
  } = useNovelData(uid);

  const { episodeList, page, count, handleCount, handlePage } = useEpisodeList(uid);
    
  useEffect(() => {
    // 유효성 검사
    if(novelData?._id) getData();
  }, [novelData])

  async function getData() {
    const result = novelData;
    setDetail(novelData)

    setDefaultInfo([
      [
        {name: '장르', content: result.genre.name}, 
        {name: '판매상태', content: '판매중'},  // [TODO] 수정 필요
        {name: '연령등급', content: result.ageGrade}, 
        {name: '연재상태', content: '연재중'}, // [TODO] 수정 필요
        {name: '면세정보/ISBN', content: result.taxType + ' / ' + result.taxTag + ":" + result.taxCode }
      ],
      [
        {name: '정가', content: `${result.regularPrice}원/회차`}, 
        {name: '게시일', content: formatDateYMD(result.created_date)}, 
        {name: '발행자', content: result?.owner?.name ?? "스텔미디어"}, 
        {name: '레이블', content: result.cpLabel.name},
        ]
    ])

    // [TODO] 프로모션 연동 시 데이터 변경 필요
    setWorkPolicyArr([
      [
        {name : 'BM', content : result.isPaid ? "유료" : "무료"},
        {name : '이벤트 적용 불가 회차', content : `최신 ${result.non_rental_episode_count}편`},
        {name : '오디오북 제공 여부', content : result.isAudioBook ? "제공" : "미제공"}
      ],
    ])

    setOddPolicyArr([
      [
        {name : '소장', content: `${result.regularPrice}원 / 회차`}, 
        {name : '대여', content : result.rentBenefit === "없음" ? "없음" : '프로모션 대여권 / 신규 독자 전용 한정'}
      ],
      [
        {name : '신규 독자 전용', content : result.rentBenefit}
      ]
    ])
  }



  const navigate = useNavigate();

  
  async function submitActive() {
    const confirm = window.confirm('정말 비활성화 하시겠습니까?');

    if(confirm) {
      await fetchNovelUnActivate(uid);
      alert('소설을 비활성화했습니다.')
    }
  }

  return (
    <Container>
      <HeadContainer className='flex align-center'>
        <img src={ic_page_prev} className="pointer" alt='back' style={{ width: '34px',height : '23px' }} onClick={() => {
          navigate(-1);
        }}/>
        <Title>{detail?.isBook ? "[단행본] " + detail?.title : detail?.title}</Title>
      </HeadContainer>

      <div className='ml-36'>
        {detail?.isOwnAdmin && <div className='bold font-30 mb-16'>어드민 소유 작품</div>}
        
        <NovelInfo className ='mb-90'>
          <img src={formatImageUri(detail?.book_cover)} alt='novel' style={{ width: '206px', height: '296px' }} />
          <div>
            <div className='flex mb-20'>
              <NovelInfoTitle></NovelInfoTitle>
              <Button width={139} height={45} style={{marginLeft:'20px'}} to={`/content/novel/edit/${uid}`}>
                수정
              </Button>
              <Button background={'red'} width={139} height={45} style={{marginLeft:'20px'}} onClick={submitActive}>
                비활성화
              </Button>
            </div>
            <NovelInfoTitle className='mb-30'>{detail?.isBook ? "[단행본] " + detail?.title : detail?.title}</NovelInfoTitle>
            <div className='flex mb-22'>
              <NovelInfoSubBold>작가</NovelInfoSubBold>
              <NovelInfoSub>
                <div className='flex'>
                  <NovelInfoSub style={{marginLeft:'5px'}}>{detail?.writer_list?.map(item => item.name).join(', ')}</NovelInfoSub>  
                </div>
              </NovelInfoSub>
            </div>
            <div className='flex mb-22'>
              <NovelInfoSubBold>연재주기</NovelInfoSubBold>
              <NovelInfoSub>
                {
                  detail?.serialCycle?.filter((item, idx) => {
                    return item
                  }).map((item, idx) => ['일','월','화','수','목','금','토'][idx]).join(', ')
                }
              </NovelInfoSub>
            </div>
            <div className='flex mb-22'>
              <NovelInfoSubBold>작품유형</NovelInfoSubBold>
              <NovelInfoSub>{detail?.salesType}</NovelInfoSub>  
            </div>
            <div className='flex'>
              <NovelInfoSubBold>키워드</NovelInfoSubBold>
              <NovelInfoSub>
                {detail?.keywords.map(item => item.name).map((keyword,index) => (
                  <div className='flex' key={index}>
                    <NovelInfoSub style ={{marginLeft:'5px'}}>#{keyword}{index !== detail?.keywords.length - 1 && ','}</NovelInfoSub>  
                  </div>
                ))}
              </NovelInfoSub>  
            </div>
          </div>
        </NovelInfo>

        <TabBar className ='mb-90' tabArr={tabArr} currentTab={currentTab} clickTab={clickTab}/>

        <div className='mb-90'></div>
      </div>
      
      <div>
        {'정보' === currentTab && (
          <div>
            <InfoTab 
              page={page}
              setPage={handlePage}
              novelData={detail}
              count={count}
              setCount={handleCount}
              summary={detail?.summary} 
              infoArr={defaultInfo} 
              infoHEADERS={infoHEADERS} 
              datas={episodeList}
            />
          </div>
        )}

        {'작품정책' === currentTab && (
          <div>
            <WorkPolicy workPolicyArr={workPolicyArr}/>
          </div>
        )}

        {'스타정책' === currentTab && (
          <div>
            <OddPolicy oddPolicyArr={oddPolicyArr}/>
          </div>
        )}
        {'승인이력' === currentTab && (
          <div>
            <ApprovalLog list={novelDecisionList}/>
          </div>
        )}

        
        {'프로모션 이력' === currentTab && (
          <div>
            <PromotionLog 
              novelDetailCoinPromotionList={novelDetailCoinPromotionList}
              novelDetailExposureExPromotionList={novelDetailExposureExPromotionList}
            />
          </div>
        )}
      </div>


    </Container>
  )
}

export default NovelDetail;
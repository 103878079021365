
import { Button, Dropdown, Table, Pagination, SearchBar } from "@/components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { formatDateYMD } from "@/utils/format";
import { useNovelDecisionList } from "@/stores/novelDecisionList";
import { downloadCSV } from "@/utils/csv";


const BoldText = styled.div`
  font-size: 25px;
  font-weight:bold;
`

const HEADERS = [
  { name: "승인 요청일", minWidth: 110 },
  { name: "승인 항목", minWidth: 100 },
  { name: "요청자", minWidth: 100 },
  { name: "대상 항목", minWidth: 180 },
  { name: "내용상세", minWidth: 250 },
  // { name: "비속어 유무", minWidth: 100 },
  // { name: "특이사항", minWidth: 100 },
  // { name: "성인 콘텐츠 유무", minWidth: 100 },
  // { name: "요청사항,비고 유무", minWidth: 100 },
  { name: "승인현황", minWidth: 100 },
  { name: "취소여부", minWidth: 100 },
];

const Container = styled.div`
  padding: 60px 100px;
`;

const SearchContainer = styled.div`
  margin-bottom: 50px;
`;

const SearchBtnContainer = styled.div`
  display: flex;
  gap: 7px;
`;

const TableTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 26px;
`;

const PaginationBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .buttons {
    position: absolute;
    right: 0;
  }
`;

const APPROVE_OPTIONS = [
  { name: "전체", value: -1 },
  { name: "대기중", value: 0 },
  { name: "승인", value: 1 },
  { name: "반려", value: 2 },
];

const SEARCH_OPTIONS = [
  { name: "작품명", value: "title" },
  // { name: "작가명", value: "writer" },
];

const COUNT_OPTIONS = [{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }];

export default function Page() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchOptions, setSearchOptions] = useState({
    search: searchParams.get("search") || "",
    type: SEARCH_OPTIONS.find((option) => option.value === searchParams.get("type")) || SEARCH_OPTIONS[0],
    approve: searchParams.get("approve") ? APPROVE_OPTIONS.find((option) => option.value === Number(searchParams.get("approve"))) : APPROVE_OPTIONS[0],
  });

  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const { novelDecisionList, submitApprove, submitReject, page, count, handlePage, handleCount, isLoading } = useNovelDecisionList();
  
  function downloadCSVButton() {
    const headers = HEADERS.map(item => item.name);
    const dataList = novelDecisionList?.list?.map(data => {
      return [
       formatDateYMD(data.created_date),
        data?.decisionType === 0 ? "작품 수정" : "작품 등록" ,
        data?.request_cp_user?.name ,
        data?.target_novel?.title ,
        data.decisionType === 0 ? '작품 정보 수정' : '작품 신규 등록',
        // { id: "slang", render: '' ? <div style={{color:'green'}}>O</div> : <div style={{color:'#ff0000'}}>X</div> ,
        // { id: "significant", render: '' ? <div style={{color:'green'}}>O</div> : <div style={{color:'#ff0000'}}>X</div> ,
        // { id: "adult", render: '' ? <div style={{color:'green'}}>O</div> : <div style={{color:'#ff0000'}}>X</div> ,
        // { id: "request", render: '' ? <div style={{color:'green'}}>O</div> : <div style={{color:'#ff0000'}}>X</div> ,
        ["대기중", "승인", "반려"][data.hasApproved] ,
        data.isCancelled ? "취소됨" : "" ,
      ]
    })
    downloadCSV(headers, dataList, '작품 승인 관리')
  }

  
  useEffect(() => {
    let filter_datas = novelDecisionList.list.filter(item => selected.includes(item._id) && !selectedData.find(it => it._id === item._id));
    let li = [...selectedData, ...filter_datas];

    let li_datas = li.filter(item => selected.includes(item._id));

    setSelectedData(li_datas)
  }, [selected])

  return (
    <Container>
      <ButtonTitle
        title='작품 승인 관리'
        containerSytle={{ marginBottom: 38 }}
      />

      <SearchContainer>
        <SearchBar
          dKey="searchCategory"
          options={SEARCH_OPTIONS}
          select={searchOptions.type}
          setSelect={(selected) => {
            setSearchOptions((prev) => ({ ...prev, type: selected }));
          }}
          search={searchOptions.search}
          onChange={(e) => {
            setSearchOptions((prev) => ({ ...prev, search: e.target.value }));
          }}
        />

        <Dropdown
          containerStyle={{width:'137px', height:'31px', marginBottom:'20px'}}
          options={APPROVE_OPTIONS}
          select={searchOptions.approve}
          setSelect={(selected) => {
            setSearchOptions((prev) => ({ ...prev, approve: selected }));
          }}
        />
        
        <SearchBtnContainer>
          <Button width={139} isLoading={isLoading} onClick={() => {
            setSearchParams((prev) => {
              prev.set("search", searchOptions.search);
              prev.set("type", searchOptions.type.value);
              prev.set("approve", searchOptions.approve.value);
              prev.set("page", 1);
              return prev;
            })
          }}>조회하기</Button>

          <Button width={139} onClick={() => {
            downloadCSVButton();
          }}>csv 다운</Button>
        </SearchBtnContainer>

      </SearchContainer>

      <TableTitle>전체 {novelDecisionList?.totalCount}개</TableTitle>


      <Table
        selected={selected}
        setSelected={setSelected}
        id="novel-list"
        className="mb-60"
        header={HEADERS}
        datas={novelDecisionList?.list?.map((data, index) => {
          console.log(data);
          return {
            id: data._id,
            renders: [
              { id: "updatedAt", render: <div className="exbold">{formatDateYMD(data.created_date)}</div> },
              { id: "approve", render: data?.decisionType === 0 ? "작품 수정" : "작품 등록" },
              { id: "requestor", render: data?.request_cp_user?.name },
              { id: "targetItem", render: data?.target_novel?.title },
              { id: "itemDetail", render: <div className="bold underline pointer" onClick={() => navigate(`/cp/approval/novel/detail/${data._id}`)}>{data.decisionType === 0 ? '작품 정보 수정' : '작품 신규 등록' }</div>},
              // { id: "slang", render: '' ? <div style={{color:'green'}}>O</div> : <div style={{color:'#ff0000'}}>X</div> },
              // { id: "significant", render: '' ? <div style={{color:'green'}}>O</div> : <div style={{color:'#ff0000'}}>X</div> },
              // { id: "adult", render: '' ? <div style={{color:'green'}}>O</div> : <div style={{color:'#ff0000'}}>X</div> },
              // { id: "request", render: '' ? <div style={{color:'green'}}>O</div> : <div style={{color:'#ff0000'}}>X</div> },
              { id: "approval", render: ["대기중", "승인", "반려"][data.hasApproved] },
              { id: "isCancelled", render: data.isCancelled ? "취소됨" : "" },
            ],
          }
        })}
      />

      <PaginationBox>
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={COUNT_OPTIONS}
          dkey="pageSize"
          select={COUNT_OPTIONS.find((option) => option.value === count)}
          setSelect={(selected) => {
            handleCount(selected.value);
          }}
        />

        <Pagination
          currentPage={page}
          totalPage={novelDecisionList.totalPage}
          onClickPage={(page) => {
            handlePage(page);
          }}
        />
        
        <div className="flex buttons">
          <Button width={140} isLoading={isLoading} onClick={() => {
            // 취소 확인
            const selected_decision = selectedData;
            if (selected_decision.find(item => item.isCancelled)) return alert('취소된 심사가 포함되어있습니다.');
            else submitReject(selected)
          }}>반려하기</Button>
          <Button className='ml-14' width={140} isLoading={isLoading} onClick={() => {
            // 취소 확인
            const selected_decision = selectedData;
            if (selected_decision.find(item => item.isCancelled)) return alert('취소된 심사가 포함되어있습니다.');
            else submitApprove(selected)
            
          }}>승인하기</Button>
        </div>
      </PaginationBox>
        



    </Container>
  );
}
import { useEffect, useState } from "react";
import { GridBox } from "..";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

// 작품 발행 현황
const CONTENT_PUBLISHING_STATUS = [
  {
    title: '심사 대기중',
    count: '13,211',
  },  
  {
    title: '반려',
    count: '13,211',
  },  
  {
    title: '판매중',
    count: '13,211',
  },
  {
    title: '판매중지',
    count: '12',
  },
];

const Card = styled.div`
  width:690px;
  background-color:#fff;
  box-shadow: 0.5px 7px 24px 0 rgba(0, 0, 0, 0.04);
  padding: 18px 30px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`

//SellTitle 글씨
const SellTitle = styled.div`
  font-size: 20.5px;
  font-weight: 500;
  letter-spacing: 0.41px;
  text-align: center;
`

const SellSubTitle = styled.div`
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.64px;
  text-align: center;
`


function PublishingStatus({ list }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <Card>
      <div className='flex'>
        <Title>플랫폼 간략 현황</Title>
      </div>
      {
        isLoading
        ? 
          (
            <ContentLoader
              speed={2}
              width={630}
              height={176}
              viewBox="0 0 630 176"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="30" rx="0" ry="0" width="130" height="150" /> 
              <rect x="170" y="30" rx="0" ry="0" width="130" height="150" />
              <rect x="340" y="30" rx="0" ry="0" width="130" height="150" />
              <rect x="510" y="30" rx="0" ry="0" width="130" height="150" />
            </ContentLoader>
          )
        :
          <GridBox col={4} gap={40} className='mt-40 pb-44'>
            {
              list.map((content, index) => (
                <div key={index} className="flex-col gap-30">
                  <SellTitle>
                    {content.title}
                  </SellTitle>
                  <SellSubTitle>
                    {content.count}
                  </SellSubTitle>
                </div>
              ))
            }
          </GridBox>
      }
    </Card>
  )
}

export default PublishingStatus;
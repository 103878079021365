import { axiosAuth } from '@/lib/axios';

export const fetchOwnLabelList = async () => {
  const { data } = await axiosAuth.get(`/label/admin/own/list`);
  return data;
}


export const fetchAdminCPLabelList = async (cp_id) => {
  const { data } = await axiosAuth.get(`/label/admin/cp/list/${cp_id}`);
  return data;
}

export const fetchLabelAdd = async (writerName) => {
  try {
    await axiosAuth.post(`/label/admin`, { name: writerName });
    return true;
  } catch (e) {
    alert('작가 추가에 실패했습니다.');
    return false;
  }
}
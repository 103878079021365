import AWS from '@/lib/AWS'


const S3ImageUpload = async (file, filename, path) => {
  const s3 = new AWS.S3();
  
  if (!file) {
    return;
  }
  
  
  try {
    const params = { 
      Bucket: import.meta.env.VITE_AWS_S3_IMAGE_BUCKET_ORIGIN, 
      Key: `${path}/${filename}`, 
      Body: file
    };
  
    await s3.upload(params).promise();
  
    return `${path}/${filename}`
    
  } catch (err) {
    throw err
  }
}




const S3EpubUpload = async (file, filename, path) => {
  const s3 = new AWS.S3();
  
  if (!file) {
    return;
  }
  
  
  try {
    const params = { 
      Bucket: import.meta.env.VITE_AWS_S3_EPUB_BUCKET_ORIGIN, 
      Key: `${path}/${filename}`, 
      Body: file
    };
  
    await s3.upload(params).promise();
  
    return `${path}/${filename}`
    
  } catch (err) {
    throw err
  }
}

export { S3ImageUpload, S3EpubUpload };
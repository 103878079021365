import { fetchCpDetail, fetchNewCPData, fetchUpdateCPData } from '@/api/cp';
import { fetchFileToUri } from '@/api/file';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { atom, useRecoilState, useResetRecoilState } from 'recoil';

const ContractTypeList = [
  { name: "일반", value: "일반" }
]


const BankList = [
  { "name": "한국은행", "value": "한국은행" },
  { "name": "산업은행", "value": "산업은행" },
  { "name": "기업은행", "value": "기업은행" },
  { "name": "국민은행", "value": "국민은행" },
  { "name": "외환은행", "value": "외환은행" },
  { "name": "국민은행", "value": "국민은행" },
  { "name": "수협중앙회", "value": "수협중앙회" },
  { "name": "수출입은행", "value": "수출입은행" },
  { "name": "수협중앙회", "value": "수협중앙회" },
  { "name": "농협은행", "value": "농협은행" },
  { "name": "농협중앙회", "value": "농협중앙회" },
  { "name": "우리은행", "value": "우리은행" },
  { "name": "제일은행", "value": "제일은행" },
  { "name": "씨티은행", "value": "씨티은행" },
  { "name": "대구은행", "value": "대구은행" },
  { "name": "부산은행", "value": "부산은행" },
  { "name": "광주은행", "value": "광주은행" },
  { "name": "제주은행", "value": "제주은행" },
  { "name": "전북은행", "value": "전북은행" },
  { "name": "경남은행", "value": "경남은행" },
  { "name": "새마을금고중앙회", "value": "새마을금고중앙회" },
  { "name": "신협중앙회", "value": "신협중앙회" },
  { "name": "상호저축은행", "value": "상호저축은행" },
  { "name": "모간스탠리은행", "value": "모간스탠리은행" },
  { "name": "HSBC은행", "value": "HSBC은행" },
  { "name": "도이치은행", "value": "도이치은행" },
  { "name": "알비에스피엘씨은행", "value": "알비에스피엘씨은행" },
  { "name": "제이피모간체이스은행", "value": "제이피모간체이스은행" },
  { "name": "미즈호은행", "value": "미즈호은행" },
  { "name": "미쓰비시도쿄UFJ은행", "value": "미쓰비시도쿄UFJ은행" },
  { "name": "BOA은행", "value": "BOA은행" },
  { "name": "비엔피파리은행", "value": "비엔피파리은행" },
  { "name": "중국공상은행", "value": "중국공상은행" },
  { "name": "중국은행", "value": "중국은행" },
  { "name": "산림조합중앙회", "value": "산림조합중앙회" },
  { "name": "대화은행", "value": "대화은행" },
  { "name": "우체국", "value": "우체국" },
  { "name": "신용보증기금", "value": "신용보증기금" },
  { "name": "기술보증기금", "value": "기술보증기금" },
  { "name": "하나은행", "value": "하나은행" },
  { "name": "신한은행", "value": "신한은행" },
  { "name": "한국정책금융공사", "value": "한국정책금융공사" },
  { "name": "한국주택금융공사", "value": "한국주택금융공사" },
  { "name": "서울보증보험", "value": "서울보증보험" },
  { "name": "경찰청", "value": "경찰청" },
  { "name": "한국전자금융(주)", "value": "한국전자금융(주)" },
  { "name": "금융결제원", "value": "금융결제원" }
]

const cpUpdateDataState = atom({
  key: 'cpUpdateData',
  default: {
    create_admin: '',
    email: '', // 이메일
  	company_email: '', // 대표 이메일
    password: '', // 비밀번호
    name: '', // 이름 - CP사 이름
    phone: '', // 전화번호 - 대표 연락처
    created_date: '', 
    bank_name: BankList[0], // 은행 이름
    bank_number: '', // 은행 - 계좌번호 
    bank_account_owner: '', // 예금주
    bank_account_image: '', // 통장 사본
    bank_account_image_file_data: null, // 통장 사본 파일
    ceo_name: '', // 대표자 명
    business_number: '', // 사업자 번호
    contractType: ContractTypeList[0], // 계약 유형
    business_certificate_file: '', // 사업자 등록증 파일
    business_certificate_file_data: null, // 사업자 등록증 파일
    settlementRatio: 70
  }
});

export const useCPUpdateData = (cp_uid) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cpUpdateData, setCPUpdateData] = useRecoilState(cpUpdateDataState);

  const navigate = useNavigate();

  useEffect(() => {
    const getCPData = async () => {
      const { cp: cpData } = await fetchCpDetail(cp_uid);

      setCPUpdateData({
        ...cpData,
        bank_name: { name: cpData.bank_name, value: cpData.bank_name },
        contractType: { name: cpData.contractType, value: cpData.contractType },
      })
    }


    if(cp_uid) {
      getCPData();
    }
  }, [])



  const handleChange = (key, value) => {
    setCPUpdateData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  async function submit() {
    if(isLoading) return;
    setIsLoading(true);

    try {
      try {
        if(cpUpdateData?.business_certificate_file_data) await fetchFileToUri(`cp/${cpUpdateData.name}`, 'business_certificate_file', cpUpdateData.business_certificate_file_data);
      } catch(e) {
        throw new Error('사업자 등록증 파일 업로드에 실패했습니다.');
      }
      try {
        if(cpUpdateData?.bank_account_image_file_data) await fetchFileToUri(`cp/${cpUpdateData.name}`, 'bank_account_image', cpUpdateData.bank_account_image_file_data);
      } catch(e) {
        throw new Error('통장 사본 파일 업로드에 실패했습니다.');
      }

      let edit_cp = { ...cpUpdateData };

      edit_cp.business_certificate_file = `cp/${cpUpdateData.name}/business_certificate_file.png`;
      edit_cp.bank_account_image = `cp/${cpUpdateData.name}/bank_account_image.png`;

      edit_cp.bank_name = cpUpdateData.bank_name.value;
      edit_cp.contractType = cpUpdateData.contractType.value;

      edit_cp.cp_id = cp_uid;

      try {
        await fetchUpdateCPData(edit_cp);
        window.location.href = '/cp/list'
      } catch(e) {
        throw new Error('CP사 수정에 실패했습니다.');
      }
    } catch(e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    cpUpdateData,
    handleChange,
    submit,
    isLoading,
    ContractTypeList,
    BankList
  }
}
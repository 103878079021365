import { fetchWriterAdd } from '@/api/writer';
import { Button, LabelInput, Modal } from '@/components'
import { axiosAuth } from '@/lib/axios';
import { useState } from 'react'
import styled from 'styled-components'


const Container = styled.div`
  .c-828282 {
    color: #828282;
    line-height: 20px;
  }
`


export default function WriterAddModal ({
  isOpen,
  setIsOpen
}) {
  const [writerName, setWriterName] = useState('');
  
  async function submit() {
    // Fetch Create Writer
    const result = await fetchWriterAdd(writerName);
    
    // 작가 생성 성공
    if(result) {
      alert('작가가 생성되었습니다.');
      setWriterName('');
      setIsOpen(false);
    } else {}
  } 

  return (
    <Container>   
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} height={400}>
        <div className='bold font-30 mb-20'>작가 추가</div>
        <div className='c-828282 medium font-14 mb-20'>
          주의사항:<br/>
          1. 작가님들의 필명은 중복될 수 없습니다.<br/>
          2. 스텔리에의 닉네임과 연동되지 않습니다.<br/>
          3. 이곳에 등록된 작가명은 cp사 페이지의 작가검색과 스텔라 서비스 cp/정식연재의 작가명으로 글검색에서만 사용됩니다.<br/>
          4. 최신작의 경우 cp사 또는 플랫폼에서 최신으로 등록한 작품으로 표기됩니다.
        </div>
        
        <LabelInput label={"작가명"} required value={writerName} onChange={e => setWriterName(e.target.value)}/>
      
        <div className='w-full flex justify-end mt-30'>
          <Button onClick={submit}>작가 등록</Button>
        </div>
      </Modal>
    </Container>
  ) 
}
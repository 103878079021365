import { Button, DivideBox, Dropdown, Input, Pagination, SearchBar, Table } from "@/components";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import NoticeDivideBox from "@/components/NoticeDivideBox";
import { useNoticeList } from "@/stores/noticeList";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";


const Container = styled.div`
  padding: 60px 100px;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 60px;
`;

const TotalCount = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const countOptions = [{ value: 10, name: "10개" }, { value: 20, name: "20개" }, { value: 30, name: "30개" }];

const targetOptions = [{ value : "USER", name: "대상: 유저"}, { value : "CP", name: "대상: CP사"}, { value : "ALL", name: "대상: 전체"}];

function Notice() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchOptions, setSearchOptions] = useState({ 
    search: "", // 검색어
    searchCategory: "제목", // 검색 카테고리
  });
  const [selected, setSelected] = useState(targetOptions[0]);
  const [checked, setChecked] = useState([]);

  function deleteNotices(){
  alert("삭제되었습니다.");
}

  const { noticeList, isLoading, page, handlePage, count, handleCount } = useNoticeList();

  return (
    <Container>
      <ButtonTitle 
        title='공지사항'
        containerSytle={{ marginBottom: 60}}
      />

      <SearchContainer>
        <SearchBar
          searchBarContainerStyle={{ width: "880px", height: "60px", margin: '0' }}
          fontSize={20}
          options={[{ name: "제목" }]}
          dKey="searchCategory"
          select={{ name: searchOptions.searchCategory }}
          setSelect={({ name: selected }) => setSearchOptions((prev) => ({ ...prev, searchCategory: selected }))}
        />
      </SearchContainer>

      <Dropdown
          containerStyle={{width:'137px', height:'31px', marginBottom:'20px'}}
          options={targetOptions}
          select={selected}
          setSelect={(item) => setSelected(item)}
        />

      <ButtonContainer style={{marginBottom:"20px"}}>
        <Button width={590} isLoading={isLoading}>검색하기</Button>
      </ButtonContainer>

      <NoticeDivideBox infoArr={noticeList.list} checked={checked} setChecked={setChecked}/>


      
      <div className="flex align-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={countOptions}
          dkey="pageSize"
          select={countOptions.find((item) => item.value === count)}
          setSelect={({ value }) => handleCount(value)}
        />
        <Pagination
          currentPage={page}
          totalPage={noticeList.totalPage}
          onClickPage={handlePage}
        />
        <Button className="ml-120" width={220} onClick={() => navigate('/etc/notice/register')}>공지사항 등록</Button>
        <Button className="ml-12" background="red" width={140} onClick={()=>{deleteNotices()}}>삭제</Button>

      </div>
    </Container>
  );
}

export default Notice;
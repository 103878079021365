import { Button, CheckBox, Dropdown, LabelInput } from '@/components'
import styled from 'styled-components'
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import ko from 'date-fns/locale/ko'; // 한국어 locale
import { fetchCreateIOSProduct, fetchCreateProduct } from '@/api/product';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import 'moment-timezone'
import { fetchCreateCoupon } from '@/api/coupon';
moment.tz.setDefault('Asia/Seoul');

registerLocale('ko', ko);

const Container = styled.div`
  padding: 60px 100px;

  .section_line {
    width: 100%;
    height: 2px;
    background-color: #000000;
  }
`


export default function Page () {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [title, setTitle] = useState('');
  const [amount, setAmount] = useState(); // [TODO] 숫자만
  const [coupon_number, set_coupon_number] = useState(''); // [TODO] 숫자만

  const [productId, setProductId] = useState('');

  const [hasEnd, setHasEnd] = useState(false);

  const handleStartDateChange = date => {
    // 선택된 날짜의 시간을 00:00으로 설정
    const adjustedDate = moment(date).startOf('day').toDate();
    console.log({date, adjustedDate});

    setStartDate(adjustedDate);
  };

  const handleEndDateChange = date => {
    // moment를 사용하여 선택된 날짜의 시간을 23:59:59으로 설정
    const adjustedDate = moment(date).endOf('day').toDate();
    setEndDate(adjustedDate);
  }

  const navigate = useNavigate();


  async function submit() {
    if (isLoading) return;
    setIsLoading(true);
    
    try {
        await fetchCreateCoupon({
          coupon_number,
          title,
          coupon_start: startDate,
          coupon_end: hasEnd ? null : endDate,
          amount,
        });
      


      navigate(-1);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }


  return (
    <Container>
      <div className='bold font-30 mb-88'>쿠폰 업로드</div>

      <div className='bold font-24'>기본 정보</div>

      <div className='section_line my-24'/>

      <LabelInput label="상품명" required value={title} onChange={e => setTitle(e.target.value)}/>
      
      <div className='mt-24'/>
      
      <LabelInput label="쿠폰 코드" required value={coupon_number} onChange={e => set_coupon_number(e.target.value)}/>

      <div  style={{width: '200px'}}>
        <div className='bold font-18 mt-32 mb-10'>
          쿠폰 시작일 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            dateFormat="yyyy-MM-dd" // 날짜 형식 지정
            locale="ko" // 한국어 locale 사용
          />
        </div>
      </div>


      

      <div style={{width: '200px'}}>
        <div className='bold font-18 mt-32 mb-10'>
          쿠폰 종료일 <span className='c-red'>*</span>
        </div>
        <CheckBox label={"종료일 미포함"} selected={hasEnd} onClick={() => setHasEnd(e => !e)}/>

        {!hasEnd && <div className='mt-12' style={{ width: '180px'}}>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            dateFormat="yyyy-MM-dd" // 날짜 형식 지정
            locale={ko} // 한국어 locale 사용
            minDate={startDate} // 시작일을 최소 날짜로 설정
          />
        </div>}
      </div>

      <div className='mt-32' style={{width: '200px'}}>
        <LabelInput label="스타 개수" required value={amount} onChange={e => setAmount(e.target.value)}/>
      </div>

      <div className='w-full mt-80 flex justify-end'>
        <Button isLoading={isLoading} onClick={submit}>판매 등록</Button>
      </div>
    </Container>
  )
}


import { getDecisionByDecisionId, getDecisionByEpisodeId } from "@/api/decision";
import { fetchApproveEpisode, fetchEpisodeUnActivate, fetchRejectEpisode } from "@/api/episode";
import { Button, Dropdown, Pagination, Table } from "@/components";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { formatBits, formatDateYMD } from "@/utils/format";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  padding: 60px 100px;
`;

const TabContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
`;

const TableTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 26px;
`;

const PaginationBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .buttons {
    position: absolute;
    right: 0;
  }
`;

const unSelectedStyle = {
  backgroundColor: "#999999",
};

const HEADERS = [
  { name: "승인 요청일", minWidth: 110 },
  { name: "승인 항목", minWidth: 100 },
  {name : '순서', minWidth : 120 },
  {name : '회차 제목', minWidth : 250 },
  {name : '유/무료', minWidth : 120 },
  {name : '가격', minWidth : 70 },
  {name : '버전', minWidth : 120 },
  {name : '판매상태', minWidth : 120 },
  {name : '발행일', minWidth : 120 },
  {name : '용량', minWidth : 120 },
  {name : '승인', minWidth : 120 },
  // {name : '미리보기', minWidth : 120 },
];

export default function Page() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState("처리 중");
  const [selected, setSelected] = useState([]);
  const [decisionData, setDecisionData] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await getDecisionByDecisionId(id);
      setDecisionData(data);
    })();
  }, [id, searchParams]);

  useEffect(() => {
    if(tab === "처리 중") {
      setList(decisionData.episodedecisionList ?? []);
    } else {
      setList(decisionData.episodedecisionCompletedList ?? []);
    }

    setSelected([]);
  }, [tab, decisionData]);

  if (!decisionData) return;


  const [previewSelect, setpreviewSelect] = useState(null)


  return (
    <Container>
      <ButtonTitle backButton to="/cp/approval/episode" title={`${decisionData.target_novel?.title} 승인 요청`} />
      <TabContainer>
        <Button style={tab === "처리 중" ? {} : unSelectedStyle} onClick={() => setTab("처리 중")}>처리 중</Button>
        <Button style={tab === "처리 완료" ? {} : unSelectedStyle} onClick={() => setTab("처리 완료")}>처리 완료</Button>
      </TabContainer>

      <TableTitle>전체 {list?.length}개</TableTitle>


      <Table
        selected={selected}
        setSelected={setSelected}
        id="decision-table"
        className="mb-60"
        header={HEADERS}
        datas={list?.map((data, index) => ({
          id: `${data._id}-${index}`,
          renders: [
            { render: () => formatDateYMD(data.created_date) },
            { render: data?.decisionType === 0 ? "회차 수정" : "회차 등록" },
            { render: () => data.target_episode.episode_index },
            { render: () => data.target_episode?.title },
            { render: () => data.target_episode.isPaid ? "유료" : "무료" },
            { render: () => data.target_episode.episode_price_amount + "개" },
            { render: () => data.target_episode.version },
            { render: () => ["승인대기중", "승인완료", "승인반려", "판매중", "판매중지"][data.target_episode.product_status] },
            { render: () => formatDateYMD(data.target_episode.created_date) },
            { render: () => formatBits(data.target_episode.fileSize) },
            { render: () => ["대기중", "승인", "반려"][data.hasApproved] },
            // { render: () => data.target_episode.uuid },
          ]}
        )) ?? []}
      />

      <PaginationBox>
        <div className="flex buttons">
          <Button width={140} height={45} onClick={async () => {
            if (isLoading) return;
            setIsLoading(true);

            try {
              const selected_episode = list.filter(item => selected.map((id) => id.split('-')[0]).includes(item._id));
            
              const fetchArr = selected_episode.map(async (item) => {
                await fetchApproveEpisode(item._id);
              });

              const result = await Promise.allSettled(fetchArr);

              const filterReject = result.filter(item => item.status === 'rejected');
              if(filterReject.length > 0) throw new Error(`승인에 실패한 회차가 있습니다. (${filterReject.length}개)`);

              alert('승인에 성공했습니다.');

              setSearchParams((prev) => {
                prev.set('timestamp', Date.now());
                return prev;
              });
            } catch (error) {
              alert(error.message);
            } finally {
              setIsLoading(false);
            }
          }}>승인</Button>

          <div className='mx-12'/>

          <Button width={140} height={45} background={'red'} onClick={async () => {
            if (isLoading) return;
            setIsLoading(true);

            try {
              const selected_episode = list.filter(item => selected.map((id) => id.split('-')[0]).includes(item._id));
              
              const fetchArr = selected_episode.map(async (item) => {
                await fetchRejectEpisode(item._id);
              });

              const result = await Promise.allSettled(fetchArr);

              const filterReject = result.filter(item => item.status === 'rejected');
              if(filterReject.length > 0) throw new Error(`반려에 실패한 회차가 있습니다. (${filterReject.length}개)`);

              alert('반려에 성공했습니다.');

              setSearchParams((prev) => {
                prev.set('timestamp', Date.now());
                return prev;
              });
            } catch (error) {
              alert(error.message);
            } finally {
              setIsLoading(false);
            }
          }}>반려</Button>

          <div className='mx-12'/>
        
          {/* <Button width={140} height={45} background={'red'} onClick={async () => {
            if (isLoading) return;
            setIsLoading(true);

            try {
              if (!confirm('정말 비활성화 하시겠습니까?')) return;

              const selected_episode = list.filter(item => selected.map((id) => id.split('-')[0]).includes(item._id));
              const fetchArr = selected_episode.map(async (item) => {
                await fetchEpisodeUnActivate(item.target_episode._id);
              });

              const result = await Promise.allSettled(fetchArr);

              const filterReject = result.filter(item => item.status === 'rejected');

              if(filterReject.length > 0) throw new Error(`비활성화에 실패한 회차가 있습니다. (${filterReject.length}개)`);

              alert('비활성화에 성공했습니다.');

              setSearchParams((prev) => {
                prev.set('timestamp', Date.now());
                return prev;
              });
            } catch (error) {
              alert(error.message);
            } finally {
              setIsLoading(false);
            }
          }}>비활성화</Button> */}
        </div>
      </PaginationBox>
    </Container>
  );
}

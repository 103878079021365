import { axiosAuth } from "@/lib/axios";


export const fetchNewCoinPromotionData = async (fetchData) => {
  try {
    const { data } = await axiosAuth.post('/promotion/coin', {
      ...fetchData
    })

    alert('프로모션을 생성했습니다.')

    window.location.href = '/content/promotion/coin';
    return data;
  } catch (e) {
    return alert('프로모션 생성에 실패했습니다.')
  }
}

// 신규 조회 프로모션 생성
export const fetchNewExposurePromotionData = async (fetchData) => {
  try {
    const { data } = await axiosAuth.post('/promotion/exposure', {
      ...fetchData
    })

    alert('프로모션을 생성했습니다.')

    window.location.href = '/content/promotion/exposure';
    return data;
  } catch (e) {
    return alert('프로모션 생성에 실패했습니다.')
  }
}


// 신규 오디오북 프로모션 생성
export const fetchNewAudioBookPromotionData = async (fetchData) => {
  try {
    const { data } = await axiosAuth.post('/promotion/audiobook', {
      ...fetchData
    })

    alert('프로모션을 생성했습니다.')

    window.location.href = '/content/promotion/audiobook';
    return data;
  } catch (e) {
    return alert('프로모션 생성에 실패했습니다.')
  }
}



export const fetchEditCoinPromotionData = async (fetchData) => {
  try {
    const { data } = await axiosAuth.put('/promotion/coin', {
      ...fetchData
    })

    alert('프로모션을 수정했습니다.')

    window.location.href = '/content/promotion/coin';
    return data;
  } catch (e) {
    return alert('프로모션 수정에 실패했습니다.')
  }
}


// 프로모션 비활성화
export const fetchPromotionUnActivate = async (promotion_id, promotion_type) => {
  const { data } = await axiosAuth.post(`/promotion/${promotion_type}/unactivate`, { promotion_id });
  return data;
}


export const fetchPromotionList = async (page, count, search, type, promotion_type) => {
  const query = [];
  if (search) query.push(`search=${search}`);
  if (type) query.push(`type=${type}`);
  const { data } = await axiosAuth.get(`/promotion/${promotion_type}/list/${page}/${count}?${query.join('&')}`);
  return data;
}


export const fetchPromotionDetail = async (promotion_id, promotion_type) => {
  const { data } = await axiosAuth.get(`/promotion/${promotion_type}/detail/${promotion_id}`);
  return data;
}




export const fetchPromotionNovelDetail = async (novel_id, promotion_type) => {
  const { data } = await axiosAuth.get(`/promotion/${promotion_type}/novel/detail/${novel_id}`);
  return data;
}


export const fetchPromotionNovelCPDetail = async (cp_id, promotion_type) => {
  const { data } = await axiosAuth.get(`/promotion/${promotion_type}/cp/detail/${cp_id}`);
  return data;
}

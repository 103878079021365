import { pagination_end, pagination_next, pagination_prev, pagination_start } from '@/assets/components';
import { getTheme } from '@/styles/theme';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  max-width: 300px;
  align-items: center;
  justify-content: center;
  width: 100%;
  .arrowBtnDump,
  .arrowBtn {
    width: 30px;
    height: 30px;
  }
  .arrowBtn {
    cursor: pointer;
  }
  .number {
    flex: 1;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
  }
  .numberUnder5 {
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    margin: 0 16px;
  }
`;

export default function Pagination({ size = '', currentPage, totalPage, onClickPage, margin = "0 30px", center }) {
  const array = [...Array(totalPage).keys()].map((item) => item + 1);
  function paginationList() {
    if (totalPage <= 5) return array;
    if (currentPage < 3) return array.slice(0, 5);
    if (currentPage + 3 > totalPage) return array.slice(totalPage - 5, totalPage);
    else return array.slice(currentPage - 3, currentPage + 2);
  }
  return (
    <Container style={{ margin }}>
      {currentPage !== 1 ?
        <div className='arrowBtnDump pointer flex-center' onClick={() => onClickPage(1)}>
          <img src={pagination_start} />
        </div>
        :
        <div className='arrowBtnDump' />
      }

      {/* 뒤로 */}
      {totalPage > 5 && currentPage > 1 ? (
        <div className='arrowBtnDump pointer flex-center' onClick={() => onClickPage(currentPage - 1)}>
          <img src={pagination_prev} />
        </div>
      ) : (
        <div className='arrowBtnDump' />
      )}


      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {paginationList().map((contact, i) => (
          <div
            onClick={() => {
              onClickPage(contact);
            }}
            className={totalPage >= 5 ? 'number' : 'numberUnder5'}
            style={{ color: contact === currentPage ? getTheme('primary') : '#141414' }}
            key={`page_${i}`}
          >
            {contact}
          </div>
        ))}
      </div>

      {totalPage > 5 && currentPage < totalPage ? (
        <div className='arrowBtnDump pointer flex-center' onClick={() => onClickPage(currentPage + 1)}>
          <img src={pagination_next} />
        </div>
      ) : (
        <div className='arrowBtnDump' />
      )}

      {currentPage !== totalPage && totalPage ?
        <div className='arrowBtnDump pointer flex-center' onClick={() => onClickPage(totalPage)}>
          <img src={pagination_end} />
        </div>
        :
        <div className='arrowBtnDump' />
      }
    </Container>
  );
}

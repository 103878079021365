import { Button, Input, Modal } from "@/components";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 60px 100px;
`;

const OnlyReadBox = styled.div`
  border: solid 1px #b9b9b9;
  width: 880px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 24px 15px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`
const TitleMini = styled.div`
  font-size:18px;
  font-weight: bold;
`

const GrayText = styled.div`
  font-size: 19px;
  color: #828282;
  white-space: pre-line;
`




function Profile() {

  const [labels, setlabels] = useState(['be주류', '설화', 'dnc미디어']);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <ButtonTitle title="파트너 프로필 편집" containerSytle={{marginBottom:76}}/>
        <div className='mb-26 pb-30'style={{ fontSize:24, fontWeight:'bold', borderBottom: '2px solid #000000' }}>
          기본 정보
        </div>
        <div className="flex mb-10">
          <TitleMini>발행자 명</TitleMini>
          <div className='ml-2' style={{color:'red'}}>*</div>
        </div>

        <OnlyReadBox className="mb-60">
          be주류
        </OnlyReadBox>

        <div className="flex align-center pb-28 mb-26" style={{borderBottom:'solid 2px #000'}}>
          <Title>레이블 설정</Title>
          <Button className='ml-16'width={127} onClick={(e)=>{setIsOpen(true)}}>추가</Button>
        </div>

        <TitleMini className="mb-24">레이블</TitleMini>
        <div className="flex">
          {labels.map((label)=>{
            return <div className="ml-30">
              {label}
            </div>
          })}
        </div>
        <Modal
          width={1070}
          height={529}
          isOpen={isOpen}
          onClose={() => { setIsOpen(false) }}
        >
          <Title className="mb-24">레이블 추가</Title>
          <GrayText className="mb-66">
            <div>주의사항:</div>
            <div>1. 레이블명은 중복될 수 없습니다.</div>
            <div>2. </div>
            <div>3. 레이블이 생성 추가되면 support@oddnary.net으로 담당자와의 연결을 통해서만 수정할 수 있습니다.</div>
          </GrayText>
          <div className="flex mb-10">
            <TitleMini>레이블명</TitleMini>
            <div className='ml-2' style={{color:'red'}}>*</div>
          </div> 
          <div className="mb-80">
            <Input />
          </div>
          <div className="flex justify-end">
            <Button width={154} onClick={() => setIsOpen(false)} >레이블 추가</Button>
          </div>
        </Modal>
    </Container>
  );
}

export default Profile;
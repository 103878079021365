import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import { LabelInput } from '..';
import { fetchWriterSearchList } from '@/api/writer';
import { useClickOutside } from '@/hooks';
import WriterAddModal from './WriterAddModal';


const Container = styled.div`

  .writer_menu {
    width: 100%;
    background-color: #fff;
    border: 1px solid #b9b9b9;
    border-radius: 7px;
    position: absolute;
    z-index: 999;
    top: calc(100% + 4px);
    left: 0;
  }

  .writer_list {
    border-bottom: 1px solid #b9b9b9;

    .writer_item {
      width: 100%;
      padding: 10px;
    }
  }

`


export default function WriterInput ({ pushWriterList }) {
  const [isWriterOpen, setIsWriterOpen] = useState(false);

  const [searchInput, setSearchInput] = useState('');
  const [writerList, setWriterList] = useState([]);
  
  const [isWriterAddOpen, setIsWriterAddOpen] = useState(false);

  useEffect(() => {
    if(searchInput) {
      // Fetch Writer List
      getData();
    }
  }, [searchInput])

  async function getData() {
    const data = await fetchWriterSearchList(searchInput);
    
    setWriterList(data);
  }

  const ref = useRef(null);
  useClickOutside(ref, () => setIsWriterOpen(false))


  function addWriter(writerData) {
    setSearchInput('');
    setIsWriterOpen(false);
    setWriterList([]);
    pushWriterList(writerData);
  }


  return (
    <>
      <WriterAddModal isOpen={isWriterAddOpen} setIsOpen={setIsWriterAddOpen}/>
      <Container className='relative' ref={ref}>
        <LabelInput 
          label={"작가명"} 
          value={searchInput} 
          onChange={e => setSearchInput(e.target.value)} 
          required
          onClick={() => setIsWriterOpen(true)}
        />

        
        

        {(isWriterOpen || writerList.length > 0) && 
          <div className='writer_menu'>
            {writerList.length > 0 &&
              <div className='writer_list'>
                {writerList.map((item, idx) => (
                  <div className='writer_item pointer' key={`writer-${item.name}-${idx}`} onClick={() => addWriter(item)}>
                    <div className='bold'>{item.name}</div>
                  </div>
                ))}
              </div>
            }

            <div className='w-full flex-center pointer py-10' onClick={() => setIsWriterAddOpen(true)}>
              <div className='bold font-18'>+ 작가 추가</div>
            </div>
          </div>
        }


      </Container>
    </>
  )
}
// 공용 CSV 다운로드 함수
export const downloadCSV = (headers, dataList, fileName) => {
  // CSV 데이터 생성
  const generateCSV = () => {
    const csvRows = [];

    // CSV 헤더 추가
    csvRows.push(headers.map(header => `"${header}"`).join(',')); // 헤더를 큰 따옴표로 감싸기

    // CSV 데이터 추가
    dataList.forEach((data) => {
      const row = headers.map((_, index) => {
        let cell = data[index] !== undefined ? data[index] : '';
        
        // 수식을 방지하기 위해 작은 따옴표 추가
        if (typeof cell === 'string' || typeof cell === 'number') {
          cell = `="${cell}"`;  // Excel이 데이터를 텍스트로 인식하도록 '=' 추가
        }
        
        return cell;
      });
      csvRows.push(row.join(','));
    });

    return csvRows.join('\n');
  };

  const csvData = generateCSV();

  // BOM 추가
  const bom = '\uFEFF'; 
  const blob = new Blob([bom + csvData], { type: 'text/csv;charset=utf-8;' });

  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

import axios, { axiosAuth } from "@/lib/axios";

export const fetchAdminSendNotification = async ({
  title,
  body,
  link,
}) => {
  try {
    const { data } = await axiosAuth.post('/notification/admin/sendall', {
      title,
      body,
      link,
    });

    alert('알람을 생성했습니다.')

    return data;
  } catch (e) {
    return alert('알람 생성에 실패했습니다.')
  }
}




// CP사 정산
export const fetchGetNotificationList = async (
  page,
  count,
) => {
  const { data } = await axiosAuth.get(`/notification/list/${page}/${count}`);
  
  return data;
}

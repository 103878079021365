import { fetchAddKeywordData } from '@/api/keyword';
import { Button, CheckBox, LabelInput, Modal } from '@/components'
import { axiosAuth } from '@/lib/axios';
import { useGenreList } from '@/stores/genre';
import { useState } from 'react'
import styled from 'styled-components'


const Container = styled.div`
  .c-828282 {
    color: #828282;
    line-height: 20px;
  }
`


export default function KeywordAddModal ({
  isOpen,
  setIsOpen
}) {
  const { genreList } = useGenreList();
  const [isLoading, setIsLoading] = useState(false);
  const [keywordName, setKeywordName] = useState('');
  
  async function submit() {
    if (isLoading) return;
    setIsLoading(true);
    try{
      // Fetch Create Keyword
      const result = await fetchAddKeywordData(keywordName, genre, keywordType);

      // 장르 생성 성공
      if(result) {
        alert('키워드가 생성되었습니다.');
        setKeywordName('');
        setIsOpen(false);
      } else {
        alert('키워드 생성에 실패했습니다.');
      }
    } catch(e) {
      console.error(e.message);
      alert('키워드 생성에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  }
  
  const [keywordType, setKeywordType] = useState([]);
  const [genre, setGenre] = useState([]);

  return (
    <Container>   
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className='bold font-30 mb-40'>키워드 추가</div>
        
        <div className='font-18 mb-10 bold'>연관 장르</div>
        <div className='mb-48 w-full'>
          {genreList.map((item) => (
            <div className='inline-block mb-10 mr-10' key={item._id}>
              <CheckBox label={item.name} selected={genre.includes(item._id)} onClick={() => {
                if(genre.includes(item._id)) setGenre(list => list.filter(it => it !== item._id ));
                else setGenre(list => [...list, item._id])
              }}/>
            </div>
          ))}
        </div>


        <div className='font-18 mb-10 bold'>키워드 타입</div>
        <div className='w-full mb-48'>
          {
            KeywordTypeList.map((item) => (
              <div className='inline-block mb-10 mr-10' key={item.name}>
                <CheckBox label={item.name} selected={keywordType.includes(item.value)} onClick={() => {
                  if(keywordType.includes(item.value)) setKeywordType(list => list.filter(it => it !== item.value ));
                  else setKeywordType(list => [...list, item.value])
                }}/>
              </div>
            ))
          }
        </div>

        <LabelInput label={"키워드명"} required value={keywordName} onChange={e => setKeywordName(e.target.value)}/>
      
        <div className='w-full flex justify-end mt-30'>
          <Button isLoading={isLoading} onClick={submit}>장르 등록</Button>
        </div>
      </Modal>
    </Container>
  ) 
}


const KeywordTypeList = [
  {
    name: '장르',
    value: 0
  },
  {
    name: '소재',
    value: 1
  },
  {
    name: '주인공',
    value: 2
  }
]
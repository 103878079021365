import { Button, Dropdown, Input, Pagination, SearchBar, Table } from "@/components";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
; // 한국어 locale

import moment from 'moment';
import 'moment-timezone'
import { fetchGetSalesStatus } from "@/api/statistics";
import { formatNumber } from "@/utils/format";
import { downloadCSV } from "@/utils/csv";
moment.tz.setDefault('Asia/Seoul');



const HEADERS = [
  { name: "작품명", minWidth: 200 },
  { name: "대여", minWidth: 120 },
  { name: "소장", minWidth: 120 },
  { name: "합계", minWidth: 120 },
  { name: "단가", minWidth: 120 },
  { name: "총 판매액", minWidth: 120 },
];


const Container = styled.div`
  padding: 60px 100px;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
`;

const Line = styled.div`
  width: 24px;
  height: 3px;
  background-color: #000;
`;

const SearchOptionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 60px;
`;

const TotalCount = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

function Sales() {


  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const [list, setList] = useState([])
  const [searchTitle, setSearchTitle ] = useState('');
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  
  useEffect(() => {
    if(startDate && endDate) getData();
  }, [page, setPage, count])

  async function getData() {
    const st = handleStartDateChange(startDate);
    const ed = handleEndDateChange(endDate);

    const { list, total, totalCount } = await fetchGetSalesStatus(
      page,
      count,
      st,
      ed,
      searchTitle
    );

    console.log(list, total, totalCount);

    setList(list);

    // 전체 카운트
    setTotalCount(totalCount);
  
    // 토탈
    setTotalPage(total);
  }

  const handleStartDateChange = date => {
    // 선택된 날짜의 시간을 00:00으로 설정
    const adjustedDate = moment(date).startOf('day').format('YYYYMMDD-HH:mm:ss');
    return adjustedDate
  };

  const handleEndDateChange = date => {
    // moment를 사용하여 선택된 날짜의 시간을 23:59:59으로 설정
    const adjustedDate = moment(date).endOf('day').format('YYYYMMDD-HH:mm:ss');
    return adjustedDate
  }


  function downloadCSVButton() {
    const headers = HEADERS.map(item => item.name);
    const dataList = list?.map(data => {
      return [
        data.title,
        formatNumber(data.rent_count),
        formatNumber(data.permanent_count),
        formatNumber(data.rent_count + data.permanent_count),
        !data.isBook ? data.regularPrice : "단행본",
        formatNumber(data.coin_total_amount) + " 스타",
      ]
    })

    downloadCSV(headers, dataList, '작품 판매 현황')
  }


  return (
    <Container>
      <ButtonTitle 
        title='작품판매 현황'
        containerSytle={{ marginBottom: 60}}
      />

      <SearchContainer>
        <SearchOptionContainer>
          <Input type="date" containerStyle={{ borderRadius: "7px", overflow: "hidden", border: "1px solid #b9b9b9" }} inputStyle={{ height: '36px' }} value={startDate} onChange={e => setStartDate(e.target.value)}/>

          <Line className="ml-10" />

          <Input type="date" containerStyle={{ marginLeft: '10px', borderRadius: "7px", overflow: "hidden", border: "1px solid #b9b9b9" }} inputStyle={{ height: '36px' }} value={endDate} onChange={e => setEndDate(e.target.value)}/>
        
        </SearchOptionContainer>

        <SearchBar
          searchBarContainerStyle={{ width: "600px", height: "46px", margin: '0' }}
          fontSize={15}
          dKey="searchCategory"
          label={"작품명"}
          value={searchTitle} onChange={e => setSearchTitle(e.target.value)}
        />
      </SearchContainer>

      <ButtonContainer>
        <Button width={140} onClick={() => {
          setPage(1);
          getData();
        }}>조회하기</Button>
      </ButtonContainer>

      <TotalCount>전체 {totalCount}개</TotalCount>
      
      <div className="mt-24"/>
      <Table
        id="sale-list"
        className="mb-50"    
        header={HEADERS}
        datas={list?.map((data) => ({
          id: data.id,
          renders: [
            { render: () => data.title },
            { render: () => formatNumber(data.rent_count) },
            { render: () => formatNumber(data.permanent_count) },
            { render: () => formatNumber(data.rent_count + data.permanent_count) },
            { render: () => !data.isBook ? data.regularPrice : "단행본" },
            { render: () => formatNumber(data.coin_total_amount) + " 스타" },
          ],
        }))}
      />

      <div className="flex align-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]}
          label={`${count}개`}
          dkey="pageSize"
          setSelect={({ value: selected }) => {
            setCount(selected);
          }}
        />
        
        <Pagination
          currentPage={page}
          totalPage={totalPage}
          onClickPage={(page) => setPage(page)}
        />

        <Button style={{ marginLeft: 'auto' }} width={245} onClick={() => {
          downloadCSVButton();
        }}>CSV 다운로드</Button>
      </div>
    </Container>
  );
}

export default Sales;

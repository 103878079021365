import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";
import { PieChart } from "..";

// 매출 현황 (작품별)
const NOVEL_SALES_DATA = [
  { name: '작품명 1', value: 400 },
  { name: '작품명 2', value: 300 },
  { name: '작품명 3', value: 300 },
  { name: '작품명 4', value: 200 },
  { name: '작품명 5', value: 100 },
  { name: '작품명 6', value: 200 },
];

const Card = styled.div`
  width:690px;
  background-color:#fff;
  box-shadow: 0.5px 7px 24px 0 rgba(0, 0, 0, 0.04);
  padding: 18px 30px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const SubTitle = styled.div`
  font-size: 15px;
`

function SalesStatusByContent({ list }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <Card>
      <div className="flex">
        <Title>작품 매출현황 [스타 사용량]</Title>
        <SubTitle className='mt-4 ml-10'>(상위 7개)</SubTitle>
      </div>
      {isLoading 
        ? 
          <ContentLoader 
            speed={2}
            width={630}
            height={600}
            viewBox="0 0 630 600"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="315" cy="275" r="160" /> 
            <rect x="15" y="540" rx="8" ry="8" width="600" height="20" />
          </ContentLoader>
        : 
          <PieChart data={list} />
      }
    </Card>
  )
}

export default SalesStatusByContent;

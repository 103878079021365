import styled from 'styled-components'

import moment from 'moment';
import 'moment-timezone'
import ButtonTitle from '@/components/ButtonTitle/ButtonTitle';
import { Button, Input } from '@/components';
import { useState } from 'react';
import { fetchGetTopSearchesByType } from '@/api/search';
moment.tz.setDefault('Asia/Seoul');

const Container = styled.div`
  padding: 60px 100px;

  .rankBox {
    min-width: 1000px;
    gap: 16px;
    .rankItem {
      max-width: 300px;
      box-shadow: 0.5px 7px 24px 0 rgba(0, 0, 0, 0.04);
      background-color: #fff;
      border-radius: 8px;
      padding: 16px;
    }
  }
`

const Line = styled.div`
  width: 24px;
  height: 3px;
  background-color: #000;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
`;


const SearchOptionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;


const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 60px;
`;


export default function Page () {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [datas, setDatas] = useState();

  const handleStartDateChange = date => {
    // 선택된 날짜의 시간을 00:00으로 설정
    const adjustedDate = moment(date).startOf('day').format('YYYYMMDD-HH:mm:ss');
    return adjustedDate
  };

  const handleEndDateChange = date => {
    // moment를 사용하여 선택된 날짜의 시간을 23:59:59으로 설정
    const adjustedDate = moment(date).endOf('day').format('YYYYMMDD-HH:mm:ss');
    return adjustedDate
  }


  async function getData() {
    const st = handleStartDateChange(startDate);
    const ed = handleEndDateChange(endDate);

    const result = await fetchGetTopSearchesByType(
      st,
      ed,
      30 // 몇위까지 뽑을 것인지
    );

    setDatas(result);

    console.log(result);
  }

  
  return (
    <Container>
      <ButtonTitle 
        title='유저 검색어 통계'
        containerSytle={{ marginBottom: 60}}
      />

      <SearchContainer>
        <SearchOptionContainer>
          <Input type="date" containerStyle={{ borderRadius: "7px", overflow: "hidden", border: "1px solid #b9b9b9" }} inputStyle={{ height: '36px' }} value={startDate} onChange={e => setStartDate(e.target.value)}/>

          <Line className="ml-10" />

          <Input type="date" containerStyle={{ marginLeft: '10px', borderRadius: "7px", overflow: "hidden", border: "1px solid #b9b9b9" }} inputStyle={{ height: '36px' }} value={endDate} onChange={e => setEndDate(e.target.value)}/>
        
        </SearchOptionContainer>
      </SearchContainer>

      <ButtonContainer>
        <Button width={140} onClick={() => {
          getData();
        }}>조회하기</Button>
      </ButtonContainer>


      <div className='w-full rankBox flex flex'>
        {datas?.map((item, idx) => (
          <div className='rankItem flex-1' key={`item-${idx}`}>
            <div className='text-center bold mb-32'>{item.type}</div>

            {item?.topTexts?.map((data, key) => (
              <div className='flex font-14 align-center mb-14' key={`itemasd-${idx}-${data.text}`}>
                <div className='bold'>{key + 1}. {data.text}</div>
                <div className='mx-12'>-</div>
                <div>{data.count}회</div>
              </div>

            ))}
          </div>
        ))}
      </div>

    </Container>
  )
}
import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import {useSearchParams } from 'react-router-dom';
import { fetchCPNovelList } from '@/api/novel';

const cpNovelListDataState = atom({
  key: 'cpNovelListData',
  default: {
    list: [],
    totalPage: 0,
    totalCount: 0,
  },
});


export const pageState = atom({
  key: 'cpNovelListPageState',
  default: 1,
});

export const countState = atom({
  key: 'cpNovelListCountState',
  default: 10,
});


export const useCpNovelList = (id) => {
  const [cpNovelList, setCpNovelList] = useRecoilState(cpNovelListDataState);
  const [page, setPage] = useRecoilState(pageState);
  const [count, setCount] = useRecoilState(countState);

  const [isLoading, setIsLoading] = useState(false);
  
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePage = (pageValue) => {
    setPage(pageValue);
    setSearchParams((prev) => {
      prev.set('page', pageValue);
      return prev;
    })
  }
  
  const handleCount = (countValue) => {
    setCount(countValue);
    setSearchParams((prev) => {
      prev.set('count', countValue);
      return prev;
    })
    handlePage(1);
  };

  useEffect(() => {
    const countParam = searchParams.get('count');
    const pageParam = searchParams.get('page');
    
    if (countParam) {
      setCount(Number(countParam));
    }
    if (pageParam) {
      setPage(Number(pageParam));
    }

    const getCpNovelListData = async () => {
      try {
        const response = await fetchCPNovelList(page, count, id);
        setCpNovelList({
          list: response.list,
          totalPage: response.totalPage,
          totalCount: response.totalCount,
        });
      } catch (error) {
        console.log({error})
        console.error('Failed to fetch CpNovel list:', error);
      } finally {
        setIsLoading(false);
      }
    }


    getCpNovelListData();


  }, [searchParams, setCount, setPage, setCpNovelList])

  return {
    isLoading,
    cpNovelList,
    page,
    count,
    handlePage,
    handleCount,
  }
}
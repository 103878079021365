import { axiosAuth } from "@/lib/axios";

export const fetchGenreList = async () => {
  const { data } = await axiosAuth.get(`/genre/admin/list`);
  return data;
}
  
export const fetchStalierGenreList = async () => {
  const { data } = await axiosAuth.get(`/genre/stalier/admin/list`);
  return data;
}
  

export const fetchAddGenreData = async (name) => {
  const { data } = await axiosAuth.post(`/genre/admin`, {
    name
  });
  return data;
}

export const fetchAddStalierGenreData = async (name) => {
  const { data } = await axiosAuth.post(`/genre/stalier/admin`, {
    name
  });
  return data;
}
  

export const fetchUpdateGenreData = async (genre_id, name) => {
  const { data } = await axiosAuth.put(`/genre/admin`, {
    genre_id,
    name
  });
  return data;
}
    

export const fetchUpdateStalierGenreData = async (genre_id, name) => {
  const { data } = await axiosAuth.put(`/genre/stalier/admin`, {
    genre_id,
    name
  });
  return data;
}
  
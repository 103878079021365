import { Button, Table } from '@/components'
import ButtonTitle from '@/components/ButtonTitle/ButtonTitle'
import GenreAddModal from '@/components/GenreAddModal'
import GenreUpdateModal from '@/components/GenreUpdateModal'
import { useGenreList } from '@/stores/genre'
import { formatDateYMD } from '@/utils/format'
import { useState } from 'react'
import styled from 'styled-components'


const Container = styled.div`
  padding: 60px 100px;
`


const HEADERS = [
  { name: "이름", minWidth: 160 },
  { name: "생성일", minWidth: 160 },
  { name: "마지막 수정일", minWidth: 160 },
  { name: "작품 수", minWidth: 110 },
  { name: "", minWidth: 110 },
];

export default function Page () {
  const { genreList } = useGenreList();

  const [isGenreAddModalOpen, setIsGenreAddModalOpen] = useState(false);
  const [updateTarget, setUpdateTarget] = useState(null);

  return (
    <Container>
      <ButtonTitle
        title='장르 관리'
        containerSytle={{ marginBottom: 38 }}
      />
      
      <div className='mt-100'/>


      <Table
        id="novel-list"
        className="mb-60"
        header={HEADERS}
        datas={genreList?.map((data, index) => {
          console.log(data)
          return {
            id: 'genre' + index,
            renders: [
              { render: () => data.name },
              { render: () => formatDateYMD(data.created_date)},
              { render: () => formatDateYMD(data.updated_date)},
              { render: () => data.novelCount },
              { render: () => <>
                <Button width={80} height={32} fontSize={14} onClick={() => {
                  setUpdateTarget(data._id)
                }}>수정하기</Button>
              </> },
              
            ],
          }
        })}
      />

      <GenreAddModal
        isOpen={isGenreAddModalOpen}
        setIsOpen={setIsGenreAddModalOpen}
      />

      <GenreUpdateModal
        genre_id={updateTarget}
        isOpen={!!updateTarget}
        setIsOpen={setUpdateTarget}
      />

      <div className='w-full flex justify-end'>
        <Button onClick={() => setIsGenreAddModalOpen(true)}>장르 추가</Button>
      </div>
    </Container>
  )
}
import { sample_banner } from "@/assets";
import { Button, Dropdown, Input, Pagination, SearchBar, Table } from "@/components";
import ButtonTitle from "@/components/ButtonTitle/ButtonTitle";
import { useBannerList } from "@/stores/bannerList";
import { formatImageUri } from "@/utils/format";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const HEADERS = [
  { name: "배너 이미지", minWidth: 200 },
  { name: "배너명", minWidth: 250 },
  { name: "배너 위치", minWidth: 100 },
  { name: "개제 시작일", minWidth: 120 },
  { name: "개재 종료일", minWidth: 120 },
  { name: "배너 타입", minWidth: 100 },
  { name: "내부 link", minWidth: 200 },
  { name: "외부 link", minWidth: 200 },
];

const postPlaceList = {
  main: '메인 화면 배너',
  stalier: '스텔리에 화면 배너',
  odi_pay: '스타 결제 화면 배너',
  sub: '소배너',
}

const bannerTypeList = {
  inner: '내부',
  outer: '외부',
}

const countOptions = [
  { name: "10개", value: 10 },
  { name: "20개", value: 20 },
  { name: "30개", value: 30 },
];

const Container = styled.div`
  padding: 60px 100px;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 60px;
`;

const TotalCount = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const SEARCH_OPTIONS = [{ name: "배너명", value: "title" }];

function Banner() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchOptions, setSearchOptions] = useState({ 
    search: searchParams.get("search") || "",
    type: SEARCH_OPTIONS.find((option) => option.value === searchParams.get("type")) || SEARCH_OPTIONS[0],
  });

  const { bannerList, isLoading, page, count, handlePage, handleCount } = useBannerList();

  const formatDateYMD = (str) => {
    if(!str) return str
    if(str.length < 8) return str
    return str.slice(0,4) + '-' + str.slice(5, 7) + '-' + str.slice(8, 10)
  }

  return (
    <Container>
      <ButtonTitle 
        title='배너 관리'
        containerSytle={{ marginBottom: 60}}
      />

      <SearchContainer>
        <SearchBar
          searchBarContainerStyle={{ width: "880px", height: "60px", margin: '0' }}
          fontSize={20}
          options={SEARCH_OPTIONS}
          dKey="searchCategory"
          select={searchOptions.type}
          setSelect={(select) => setSearchOptions({ ...searchOptions, type: select })}
          serarch={searchOptions.search}
          onChange={(e) => setSearchOptions({ ...searchOptions, search: e.target.value })}
        />
      </SearchContainer>

      <ButtonContainer>
        <Button width={590} isLoading={isLoading} onClick={() => {
          setSearchParams((prev) => {
            prev.set("search", searchOptions.search);
            prev.set("type", searchOptions.type.value);
            prev.set("page", 1);
            return prev;
          })
        }}>검색하기</Button>
      </ButtonContainer>

      <TotalCount>전체 {bannerList.totalPage}개</TotalCount>

      <Table
        id="sale-list"
        className="mb-50"
        header={HEADERS}
        datas={
          bannerList.list.map((data) => ({
            id: data.id,
            onClick: () => {
              navigate(`/etc/banner/register?id=${data._id}`);
            },
            renders: [
              { render: () => <img src={formatImageUri(data.post_image_uri)} style={{width:"157px", height:"122px"}}/> },
              { render: () => data.title },
              { render: () => postPlaceList[data.post_place] },
              { render: () => formatDateYMD(data.post_start_date) },
              { render: () => formatDateYMD(data.post_end_date) },
              { render: () => bannerTypeList[data.bannerType] },
              { render: () => data.link },
              { render: () => data.outLink },
            ],
          }))
        }
      />

      <div className="flex align-center">
        <Dropdown
          containerStyle={{ width: "84px", minWidth: "84px" }}
          options={countOptions}
          dkey="pageSize"
          select={countOptions.find((option) => option.value === count)}
          setSelect={({ value }) => {
            handleCount(value);
          }}
        />
          <Pagination
            currentPage={page}
            totalPage={bannerList.totalPage}
            onClickPage={handlePage}
          />

        <Button style={{ marginLeft: 'auto' }} width={245} onClick={() => {
          navigate('/etc/banner/register');
        }}>배너 등록</Button>
      </div>
    </Container>
  );
}

export default Banner;
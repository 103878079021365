import styled from 'styled-components'
import { Button, CheckBox, GridBox, LabelInput, Modal } from '..'
import { useEffect, useState } from 'react';


const Container = styled.div`
  .keywordItem {
    border: 1px solid #b9b9b9;
    width: 145px;
    height: 47px;
    border-radius: 8px;
    cursor: pointer;
  }
`

// [TODO] Create Data 연결 필요

export default function KeywordModal ({
  selectedGenre,
  keywordSelect,
  selectKeywordList,
  keywordList,
  isOpen,
  setIsOpen
}) {
  const [chk1, setChk1] = useState(false);
  const [chk2, setChk2] = useState(false);
  const [chk3, setChk3] = useState(false);

  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  const [list3, setList3] = useState([]);

  useEffect(() => {
    if(isOpen) {
      // 체크 되어있고, 장르가 선택되어 있을 경우
      if(chk1 && selectedGenre?.value) {
        const list = keywordList.filter(item => item.keywordType.includes(0))
        const list_genre = list.filter(item => {
          let li = item.genre.map(gi => gi._id);
          if(li.includes(selectedGenre.value)) return true;
          else return false;
        });

        setList1(list_genre)
      } else {
        const list = keywordList.filter(item => item.keywordType.includes(0))
        setList1(list)
      }
    }
  }, [chk1, isOpen])

  
  useEffect(() => {
    if(isOpen) {
      // 체크 되어있고, 장르가 선택되어 있을 경우
      if(chk2 && selectedGenre?.value) {
        const list = keywordList.filter(item => item.keywordType.includes(1))
        const list_genre = list.filter(item => {
          let li = item.genre.map(gi => gi._id);
          if(li.includes(selectedGenre.value)) return true;
          else return false;
        });

        setList2(list_genre)
      } else {
        const list = keywordList.filter(item => item.keywordType.includes(1))
        setList2(list)
      }
    }
  }, [chk2, isOpen])
  

  useEffect(() => {
    if(isOpen) {
      // 체크 되어있고, 장르가 선택되어 있을 경우
      if(chk3 && selectedGenre?.value) {
        const list = keywordList.filter(item => item.keywordType.includes(2))
        const list_genre = list.filter(item => {
          let li = item.genre.map(gi => gi._id);
          if(li.includes(selectedGenre.value)) return true;
          else return false;
        });

        setList3(list_genre)
      } else {
        const list = keywordList.filter(item => item.keywordType.includes(2))
        setList3(list)
      }
    }
  }, [chk3, isOpen])
  
  

  return (
    <Container>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className='innerModalContainer'>
          <div className='bold font-30 mb-8'>키워드</div>
          <div className='c-828282 medium font-14 mb-32'>
            * 최대 5개까지 등록 가능합니다.<br/>
            * 독자님이 작품을 검색할때 도움을줍니다.
          </div>

          <div className='flex align-center mb-12'>
            <div className='bold font-20 mr-12'>장르 형태 키워드</div>
            <CheckBox selected={chk1} onClick={() => setChk1(chk => !chk)} label={'본 작품의 장르 관련 키워드만 띄우기'}/>
          </div>

          <GridBox col={5} gap={20}>
            {list1.map((item, idx) => (
              <KeywordItem 
                name={item.name}
                key={`item-0-${idx}`}
                isActive={selectKeywordList.find(fit => fit.name === item.name && fit.keywordType.includes(0))}
                onClick={() => {
                  if(selectKeywordList.length < 5) keywordSelect(item);
                  else alert('키워드는 최대 5개까지 선택 가능합니다.');
                }}
              />
            ))}
          </GridBox>


          


          <div className='flex align-center mt-32 mb-12'>
            <div className='bold font-20 mr-12'>소재 관련 키워드</div>
            <CheckBox selected={chk2} onClick={() => setChk2(chk => !chk)}  label={'본 작품의 장르 관련 키워드만 띄우기'}/>
          </div>

          <GridBox col={5} gap={20}>
            {list2.map((item, idx) => (
              <KeywordItem 
                name={item.name}
                key={`item-1-${idx}`}
                isActive={selectKeywordList.find(fit => fit.name === item.name && fit.keywordType.includes(1))}
                onClick={() => {
                  if(selectKeywordList.length < 5) keywordSelect(item);
                  else alert('키워드는 최대 5개까지 선택 가능합니다.');
                }}
              />
            ))}
          </GridBox>
          


          <div className='flex align-center mt-32 mb-12'>
            <div className='bold font-20 mr-12'>주인공 관련 키워드 </div>
            <CheckBox selected={chk3} onClick={() => setChk3(chk => !chk)} label={'본 작품의 장르 관련 키워드만 띄우기'}/>
          </div>

          <GridBox col={5} gap={20}>
            {list3.map((item, idx) => (
              <KeywordItem 
                name={item.name}
                key={`item-2-${idx}`}
                isActive={selectKeywordList.find(fit => fit.name === item.name && fit.keywordType.includes(2))}
                onClick={() => {
                  if(selectKeywordList.length < 5) keywordSelect(item);
                  else alert('키워드는 최대 5개까지 선택 가능합니다.');
                }}
              />
            ))}
          </GridBox>
        </div>
      </Modal>
    </Container>
  )
}

const KeywordItemContainer = styled.div`
  .keywordItem {
    background-color: ${({isActive}) => isActive ? '#ba1c1f' : '#fff'};
    color: ${({isActive}) => isActive ? '#ffffff' : '#000000'};
    transition: .2s;
  }
`

const KeywordItem = ({
  name,
  isActive,
  onClick
}) => {
  return (
    <KeywordItemContainer onClick={onClick} isActive={isActive}>
      <div className='keywordItem flex-center font-18 regular'>
        #{name}
      </div>
    </KeywordItemContainer>
  )
}
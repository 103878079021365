import { axiosAuth } from "@/lib/axios";

export const fetchkeywordList = async () => {
  const { data } = await axiosAuth.get(`/keyword/admin/list`);
  return data;
}


export const fetchStalierKeywordList = async () => {
  const { data } = await axiosAuth.get(`/keyword/stalier/admin/list`);
  return data;
}


export const fetchAddKeywordData = async (
  name,
  genre,
  keywordType
) => {
  const { data } = await axiosAuth.post('/keyword/admin', {
    name,
    genre,
    keywordType
  })

  return data;
}


export const fetchAddStalierKeywordData = async (
  name,
  genre,
  keywordType
) => {
  const { data } = await axiosAuth.post('/keyword/stalier/admin', {
    name,
    genre,
    keywordType
  })

  return data;
}



export const fetchUpdateKeywordData = async (
  keyword_id,
  name,
  genre,
  keywordType
) => {
  const { data } = await axiosAuth.put('/keyword/admin', {
    keyword_id,
    name,
    genre,
    keywordType
  })

  return data;
}



export const fetchUpdateStalierKeywordData = async (
  keyword_id,
  name,
  genre,
  keywordType
) => {
  const { data } = await axiosAuth.put('/keyword/stalier/admin', {
    keyword_id,
    name,
    genre,
    keywordType
  })

  return data;
}
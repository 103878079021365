import { fetchNovelTitleSearchList } from '@/api/novel'
import { Button, Dropdown, LabelInput } from '@/components'
import { useClickOutside } from '@/hooks'
import { formatImageUri } from '@/utils/format'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setHours, setMinutes } from 'date-fns';
import ko from 'date-fns/locale/ko'; // 한국어 locale
import { fetchNewExposurePromotionData } from '@/api/promotion'

import moment from 'moment';
import 'moment-timezone'
moment.tz.setDefault('Asia/Seoul');

const Container = styled.div`
  padding: 60px 100px;
  padding-bottom: 200px;


  .section_line {
    width: 100%;
    height: 2px;
    background-color: #000000;
  }

  .searchResultBox {
    padding: 12px;
    border-radius: 8px;
    margin-top: 12px;
    background-color: #fff;
    border: 1px solid rgb(204, 204, 204);
    z-index: 10;
    max-height: 400px;
    overflow-y: scroll;
  }

  .searchResultItem {
    border-radius: 8px;
    padding: 16px 10px;
    :hover {
      background-color: #e2e2e2;
    }
  }

  .book_cover {
    width: 155px;
    height: 246px;
  }
`


export default function Page () {
  const [isLoading, setIsLoading] = useState(false)

  const [searchText, setSearchText] = useState('')
  const [novelList, setNovelList] = useState([])

  const [selected, setSelected] = useState(null)

  const [promotion, setPromotion] = useState(PromotionList[0])
  const [title, setTitle] = useState('');

  
  
  const handleStartDateChange = date => {
    // 선택된 날짜의 시간을 00:00으로 설정
    const adjustedDate = moment(date).startOf('day').toDate();
    return adjustedDate;
  };

  const handleEndDateChange = date => {
    // moment를 사용하여 선택된 날짜의 시간을 23:59:59으로 설정
    const adjustedDate = moment(date).endOf('day').toDate();
    return adjustedDate;
  }

  
  const [startDate, setStartDate] = useState(handleStartDateChange(new Date()));
  const [endDate, setEndDate] = useState(handleEndDateChange(new Date()));


  useEffect(() => {
    // 두 글자 이상일 때
    if(searchText.length >= 2) {
      getData();
    }
  }, [searchText])
  
  async function getData () {
    const data = await fetchNovelTitleSearchList(searchText);
    setNovelList(data);
  }
  
  const ref = useRef();
  useClickOutside(ref, () => {
    setNovelList([])
    setSearchText('')
  });

  async function submit() {
    if(isLoading) return;
    setIsLoading(true);

    try {
      let fetchData = {
        target_novel: selected?._id, // 적용 소설
        target_cp: selected?.owner?._id, // 소설 소유 CP사
        title, // 적용된 프로모션 제목 [매열무, 타임딜 등]
        promotion_start: startDate, // 프로모션 시작일
        promotion_end: endDate, // 프로모션 종료일
        promotionNumber: promotion.value
      }


      await fetchNewExposurePromotionData({
        ...fetchData
      });
    } catch (error) {
      alert('프로모션 등록에 실패했습니다.')
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <div className='bold font-30 mb-88'>신규 노출 프로모션 등록</div>
      <div className='bold font-24'>작품 정보</div>

      <div className='section_line my-24'/>

      <div className='w-full relative' ref={ref}>
        <LabelInput value={searchText} onChange={e => setSearchText(e.target.value)} label={"작품 검색"}/>

        {searchText && 
          <div className='searchResultBox absolute w-full'>
            {
              novelList.map((item, index) => (
                <div className='searchResultItem w-full flex align-center pointer' key={item._id + index} onClick={() => {
                  setSelected(item);
                  setNovelList([]);
                  setSearchText('');
                }}>
                  <div className='font-20 bold'>{item?.isBook ? "[단행본] " : ""}{item.title}</div>
                  
                  <div className='font-20 bold mx-12'>-</div>
                  
                  <div className='font-18 medium'>{item?.writer_list?.map(item => item?.name)?.join(', ')}</div>

                  <div className='font-20 bold mx-12'>-</div>

                  <div className='font-18 medium'>[{item?.owner?.name}]</div>
                </div>
              ))
            }

            {
              novelList.length === 0 &&
              <div className='font-24 bold text-center w-full py-24'>
                검색 결과가 없습니다.
              </div>
            }
          </div>
        }
      </div>


      {selected &&
        <div className='mt-80 w-full'>
          <div className='flex align-center'>
            <img className='book_cover' src={formatImageUri(selected.book_cover)}/>

            <div className='ml-20'>
              <div className='font-24 exbold mb-16'>제목: {selected?.isBook ? "[단행본] " : ""}{selected?.title}</div>
              <div className='font-20 medium mb-8'>장르: {selected?.genre.name}</div>
              <div className='font-20 medium mb-32'>키워드: {selected?.keywords.map(item => item.name)?.join(', ')}</div>

              <div className='font-20 medium mb-8'>작가: {selected?.writer_list?.map(item => item.name)?.join(', ')}</div>
              <div className='font-20 medium'>CP사: {selected?.owner?.name}</div>

            </div>
          </div>
        </div>
      }
      
      <div className='bold font-24 mt-80'>프로모션 정보</div>

      <div className='section_line my-24'/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          프로모션 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <Dropdown options={PromotionList} select={promotion} setSelect={item => setPromotion(item)}/>
        </div>
      </>

      <div className='mt-32'/>
      <LabelInput value={title} onChange={e => setTitle(e.target.value)} required label={"프로모션 이름 (설명 등)"}/>
      
      
            
      <div  style={{width: '200px'}}>
        <div className='bold font-18 mt-32 mb-10'>
          프로모션 시작일 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(handleStartDateChange(date))}
            dateFormat="yyyy-MM-dd" // 날짜 형식 지정
            locale="ko" // 한국어 locale 사용
          />
        </div>
      </div>

      

      <div style={{width: '200px'}}>
        <div className='bold font-18 mt-32 mb-10'>
          프로모션 종료일 <span className='c-red'>*</span>
        </div>

        <div className='mt-12' style={{ width: '180px'}}>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(handleEndDateChange(date))}
            dateFormat="yyyy-MM-dd" // 날짜 형식 지정
            locale="ko" // 한국어 locale 사용
            minDate={startDate} // 시작일을 최소 날짜로 설정
          />
        </div>
      </div>
      




      <div className='w-full mt-80 flex justify-end'>
        <Button isLoading={isLoading} onClick={submit}>프로모션 등록</Button>
      </div>
      
    </Container>
  )
}




const PromotionList = [
  {
    name: "당신을 위한 추천",
    value: 0,
  },
]
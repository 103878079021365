import styled from 'styled-components';
import { DivideBox } from '..';



const BoldText = styled.div`
  font-family: Pretendard;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-align: left;
  color: #000;
  margin-bottom: 20px;

  .smaller{
    font-size: 21px;
  
  }
`
const BoldTextSmall = styled(BoldText)`
  font-size: 21px;
`

const GrayText = styled.div`
  font-family: Pretendard;
  font-size: 21px;
  letter-spacing: 0.42px;
  text-align: left;
  color: #636363;
`

const WorkInfo=styled.div`
  font-family: Pretendard;
  font-size: 21px;
  letter-spacing: 0.42px;
  text-align: left;
  color: #636363;
`

const WorkInfoTitle = styled.div`
  font-family: Pretendard;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.42px;
  text-align: left;
  color: #000;
`

function WorkPolicy(
  {workPolicyArr}
) {
   return (<div>

      <div className='flex' style={{alignItems:'flex-end'}}>
        <BoldText>작품정책</BoldText>
      </div>
      
      <DivideBox infoArr={workPolicyArr}/>
  </div>

  );
}

export default WorkPolicy;
import { Button, Dropdown, Input, Table } from "@/components";
import { formatBits } from "@/utils/format";
import ProgressBar from "@ramonak/react-progress-bar";
import styled from "styled-components";

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko'; // 한국어 locale
import { getTheme } from "@/styles/theme";
import { validateLength } from "@/utils/validate";



const TableContainer = styled.div`
  .table_header_box {
    height: 60px;
    border-bottom: 4px solid #000;
  }

  .table_body_item {
    height: 64px;
    border-bottom: 2px solid #bbbbbb;
  }
`


const SimpleButton = styled.label`
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100px;
  height: 40px;
  background-color: ${getTheme('primary')};
  border-radius: 10px;
`;



function Step1({ datas, setDatas, setStep, isBook }) {
  return (
    <div>
      <TableContainer className="w-full">
        <div className="w-full flex table_header_box align-center">
          <div className="font-20 bold" style={{ minWidth: '140px'}}>파일 변경</div>
          <div className="font-20 bold" style={{ minWidth: '240px'}}>수정 파일명</div>
          <div className="font-20 bold" style={{ minWidth: '120px'}}>용량</div>
          <div className="font-20 bold" style={{ minWidth: '150px'}}>유/무료</div>
          <div className="font-20 bold" style={{ minWidth: '80px'}}>가격</div>
          <div className="font-20 bold flex-1" style={{ minWidth: '200px', maxWidth: '380px'}}>제목</div>
          {isBook && <div className="font-20 bold" style={{ width: '300px'}}>면세코드</div>}
          

        </div>

        <div className="table_body_box w-full">
          {datas.map((item, index) => (
            <div className="table_body_item w-full flex align-center" key={`dfiasdasdle-i-${index}`}>
              <div className="font-20 bold" style={{ minWidth: '140px'}}>
                <input
                  id={`file-input-${index}`}
                  type="file" 
                  accept=".epub"
                  style={{display: 'none'}}
                  onChange={e => {
                    const new_file = e.target.files[0];

                    console.log(new_file)
                    
                    const list = datas.map((dataItem, idx) => {
                      if (idx === index) {
                        return { ...dataItem, new_file };
                      }
                      return dataItem;
                    });

                    setDatas(list);
                  }}
                />
                <SimpleButton className="c-white bold flex-center font-16" htmlFor={`file-input-${index}`}>
                  파일변경
                </SimpleButton>
              </div>
              <div className="font-20 bold" style={{ minWidth: '240px'}}>{item?.new_file ? item.new_file.name : ''}</div>
              <div className="font-20 bold" style={{ minWidth: '120px'}}>{item?.new_file ? formatBits(item.new_file.size) : formatBits(item.fileSize)}</div>
              <div className="font-20 bold" style={{ minWidth: '150px'}}>
                <div style={{width: '100px'}}>
                  <Dropdown 
                    options={PaidTypeList} 
                    select={item.isPaid ? PaidTypeList[0] : PaidTypeList[1]} 
                    setSelect={({value}) => {
                      const list = datas.map((dataItem, idx) => {
                        if (idx === index) {
                          return { ...dataItem, isPaid: value };
                        }
                        return dataItem;
                      });
                      setDatas(list);
                    }}
                  />
                </div>
              </div>
              <div className="font-20 bold" style={{ minWidth: '80px'}}>
                <div style={{width: '60px'}}>
                  <Input value={item.episode_price_amount} onChange={e => {
                    // [TODO] 숫자만
                    const list = datas.map((dataItem, idx) => {
                      if (idx === index) {
                        return { ...dataItem, episode_price_amount: e.target.value };
                      }
                      return dataItem;
                    });
                    setDatas(list);
                  }}/>
                </div>
              </div>
              <div className="font-20 bold flex-1 pr-20" style={{ minWidth: '200px', maxWidth: '380px'}}>
                <Input value={item.title} onChange={e => {
                  const list = datas.map((dataItem, idx) => {
                    if (idx === index) {
                      return { ...dataItem, title: e.target.value };
                    }
                    return dataItem;
                  });
                  setDatas(list);
                }}/>
              </div>

              {isBook && <div className="font-20 bold flex align-center" style={{ width: '300px'}}>
                <div className='' style={{ width: '140px'}}>
                  <Dropdown options={TaxTagList} select={item.taxTag} setSelect={item => {
                    const list = datas.map((dataItem, idx) => {
                      if (idx === index) {
                        return { ...dataItem, taxTag: item };
                      }
                      return dataItem;
                    });
                    setDatas(list);
                  }}/>
                </div>

                <div className='ml-4  ' style={{ width: '100%'}}>
                  <Input placeholder={"면세코드"} value={item.taxCode} onChange={e => {
                    const list = datas.map((dataItem, idx) => {
                      if (idx === index) {
                        return { ...dataItem, taxCode: e.target.value };
                      }
                      return dataItem;
                    });
                    setDatas(list);
                  }}/>
                </div>
              </div>}

              
            </div>
          ))}
        </div>
      </TableContainer>
      
{/*       
      <div className="py-30 mb-100">
        <ProgressBar completed={60} />
      </div> */}


      <div className="flex justify-end my-24">
        <Button width={140} onClick={() => setStep(2)}>
          다음으로
        </Button>
      </div>
    </div>
  );
}

export default Step1;



const PaidTypeList = [
  { name: "유료", value: true },
  { name: "무료", value: false },
]

const TaxTagList = [
  { name: "ISBN", value: "ISBN" },
  { name: "UCI", value: "UCI" },
  { name: "ISSN", value: "ISSN" },
  { name: "ESN", value: "ESN" },
]
  
import { sample_pdf } from "@/assets";
import { useEffect, useState } from "react";
import { pdfjs, Document, Page as PdfPage } from "react-pdf";
import styled from "styled-components";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Container = styled.div`
  padding: 60px 100px;
`;

const Title = styled.div`
  min-width: 600px;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 60px;
`;

const DocumentContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0.5px 7px 24px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
`;


function Page () {
  const [pageNumber, setPageNumber] = useState(0);
  const [width, setWidth] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(numPages);
  }

  useEffect(() => {
    const resize = () => {
      setWidth(window.innerWidth - 520);
    }

    window.addEventListener("resize", resize);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    }
  }, []);
      

  return (
    <Container>
      <Title>CP사 어드민 사용 가이드</Title>
      <DocumentContainer>
        <Document file={sample_pdf} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(pageNumber), (el, index) => (
            <PdfPage key={index} pageNumber={index + 1} width={width} />
            ))}
        </Document>
      </DocumentContainer>
    </Container>
  );
}

export default Page;

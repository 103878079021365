import { fetchLabelAdd } from '@/api/label';
import { Button, LabelInput, Modal } from '@/components'
import { axiosAuth } from '@/lib/axios';
import { useState } from 'react'
import styled from 'styled-components'


const Container = styled.div`
  .c-828282 {
    color: #828282;
    line-height: 20px;
  }
`


export default function LabelAddModal ({
  isOpen,
  setIsOpen
}) {
  const [labelName, setLabelName] = useState('');
  
  async function submit() {
    // Fetch Create Writer
    const result = await fetchLabelAdd(labelName);
    
    // 레이블 생성 성공
    if(result) {
      alert('레이블이 생성되었습니다.');
      setLabelName('');
      setIsOpen(false);
      window.location.reload();
    } else {}
  } 

  return (
    <Container>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} height={400}>
        <div className='bold font-30 mb-20'>레이블 추가</div>
        <div className='c-828282 medium font-14 mb-20'>
          주의사항:<br/>
          1. 레이블명은 중복될 수 없습니다.<br/>
          2. <br/>
          {/* 3. 레이블이 생성 추가되면 support@oddnary.net으로 담당자와의 연결을 통해서만 수정할 수 있습니다.      */}
        </div>
        
        <LabelInput label={"레이블명"} required value={labelName} onChange={e => setLabelName(e.target.value)}/>
      
        <div className='w-full flex justify-end mt-30'>
          <Button onClick={submit}>레이블 추가</Button>
        </div>
      </Modal>
    </Container>
  ) 
}
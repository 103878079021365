import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { fetchOwnNovelList } from '@/api/novel';
import { fetchNovelDecisionApproveOne, fetchNovelDecisionList, fetchNovelDecisionRejectOne } from '@/api/decision';

const novelDecisionListState = atom({
  key: 'NovelDecisionList',
  default: {
    list: [], // 리스트 데이터
    totalPage: 0, // 총 페이지 수
    totalCount: 0, // 총 갯수
  },
});

export const pageState = atom({
  key: 'decisionPageState',
  default: 1,
});

export const countState = atom({
  key: 'decisionCountState',
  default: 10,
});

export const useNovelDecisionList = () => {
  const [novelDecisionList, setNovelDecisionList] = useRecoilState(novelDecisionListState);
  const [page, setPage] = useRecoilState(pageState);
  const [count, setCount] = useRecoilState(countState);

  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    console.log('useNovelDecisionList useEffect');
    const countParam = searchParams.get('count');
    const pageParam = searchParams.get('page');
    const searchParam = searchParams.get('search');
    const typeParam = searchParams.get('type');
    const approveParam = searchParams.get('approve');

    if (countParam) {
      setCount(Number(countParam));
    }
    if (pageParam) {
      setPage(Number(pageParam));
    }

    const getNovelOwnList = async (page, count) => {
      if (isLoading) return;
      setIsLoading(true);
      try {
        const data = await fetchNovelDecisionList(page, count, searchParam, typeParam, approveParam);
        setNovelDecisionList(data);
      } catch (error) {
        console.error('Failed to fetch novel genre list:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getNovelOwnList(page, count || 10);
  }, [searchParams, page, count, setCount, setPage, setNovelDecisionList]);



  const handlePage = (pageValue) => {
    setPage(pageValue);
    setSearchParams((prev) => {
      prev.set('page', pageValue);
      return prev;
    })
  }
  
  const handleCount = (countValue) => {
    setCount(countValue);
    setSearchParams((prev) => {
      prev.set('count', countValue);
      return prev;
    })
    handlePage(1);
  };


  const submitApprove = async ( selected ) => {
    if(isLoading) return;
    setIsLoading(true);

    try {
      console.log('Approve', selected);

      for (let i = 0; i < selected.length; i++ ) {
        await fetchNovelDecisionApproveOne(selected[i]);

        if(i === selected.length -1) alert('심사 등록이 완료되었습니다.') 
      }
    } catch (e) {
      alert('심사 등록에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  }



  const submitReject = async ( selected ) => {
    if(isLoading) return;
    setIsLoading(true);

    try {
      console.log('Reject', selected);

      for (let i = 0; i < selected.length; i++ ) {
        await fetchNovelDecisionRejectOne(selected[i]);

        if(i === selected.length -1) alert('심사 등록이 완료되었습니다.') 
      }
    } catch (e) {
      alert('심사 등록에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  }



  return {
    novelDecisionList,
    submitApprove,
    submitReject,
    page,
    count,
    isLoading,
    handlePage,
    handleCount,
  };
};

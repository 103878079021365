import { fetchCoinDetail, fetchRefundOne } from '@/api/coin';
import { Button } from '@/components';
import ButtonTitle from '@/components/ButtonTitle/ButtonTitle'
import { formatDateYYMD, formatNumber } from '@/utils/format';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  padding: 60px 100px;

`
const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 100px;
`;

const InfoBox = styled.div`
  display: flex;
  gap: 20px;
  font-size: 21px;
  font-weight: bold;

  .content {
    color: #636363;
    font-weight: normal;
  }
`;

const Name = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

export default function Page () {
  const { uid } = useParams();

  useEffect(() => {
    getData();
  }, [])

  const [detail, setDetail] = useState(null);
  const [coin, set_coin] = useState(null);
  const [total_coin, set_total_coin] = useState(null);
  const [used_coin, set_used_coin] = useState(null);


  async function getData() {
    const { 
      data,
      coin,
      total_coin,
      used_coin
    } =  await fetchCoinDetail(uid);

    console.log(data);
    setDetail(data);

    set_coin(coin);
    set_used_coin(used_coin);
    set_total_coin(total_coin);
  }

  function getPGFee(pgType) {
    switch (pgType) {
      default:
        return 30;
      case "TOSS_PAYMENT":
        return 3.3;
    }
  }
  
  async function submitRefund() {
    if (!confirm('환불처리를 완료하시겠습니까? (환불처리를 해도 유저에게 자동으로 입금되지 않습니다.)')) return;
    else {
      try {
        await fetchRefundOne(uid);
        alert('환불처리 되었습니다!')
        window.location.reload();
      } catch {
        alert('환불처리에 실패했습니다.')
      }
    }
  }

  function calculatePercentage(number, percent) {
    return Math.floor((number * (percent / 100)));
  }


  function getRefundPrice() {
    if(!detail) return 0;
    const feePercent = Number(getPGFee(detail?.pgType));

    const coinPrice = Math.floor((detail?.product_id?.price / detail?.product_id?.amount));

    const price = coinPrice * coin;

    console.log(feePercent)

    return calculatePercentage(price, 100 - feePercent);
  }

  return (
    <Container>
      <ButtonTitle backButton title={"결제 정보 상세"} containerSytle={{ marginBottom: '85px' }} />
    
      <InformationContainer>
        <div className="flex align-center gap-100">
          <Name>상품 정보</Name>
        </div>
        <InfoBox>
          <div>상품 이름</div>
          <div className="content">{detail?.product_id?.title}</div>
        </InfoBox>
        <InfoBox>
          <div>상품 가격</div>
          <div className="content">{formatNumber(detail?.product_id?.price)}원</div>
        </InfoBox>
        <InfoBox>
          <div>재화</div>
          <div className="content">{formatNumber(detail?.product_id?.amount)}스타</div>
        </InfoBox>
      </InformationContainer>
   
      <InformationContainer>
        <div className="flex align-center gap-100">
          <Name>결제 정보</Name>
        </div>
        <InfoBox>
          <div>결제일</div>
          <div className="content">{formatDateYYMD(detail?.created_date)}</div>
        </InfoBox>
        <InfoBox>
          <div>결제 PG사</div>
          <div className="content">{detail?.pgType}</div>
        </InfoBox>
        <InfoBox>
          <div>결제 고유 키</div>
          <div className="content">{detail?.paymentKey}</div>
        </InfoBox>
        <InfoBox>
          <div>결제 수수료</div>
          <div className="content">{getPGFee(detail?.pgType)}%</div>
        </InfoBox>
      </InformationContainer>

      <InformationContainer>
        <div className="flex align-center gap-100">
          <Name>환불 정보</Name>
          {!detail?.isRefund && <Button onClick={submitRefund}>환불</Button>}
        </div>
        
        <InfoBox>
          <div>환불 여부</div>
          <div className="content">{detail?.isRefund ? "O" : "X"}</div>
        </InfoBox>
        <InfoBox>
          <div>재화</div>
          <div className="content">{formatNumber(total_coin)}개</div>
        </InfoBox>
        <InfoBox>
          <div>환불 가능 재화</div>
          <div className="content">{formatNumber(coin)}개</div>
        </InfoBox>
        <InfoBox>
          <div>사용한 재화</div>
          <div className="content">{formatNumber(used_coin)}개</div>
        </InfoBox>
        <InfoBox>
          <div>환불 여부</div>
          <div className="content">{detail?.isRefund ? "O" : "X"}</div>
        </InfoBox>
        <InfoBox>
          <div>환불 처리 일시</div>
          <div className="content">{formatDateYYMD(detail?.refund_date)}</div>
        </InfoBox>
        <InfoBox>
          <div>환불 예상 금액</div>
          <div className="content">{formatNumber(getRefundPrice())}원</div>
        </InfoBox>
      </InformationContainer>
    </Container>
  )
}
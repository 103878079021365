import { Dropdown, Pagination, Table } from "@/components";
import styled from "styled-components";

const Container = styled.div`
  padding: 60px 100px;
`;

const Title = styled.div`
  min-width: 600px;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 60px;
`;

const LicenseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
  align-items: start;
`;

const LicenseTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const LicenseContent = styled.div`
  display: flex;
  gap: 10px;
  font-size: 16px;

  .subTitle {
    font-weight: bold;
  }
`;

function Page() {
  return (
    <Container>
      <Title>오픈소스 라이센스</Title>
      <Table
        id="open-source-list"
        className="mb-100"
        header={[{ name:'', minWidth: '900px' }]}
        datas={
          [
            {
              renders: [
                {
                  render: <License />,
                }
              ]
            },
            {
              renders: [
                {
                  render: <License />,
                }
              ]
            },
            {
              renders: [
                {
                  render: <License />,
                }
              ]
            },
            {
              renders: [
                {
                  render: <License />,
                }
              ]
            },
            {
              renders: [
                {
                  render: <License />,
                }
              ]
            }
          ]
        }
      />

      <div className="flex">
        <Dropdown options={[{ name: "10개", value: 10 }, { name: "20개", value: 20 }, { name: "30개", value: 30 }]} select={{ name: "10개", value: 10 }} />
        <Pagination currentPage={1} totalPage={100} />
      </div>
    </Container>
  );
}

function License() {
  return (
    <LicenseContainer>
      <LicenseTitle>minini</LicenseTitle>
      <LicenseContent>
        <div className="subTitle">License명:</div>
        <div>Apache License 2.0</div>
      </LicenseContent>
      <LicenseContent>
        <div className="subTitle">용도:</div>
        <div>WebRTC는 간단한 API를 통해 브라우저 및 모바일 응용 프로그램에 실시간 통신 (RTC) 기능을 제공 하는 무료 공개 프로젝트 입니다</div>
      </LicenseContent>
      <LicenseContent>
        <div className="subTitle">CopyRight:</div>
        <div>Copyright 2015, Thiadmer Riemersma, CompuPhase</div>
      </LicenseContent>
    </LicenseContainer>
  );
}

export default Page;

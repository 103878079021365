import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import { LabelInput } from '..';
import { useClickOutside } from '@/hooks';
import { fetchCPSearchList } from '@/api/cp';


const Container = styled.div`

  .cp_menu {
    width: 100%;
    background-color: #fff;
    border: 1px solid #b9b9b9;
    border-radius: 7px;
    position: absolute;
    z-index: 999;
    top: calc(100% + 4px);
    left: 0;
  }

  .cp_list {

    .cp_item {
      width: 100%;
      padding: 10px;
    }
  }

`


export default function CPInput ({ setOwner }) {
  const [isCPOpen, setIsCPOpen] = useState(false);

  const [searchInput, setSearchInput] = useState('');
  const [cpList, setCPList] = useState([]);
  

  useEffect(() => {
    if(searchInput) {
      // Fetch Writer List
      getData();
    }
  }, [searchInput])

  async function getData() {
    const data = await fetchCPSearchList(searchInput);
    
    setCPList(data);
  }

  const ref = useRef(null);
  useClickOutside(ref, () => setIsCPOpen(false))


  function addCP(cpData) {
    setSearchInput('');
    setIsCPOpen(false);
    setCPList([]);
    setOwner(cpData);
  }


  return (
    <>
      <Container className='relative' ref={ref}>
        <LabelInput 
          label={"CP사 이름"} 
          value={searchInput} 
          onChange={e => setSearchInput(e.target.value)} 
          required
          onClick={() => setIsCPOpen(true)}
        />

        
        

        {(isCPOpen || cpList.length > 0) && 
          <div className='cp_menu'>
            {cpList.length > 0 &&
              <div className='cp_list'>
                {cpList.map((item, idx) => (
                  <div className='cp_item pointer' key={`cp-${item.name}-${idx}`} onClick={() => addCP(item)}>
                    <div className='bold'>{item.name}</div>
                  </div>
                ))}
              </div>
            }
          </div>
        }


      </Container>
    </>
  )
}
import { atom, useRecoilState, useResetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchFileToUri } from '@/api/file';
import { fetchNewNovelData } from '@/api/novel';
import { validateFile, validateLength, validateNumber } from '@/utils/validate';
import { fetchAdminCPLabelList, fetchOwnLabelList } from '@/api/label';

const AgeGradeList = [
  { name: "전체이용가", value: "전체이용가" },
  { name: "15세 이용가", value: "15세 이용가" },
  { name: "19세 이용가", value: "19세 이용가" },
]

const SalesTypeList = [
  { name: "해당없음", value: "해당없음" },
  { name: "독점", value: "독점" },
  { name: "선공개", value: "선공개" },
]

const TaxTypeList = [
  { name: "면세", value: "면세" },
  { name: "과세", value: "과세" },
]

const PaidTypeList = [
  { name: "유료", value: true },
  { name: "무료", value: false },
]

const TaxTagList = [
  { name: "ISBN", value: "ISBN" },
  { name: "UCI", value: "UCI" },
  { name: "ISSN", value: "ISSN" },
  { name: "ESN", value: "ESN" },
]
  
const RentBenefitList = [
  { name: "없음", value: 0 },
  { name: "10화 무료", value: 10 },
  { name: "15화 무료", value: 15 },
]

const novelCreateDataState = atom({
  key: 'novelCreateData',
  default: {
    isOwnAdmin: true,
    title: '', // 제목
    owner: null, // 소유한 CP 사. 없을 수 있음
    writer_list: [], // 작가 리스트
    book_cover: '', // 표지
    book_cover_file: null, // 표지 ( 파일 )
    keywords: [], // 키워드
    genre: {name: "장르", value: ''}, // 장르
    summary: '', // 줄거리 = 작품소개
    ageGrade: AgeGradeList[0], // 연령 등급
    taxTag: TaxTagList[0], // [ isbn, uci, issn, esn ] 사라져도 상관 없어짐
    taxCode: '', // 면세 코드
    serialCycle: [false, false, false, false, false, false, false], // 일 ~ 토요일 7개짜리 배열
    taxType: TaxTypeList[0], // 면세 / 과세 여부
    isPaid: PaidTypeList[0],
    regularPrice: 100, // 전자책 정가
    salesType: SalesTypeList[0], // 판매 유형
    rentBenefit: RentBenefitList[0],
    hasCompletion: false,
    isBook: false,
    non_rental_episode_count: 5
  },
});

export const useNovelCreateData = () => {
  const [novelCreateData, setNovelCreateData] = useRecoilState(novelCreateDataState);
  
  const [isLoading, setIsLoading] = useState(false);

  const [labelList, setLabelList] = useState([]);
    
  const navigate = useNavigate()



  useEffect(() => {
    if(novelCreateData.isOwnAdmin) getData();
    else if(novelCreateData?.owner) getDataCP();
  }, [novelCreateData.isOwnAdmin, novelCreateData.owner]);


  async function getData() {
    const LabelList = await fetchOwnLabelList();
    setLabelList(LabelList);
  }

  async function getDataCP() {
    const LabelList = await fetchAdminCPLabelList(novelCreateData?.owner?._id);
    setLabelList(LabelList);
  }



  const handleChange = (key, value) => {
    // validation check
    switch(key) {
      case 'title':
        if(!validateLength(value, 50)) return alert('제목은 50자 이내로 작성해주세요.'); // 필요시 length 수정
        break;
      case 'summary':
        if(!validateLength(value, 5000)) return alert('작품소개는 5000자 이내로 작성해주세요.'); // 필요시 length 수정
        break;
      case 'taxCode':
        // Todo: 면세 코드 validation check
        break;
      case 'regularPrice':
        if(!validateNumber(value)) return alert('숫자만 입력해주세요.');
        break;
      case 'book_cover_file':
        if(!validateFile(value, 1024 * 1024 * 30)) return alert('이미지 파일은 30MB 이하로 업로드해주세요.'); // 필요시 size 수정
        break;
      default:
        break;
    }

    setNovelCreateData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  

  
  const pushWriterList = (writerData) => {
    let copy_writer_list = [...novelCreateData.writer_list];

    if(copy_writer_list.length <= 2) {
      copy_writer_list.push(writerData);
      
      setNovelCreateData((prevData) => ({
        ...prevData,
        writer_list: copy_writer_list,
      }));
    } else {
      return alert('공동 작가는 3명을 넘을 수 없습니다.');
    }
  }


  const handleSerialCycle = (idx) => {
    let copySerialCycle = [...novelCreateData.serialCycle];

    copySerialCycle[idx] = !copySerialCycle[idx];
    
    setNovelCreateData((prevData) => ({
      ...prevData,
      serialCycle: copySerialCycle,
    }));
  }


  const keywordSelect = (item) => {
    let copyKeywordSelect = [...novelCreateData.keywords];

    if(novelCreateData.keywords.find(fit => fit.name === item.name && fit.keywordType === item.keywordType)) {
      copyKeywordSelect = copyKeywordSelect.filter(fit => fit._id !== item._id);
    }
    else copyKeywordSelect.push(item);
    

    setNovelCreateData((prevData) => ({
      ...prevData,
      keywords: copyKeywordSelect,
    }));
  }



  async function submit() {
    if(isLoading) return;
    setIsLoading(true);

    try{
      const uuid = nanoid(40);

      // validation check
      if (novelCreateData.title.length === 0) {
        setIsLoading(false);
        return alert('제목을 입력해주세요.');
      }
      if (!validateLength(novelCreateData.title, 50)) {
        setIsLoading(false);
        return alert('제목은 50자 이내로 작성해주세요.');
      }
      if (novelCreateData.writer_list.length === 0) {
        setIsLoading(false);
        return alert('작가를 입력해주세요.');
      }
      if (novelCreateData.book_cover_file === null) {
        setIsLoading(false);
        return alert('썸네일을 입력해주세요.');
      }
      if (!validateFile(novelCreateData.book_cover_file, 1024 * 1024 * 30)) {
        setIsLoading(false);
        return alert('이미지 파일은 30MB 이하로 업로드해주세요.');
      }
      if (novelCreateData.keywords.length === 0) {
        setIsLoading(false);
        return alert('키워드를 입력해주세요.');
      }
      if (novelCreateData.genre.value === '') {
        setIsLoading(false);
        return alert('장르를 입력해주세요.');
      }
      if (novelCreateData.summary.length === 0) {
        setIsLoading(false);
        return alert('작품소개를 입력해주세요.');
      }
      if (!validateLength(novelCreateData.summary, 5000)) {
        setIsLoading(false);
        return alert('작품소개는 5000자 이내로 작성해주세요.');
      }
      if (novelCreateData.taxCode.length === 0) {
        setIsLoading(false);
        return alert('면세 코드를 입력해주세요.');
      }
      if (novelCreateData.regularPrice.length === 0) {
        setIsLoading(false);
        return alert('전자책 정가를 입력해주세요.');
      }
      if (!validateNumber(novelCreateData.regularPrice)) {
        setIsLoading(false);
        return alert('숫자만 입력해주세요.');
      }

      // 이미지 처리
      try {
        await fetchFileToUri(`novel/${uuid}`, 'book_cover', novelCreateData.book_cover_file);
      } catch(e) {
        console.error(e);
        throw new Error('이미지 업로드에 실패했습니다.');
      }

      let new_novel = { ...novelCreateData };

      new_novel.uuid = uuid;
      new_novel.book_cover = `novel/${uuid}/book_cover.png`;
      new_novel.ageGrade = novelCreateData.ageGrade.value;
      new_novel.cpLabel = novelCreateData.cpLabel._id;
      new_novel.genre = novelCreateData.genre.value;
      new_novel.isPaid = novelCreateData.isPaid.value;
      new_novel.salesType = novelCreateData.salesType.value;
      new_novel.taxTag = novelCreateData.taxTag.value;
      new_novel.taxType = novelCreateData.taxType.value;
      new_novel.rentBenefit = novelCreateData.rentBenefit.value;

      // 소유자가 존재할 경우
      if(new_novel?.owner) new_novel.owner = new_novel.owner._id;

      new_novel.writer_list = novelCreateData.writer_list?.map(item => item._id);
      new_novel.keywords = novelCreateData.keywords.map(item => item._id);


      // Fetch Data
      try {
        await fetchNewNovelData(new_novel);
        window.history.back();
      } catch (e) {
        console.error(e);
        throw new Error('작품 등록에 실패했습니다.');
      }

    } catch(e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  }


  return {
    AgeGradeList,
    TaxTypeList,
    SalesTypeList,
    PaidTypeList,
    TaxTagList,
    novelCreateData,
    RentBenefitList,
    isLoading,
    labelList,
    submit,
    keywordSelect,
    handleChange,
    handleSerialCycle,
    pushWriterList
  }
}
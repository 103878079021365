import { accordion_down, accordion_up } from "@/assets/components";
import { useState } from "react"
import styled from "styled-components";

const Container = styled.div`
  border-bottom: 1px solid #000000;
  color: #575757;

  &:first-child {
    border-top: 1px solid #000000;
  }


  .arrow-position {
    top: 15px;
    height: 24px;
    width: 24px;
    right: 16px;
  }
`


export const AccordionMenu = ({ content, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container className='px-20 py-16 w-full relative font-16' onClick={() => setIsOpen(chk => !chk)}>
      <div className="flex justify-between align-center">
        <div>{children}</div>
        <div className="bold p-16">상세</div>
      </div>
      {isOpen && <div className="mt-14">{content}</div>}
    </Container>
  )
}
import { axiosAuth } from "@/lib/axios";

export const fetchNewCPData = async (fetchData) => {
  try {
    const { data } = await axiosAuth.post('/cpauth/register', {
      ...fetchData
    });

    alert('CP사를 생성했습니다.');

    return data;
  } catch (e) {
    alert('CP사 생성에 실패했습니다.')
    throw e;
  }
}

export const fetchUpdateCPData = async (fetchData) => {
  try {
    const { data } = await axiosAuth.post('/cp/admin/edit', {
      ...fetchData
    });

    alert('CP사 정보를 수정했습니다.');

    return data;
  } catch (e) {
    alert('CP사 정보를 수정에 실패했습니다.')
    throw e;
  }
}


export const fetchUpdateStatusCPData = async (cp_id, value) => {
  try {
    const { data } = await axiosAuth.post('/cp/admin/edit/status', {
      cp_id, value
    });

    alert('CP사 출판 상태를 수정했습니다.');

    return data;
  } catch (e) {
    alert('CP사 출판 상태 변경에 실패했습니다.')
    throw e;
  }
}


// CP 리스트
export const fetchCpList = async (page, count, search, type) => {
  const query = [];
  if (search) query.push(`search=${search}`);
  if (type) query.push(`type=${type}`);
  const { data } = await axiosAuth.get(`/cp/admin/list/${page}/${count}?${query.join('&')}`);
  return data;
}

export const fetchCpDetail = async (id) => {
  const { data } = await axiosAuth.get(`/cp/admin/detail/${id}`);
  return data;
}


export const fetchResetPasswordCP = async (cp_id, pw) => {
  try {
    const { data } = await axiosAuth.post('/cp/admin/reset/password', {
      cp_id,
      password: pw
    });

    alert('CP사 비밀번호를 초기화했습니다.');

    return data;
  } catch (e) {
    alert('CP사 비밀번호 초기화에 실패했습니다.')
    throw e;
  }
}

export const fetchCPSearchList = async (search_text) => {
  const { data } = await axiosAuth.get(`/cp/admin/search/list/${search_text}`);
  return data;
}
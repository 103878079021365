import styled from 'styled-components'
import { useEffect, useState } from 'react';
import PublishingStatus from '@/components/Dashboard/PublishingStatus';
import MonthySalesStatistics from '@/components/Dashboard/MonthySalesStatistics';
import SalesStatusByContent from '@/components/Dashboard/SalesStatusByContent';
import SalesStatusByGenre from '@/components/Dashboard/SalesStatusByGenre';
import VisitorDay from '@/components/Dashboard/VisitorDay';
import VisitorMonth from '@/components/Dashboard/VisitorMonth';
import { fetchDashBoardNovelStatus, fetchDashBoardSalesStatus, fetchDashBoardSalesStatusGenre, fetchDashBoardSalesStatusMonth } from '@/api/dashboard';


//main 컨테이너
const ContainerMain = styled.div`
  padding:  60px 100px;
  background-color: #f7f7f7;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`

export default function MainPage () {
  const [isLoading, setIsLoading] = useState(false);

  const [salesList, setSalesList] = useState([]);
  const [genreList, setGenreList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setIsLoading(true);
    const salesList = await fetchDashBoardSalesStatus();
    const genreList = await fetchDashBoardSalesStatusGenre();
    const monthList = await fetchDashBoardSalesStatusMonth();
    const statusList = await fetchDashBoardNovelStatus();

    setSalesList(salesList);
    setGenreList(genreList);
    setMonthList(monthList);
    setStatusList(statusList);
  }

  return (
    <ContainerMain>
      <PublishingStatus list={statusList}/>
      <MonthySalesStatistics list={monthList}/>
      {/* <VisitorDay/>
      <VisitorMonth/> */}
      <SalesStatusByContent list={salesList}/>
      <SalesStatusByGenre list={genreList}/>
    </ContainerMain>
  )
}
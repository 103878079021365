import { axiosAuth } from '@/lib/axios';

export const fetchGetUserLogList = async (
  user_id,
  type, // ["정지", "수정"]
  page,
  count,
) => {
  const { data } = await axiosAuth.get(`/logs/user/list/${user_id}/${type}/${page}/${count}`);
  
  return data;
}
import { S3EpubUpload, S3ImageUpload } from "@/lib/AWSFileUpload";
import { axiosAuth } from "@/lib/axios";

export const fetchFileToUri = async (
  foldername,
  filename,
  file
) => {
  return new Promise(async (resolve, reject) => {
    await S3ImageUpload(file, filename + '.png', foldername)
    resolve(file, filename + '.png', foldername);
  });
  
  
  // const formData = new FormData();
  // formData.append('file', file);
  // formData.append('foldername', `${foldername}`);
  // formData.append('filename', `${filename}.png`);

 
  // const { data } = await axiosAuth.post(`/upload/single`, formData, {
  //   headers: { 'content-type': 'multipart/form-data' },
  // });

  // return data;
}
  


export const fetchEpubFileToUri = async (
  foldername,
  filename,
  file
) => {
  return new Promise(async (resolve, reject) => {
    await S3EpubUpload(file,filename + '.epub',  "epub/" + foldername)
    resolve(file, filename + '.epub', "epub/" + foldername);
  });


  // const formData = new FormData();
  // formData.append('file', file);
  // formData.append('foldername', `${foldername}`);
  // formData.append('filename', `${filename}.epub`);
 
  // const { data } = await axiosAuth.post(`/upload/epub`, formData, {
  //   headers: { 'content-type': 'multipart/form-data' },
  // });

  // return data;
}
  



export const fetchAudioBookFileToUri = async (
  foldername,
  filename,
  file
) => {
  return new Promise(async (resolve, reject) => {
    await S3EpubUpload(file, filename + '.mp3', foldername)
    resolve(file, filename + '.mp3', foldername);
  });
}
  
import styled from "styled-components";
import { Table } from "..";
import { formatDateYMD } from "@/utils/format";

const HEADERS = [
  { name: "항목", minWidth: 200 },
  { name: "처리일", minWidth: 140 },
  { name: "열람 여부", minWidth: 140 },
  { name: "동의 여부", minWidth: 40 },
  { name: "VERSION", minWidth: 100 },
];


const TotalCount = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
`;

function PolicyHistory({
  agreedToServicePolicy,
  agreedToPrivacyPolicy
}) {
  return (
    <div>
      <TotalCount>전체 {agreedToPrivacyPolicy.length + agreedToServicePolicy.length}개</TotalCount>

      <Table
        id="sale-list"
        className="mb-50"
        header={HEADERS}
        datas={
          [
            ...agreedToServicePolicy.map(item => ({ ...item, source: '서비스 이용약관' })),
            ...agreedToPrivacyPolicy.map(item => ({ ...item, source: '개인정보처리방침' }))
          ]
          .sort((a, b) => new Date(b.agreed_date) - new Date(a.agreed_date))
          .map((data) => ({
            id: data.id,
            renders: [
              { render: () => data.source },
              { render: () => formatDateYMD(data.agreed_date) },
              { render: () => data.hasReadAndAgreedTerms ? "열람" : "미열람" },
              { render: () => data.agreed ? "동의" : "거부" },
              { render: () => data.version },
            ],
          }))
        }
      />

    </div>
  );
}

export default PolicyHistory;

import { book_cover_sample } from '@/assets';
import { Button, Dropdown, LabelInput, Modal } from '@/components';
import { useCPCreateData } from '@/stores/cpCreate'
import { useState } from 'react';
import styled from 'styled-components'
import{ nanoid} from 'nanoid';

const Container = styled.div`
  padding: 60px 100px;

  .section_line {
    width: 100%;
    height: 2px;
    background-color: #000000;
  }

  .imageUpload {
    width: 155px;
    height: 246px;
    background-color: #fff;
    border-radius: 10px;
    
    .book_cover {
      width: 100%;
      height: 100%;
    }
  }
`


export default function Page () {

  const {
    handleChange,
    submit,
    cpCreateData,
    isLoading,
    BankList,
    ContractTypeList
  } = useCPCreateData();

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);

  async function onSubmit() {
    const new_pw = nanoid(12);
    console.log(new_pw)
    setPassword(new_pw)
    setEmail(cpCreateData.email);


    try {
      setTimeout(async () => {
        await submit(new_pw); 
        setIsModalOpen(true);
      }, 300)
    } catch {

    }
  }

  const copyToClipboard = () => {
    const textToCopy = `ID: ${email}\nPW: ${password}`;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        alert('클립보드에 복사되었습니다!');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <Container>
      <Modal isOpen={isModalOpen} onClose={() => {}} height={520}>
        <div className='flex-col flex-center'>
          <div className='bold font-32 mb-40'>CP사 계정 생성 성공</div>

          <div className='medium font-20'>ID: {email}</div>
          <div className='medium font-20'>PW: {password}</div>

          <div className='text-center bold mt-40 mb-20'>위 계정을 복사해 CP사로 보내주세요.</div>
          <Button onClick={copyToClipboard}>계정 정보 복사</Button>

          <div className='bold font-20 text-center mt-60 mb-40'>
            계정정보는 당 cp사 외에는 그 어느곳에도 공유하지마세요.<br/>
            이 화면 외에는 계정정보를 복사할 수 없으니 주의해주세요.  
          </div>


          <Button onClick={() => {
            setIsModalOpen(false)
            window.location.href = '/cp/list'
          }}>닫기</Button>
        </div>
      
      </Modal>

      <div className='bold font-30 mb-88'>CP사 추가</div>
      <div className='bold font-24'>기본 정보</div>
      <div className='section_line my-24'/>
      
      <LabelInput value={cpCreateData.name} onChange={e => handleChange('name', e.target.value)} label={"상호명"} required/>
      
      <div className='mt-32 mb-10'/>
      
      <LabelInput value={cpCreateData.business_number} onChange={e => handleChange('business_number', e.target.value)} label={"사업자 번호"} required/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          계약 유형 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <Dropdown options={ContractTypeList} select={cpCreateData.contractType} setSelect={item => handleChange('contractType', item)}/>
        </div>
      </>

      <div className='mt-32 mb-10'/>
      
      <LabelInput value={cpCreateData.company_email} onChange={e => handleChange('company_email', e.target.value)} label={"대표 이메일"} required/>

      <div className='mt-32 mb-10'/>
      
      <LabelInput value={cpCreateData.phone} onChange={e => handleChange('phone', e.target.value)} label={"대표 연락처"} required/>

      <div className='mt-32 mb-10'/>
      
      <LabelInput value={cpCreateData.ceo_name} onChange={e => handleChange('ceo_name', e.target.value)} label={"대표이사명"} required/>

      <div className='mt-32 mb-10'/>
      
      <LabelInput value={cpCreateData.email} onChange={e => handleChange('email', e.target.value)} label={"CP사 로그인용 Email"} required/>

      <div className='bold font-24 mt-60'>정산 정보</div>
      <div className='section_line my-24'/>

      <>
        <div className='bold font-18 mt-32 mb-10'>
          정산 은행 <span className='c-red'>*</span>
        </div>

        <div className='' style={{ width: '180px'}}>
          <Dropdown options={BankList} select={cpCreateData.bank_name} setSelect={item => handleChange('bank_name', item)}/>
        </div>
      </>

      <div className='mt-32 mb-10'/>
      
      <LabelInput value={cpCreateData.bank_number} onChange={e => handleChange('bank_number', e.target.value)} label={"정산 계좌 번호"} required/>

      <div className='mt-32 mb-10'/>
      
      <LabelInput value={cpCreateData.bank_account_owner} onChange={e => handleChange('bank_account_owner', e.target.value)} label={"정산 계좌 예금주"} required/>

      <div className='bold font-24 mt-60'>파일</div>
      <div className='section_line my-24'/>
      <>
        <div className='bold font-18 mt-32 mb-24'>
          통장사본 파일 <span className='c-red'>*</span>
        </div>

        <div className=''>
          <input type="file" accept="image/*" id="imageUpload1" style={{ display: "none" }} onChange={(e) => handleChange('bank_account_image_file_data', e.target.files[0])}/>
          
          <label htmlFor='imageUpload1' className='imageUpload flex-center flex-col w-full'>
            <img className='book_cover  pointer' src={(cpCreateData.bank_account_image_file_data ? URL.createObjectURL(cpCreateData.bank_account_image_file_data) : null) || book_cover_sample}/>
          </label>
        </div>
      </>



      <>
        <div className='bold font-18 mt-32 mb-24'>
          사업자등록증 파일 <span className='c-red'>*</span>
        </div>

        <div className=''>
          <input type="file" accept="image/*" id="imageUpload2" style={{ display: "none" }} onChange={(e) => handleChange('business_certificate_file_data', e.target.files[0])}/>
          
          <label htmlFor='imageUpload2' className='imageUpload flex-center flex-col w-full'>
            <img className='book_cover  pointer' src={(cpCreateData.business_certificate_file_data ? URL.createObjectURL(cpCreateData.business_certificate_file_data) : null) || book_cover_sample}/>
          </label>
        </div>
      </>

      <div className='mt-80'/>
      <Button style={{width: '100%'}} isLoading={isLoading} onClick={onSubmit}>생성 하기</Button>
      <div className='mt-100'/>
    
    </Container>
  )
}
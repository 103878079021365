import { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { CheckBox } from "..";

const CustomTable = styled.div`
  overflow-x: auto;
`;

const TableHeader = styled.div`
  display: flex;
  min-width: fit-content;
  border-bottom: 2px solid #000;
`;

const TableHeaderItem = styled.div`
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  flex: ${({ minWidth = 100 }) => `1 1 ${minWidth}px`};
  min-width: ${({ minWidth = 100 }) => `${minWidth}px`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableBody = styled.div`
`;

const TableRow = styled.div`
  display: flex;
  min-width: fit-content;
  border-bottom: 1px solid #e2e2e2;
`;

const TableBodyItem = styled.div`
  padding: 12px;
  font-size: 16px;
  text-align: center;
  flex: ${({ minWidth = 100 }) => `1 1 ${minWidth}px`};
  min-width: ${({ minWidth = 100 }) => `${minWidth}px`};
  word-break: break-all;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Table({ id, className, header, datas, preDatas, useDragDrop, changeOrder, tableStyle, selected, setSelected, headerAlign, bodyAlign}) {
  const dragImg = useRef(null);
  const lineDiv = useRef(null);

  const selectedAll = useMemo(() => {
    if(!selected) return false;
    return datas.every(({ id }) => selected.includes(id));
  }, [selected, datas]);

  useEffect(() => {
    const handleDrop = (e) => {
      lineDiv.current?.remove();
      // dragImg.current?.remove();
      dragImg.current.style.opacity = 1;
    }

    const handleDragOver = (e) => {
      e.preventDefault();

      lineDiv.current.remove();
    }

    window.addEventListener("drop", handleDrop);
    window.addEventListener("dragover", handleDragOver);
    return () => {
      window.removeEventListener("drop", handleDrop);
      window.removeEventListener("dragover", handleDragOver);
    }
  }, []);

  return (
    <CustomTable id={id} style={{...tableStyle}} className={className} >
      <TableHeader>
        {useDragDrop && <TableHeaderItem minWidth={50} />}
        {selected && (
          <TableHeaderItem minWidth={50} onClick={(e) => {
            e.stopPropagation();
          }}>
            <CheckBox
              selected={selectedAll}
              onClick={(e) => {
                e.stopPropagation();
                if(selectedAll) {
                  setSelected(selected.filter((id) => !datas.map(({ id }) => id).includes(id)));
                } else {
                  setSelected([...selected, ...datas.map(({ id }) => id).filter((id) => !selected.includes(id))]);
                }
              }}
            />
          </TableHeaderItem>
        )}
        {header.map(({ name, minWidth }, index) => (
          <TableHeaderItem style={{ justifyContent: headerAlign }} key={name + index} minWidth={minWidth}>{name}</TableHeaderItem>
        ))}
      </TableHeader>
      
      {
        preDatas && (
          <TableHeader>
            {useDragDrop && <TableHeaderItem minWidth={50} />}
            {selected && <TableHeaderItem minWidth={50} />}
            {preDatas.map((preData, preDataIndex) => (
              <TableHeaderItem key={preData + preDataIndex} minWidth={header[preDataIndex]?.minWidth}>{preData}</TableHeaderItem>
            ))}
          </TableHeader>
        )
      }

      <TableBody>
        {datas?.map((data, index) => {
          const { id: dataId, renders, onClick } = data;
          return (
            <TableRow
              id={`${id}-table-row-${dataId ?? index}`}
              key={`${id}-table-row-${dataId ?? index}`}
              onClick={onClick}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();

                // 50% 지점에 따라서 변경될 위치를 미리 보여줌
                // 보여주는 방법은 border를 추가하는 방법으로 함
                const hoverEl = e.currentTarget;
                const hoverRect = hoverEl.getBoundingClientRect();
                const hoverCenter = hoverRect.y + hoverRect.height / 2;
                const isHoverNext = e.clientY > hoverCenter;
                const isHoverPrev = e.clientY < hoverCenter;
                let hoverTop = 0;
                let hoverBottom = 0;

                if (isHoverNext) {
                  hoverTop = hoverRect.y + hoverRect.height - 2;
                  hoverBottom = hoverRect.y + hoverRect.height;

                  // 가이드라인을 추가
                  hoverEl.parentNode.insertBefore(lineDiv.current, hoverEl.nextSibling);
                } else if (isHoverPrev) {
                  hoverTop = hoverRect.y;
                  hoverBottom = hoverRect.y + 2;

                  // 가이드라인을 추가
                  hoverEl.parentNode.insertBefore(lineDiv.current, hoverEl);
                }
              }}

              onDrop={(e) => {
                e.preventDefault();
                // 가이드 라인 제거
                lineDiv.current.remove();
                // 드래그 이미지 제거
                // dragImg.current.remove();

                // 드래그한 인덱스
                const dragIndex = +e.dataTransfer.getData("text/plain");
                
                // 드롭 위치에 따라 (50% 기준) 드래그한 인덱스를 이동
                const targetIndex = index;
                const targetRect = e.currentTarget.getBoundingClientRect();
                const targetCenter = targetRect.y + targetRect.height / 2;
                const isNext = e.clientY > targetCenter;
                
                if(targetIndex === dragIndex) {
                  changeOrder(dragIndex, dragIndex);
                } else if(targetIndex < dragIndex) {
                  changeOrder(dragIndex, isNext ? targetIndex + 1 : targetIndex);
                } else {
                  changeOrder(dragIndex, !isNext ? targetIndex -1 : targetIndex);
                }

                dragImg.current.style.opacity = 1;
              }}
            >
              {useDragDrop && (
                <TableBodyItem minWidth={50}
                  draggable={useDragDrop}
                  onDragStart={(e) => {
                    // 타겟 인덱스
                    e.dataTransfer.setData("text/plain", index);
                    const target = document.getElementById(`${id}-table-row-${dataId ?? index}`);
                    dragImg.current = target;
                    dragImg.current.style.opacity = 0.5;
      
                    e.dataTransfer.setDragImage(dragImg.current, 0, 0);
      
                    // 드래그 이미지가 움직이는 위치를 보여주기 위한 가이드라인 준비
                    lineDiv.current = document.createElement("div");
                    lineDiv.current.style.width = "100%";
                    lineDiv.current.style.height = "2px";
                    lineDiv.current.style.backgroundColor = "black";

                    e.dataTransfer.effectAllowed = "move";
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-list"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 2.5a.5.5 0 0 1 .5-.5h14a.5.5 0 0 1 0 1H1.5a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h14a.5.5 0 0 1 0 1H1.5a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h14a.5.5 0 0 1 0 1H1.5a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
                </TableBodyItem>
              )}

              {selected && (
                <TableBodyItem minWidth={50} onClick={(e) => {
                  e.stopPropagation();
                }}>
                  <CheckBox
                    selected={selected.includes(dataId)}
                    onClick={(e) => {
                      e.stopPropagation();
                      const isSelected = selected.includes(dataId);
                      if(isSelected) {
                        setSelected(selected.filter((id) => id !== dataId));
                      } else {
                        setSelected([...selected, dataId]);
                    }}
                  } />
                </TableBodyItem>
              )}
              
              {
                renders.map(({ id: renderId, render: Render }, renderIndex) => {
                  return (
                    <TableBodyItem style={{ justifyContent: bodyAlign }} key={`${id}-table-row-${dataId ?? index}-item-${renderId ?? renderIndex}`} minWidth={header[renderIndex]?.minWidth}>
                      {typeof Render === "function" ? <Render index={renderIndex} /> : Render}
                    </TableBodyItem>
                  )
                })
              }
            </TableRow>
          )
        })}
      </TableBody>
    </CustomTable>
  );
}

export default Table;
import styled from "styled-components";
import { Button, DivideBox, Dropdown, Pagination, Table } from "..";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchCPEpisodeDecisionListAll, fetchCPNovelDecisionListAll } from "@/api/decision";
import { formatDateYMD } from "@/utils/format";


const HEADERS = [
  { name: "승인 요청일", minWidth: 110 },
  { name: "승인 항목", minWidth: 100 },
  { name: "요청자", minWidth: 100 },
  { name: "대상 항목", minWidth: 180 },
  { name: "내용상세", minWidth: 250 },
  // { name: "비속어 유무", minWidth: 100 },
  // { name: "특이사항", minWidth: 100 },
  // { name: "성인 콘텐츠 유무", minWidth: 100 },
  // { name: "요청사항,비고 유무", minWidth: 100 },
  { name: "승인현황", minWidth: 100 },
  { name: "취소여부", minWidth: 100 },
];


const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
`

function UpdateHistory({ cpInfo }) {
  // 소설 목록
  const [list1, setList1] = useState([])
  const [page1, setPage1] = useState(1);
  const [totalPage1, setTotalPage1] = useState(1);
  const [totalCount1, setTotalCount1] = useState(0);

  async function getData1() {
    const { list, total, totalCount } = await fetchCPNovelDecisionListAll(cpInfo._id, page1);

    setList1(list);
    setTotalCount1(totalCount);
    setTotalPage1(total);
  }


  // 회차 목록
  const [list2, setList2] = useState([])
  const [page2, setPage2] = useState(1);
  const [totalPage2, setTotalPage2] = useState(1);
  const [totalCount2, setTotalCount2] = useState(0);

  async function getData2() {
    const { list, total, totalCount } = await fetchCPEpisodeDecisionListAll(cpInfo._id, page2);

    setList2(list);
    setTotalCount2(totalCount);
    setTotalPage2(total);
  }



  useEffect(() => {
    getData1()
  }, [page1])

  
  useEffect(() => {
    getData2()
  }, [page2])

  return (
    <div>
      <Title>작품 승인 이력</Title>

      <Table
        id="novel-decision-list"
        className="mb-60"
        header={HEADERS}
        datas={list1?.map((data, index) => {
          return {
            id: data._id,
            renders: [
              { id: "updatedAt", render: <div className="exbold">{formatDateYMD(data.created_date)}</div> },
              { id: "approve", render: data?.decisionType === 0 ? "작품 수정" : "작품 등록" },
              { id: "requestor", render: data?.request_cp_user?.name },
              { id: "targetItem", render: data?.target_novel?.title },
              { id: "itemDetail", render: <div className="bold underline pointer">{data.decisionType === 0 ? '작품 정보 수정' : '작품 신규 등록' }</div>},
              { id: "approval", render: ["대기중", "승인", "반려"][data.hasApproved] },
              { id: "isCancelled", render: data?.isCancelled ? "취소됨" : "" },
            ],
          }
        })}
      />

      <div className="w-full flex-center mb-80">
        <Pagination
          currentPage={page1}
          totalPage={totalPage1}
          onClickPage={setPage1}
        />
      </div>










      <Title>회차 승인 이력</Title>



      <Table
        id="novel-list"
        className="mb-60"
        header={HEADERS}
        datas={list2?.map((data) => {
          return {            
            id: data._id,
            renders: [
              { id: "updatedAt", render: <div className="exbold">{formatDateYMD(data.created_date)}</div> },
              { id: "approve", render: data?.decisionType === 0 ? "회차 수정" : "회차 등록" },
              { id: "requestor", render: data?.request_cp_user?.name },
              { id: "targetItem", render: data?.target_novel?.title },
              { id: "itemDetail", render: 
                <div className="bold underline pointer">{
                  (data?.episodedecisionList?.length > 0 ?
                    data?.episodedecisionList?.length : 
                    data?.episodedecisionCompletedList?.length ?? 0
                  ) + "건 업로드"}
                </div>
              },
              { id: "approval", render: ["대기중", "승인", "반려"][
                data?.episodedecisionList?.length > 0 ?
                  data?.episodedecisionList?.at(0)?.hasApproved :
                  data?.episodedecisionCompletedList?.at(0)?.hasApproved
              ] },
              { id: "isCancelled", render: data?.isCancelled ? "취소됨" : "" },
            ],
          }
        })}
      />
      

      <div className="w-full flex-center mb-80">
        <Pagination
          currentPage={page2}
          totalPage={totalPage2}
          onClickPage={setPage2}
        />
      </div>
    </div>
  );
}

export default UpdateHistory;
